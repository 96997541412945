import React from "react";

export default function LinkedIn() {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 0C37.254 0 48 10.746 48 24C48 37.254 37.254 48 24 48C10.746 48 0 37.254 0 24C0 10.746 10.746 0 24 0ZM17.902 18.808H12.33V35H17.9V18.808H17.902ZM31.584 18.424C28.936 18.424 27.598 19.682 26.814 20.736L26.56 21.098V18.806H20.988L21.008 19.774C21.02 21.046 21.022 23.27 21.018 25.634L20.988 35H26.56V25.956C26.56 25.472 26.596 24.988 26.744 24.642C27.148 23.676 28.064 22.674 29.604 22.674C31.514 22.674 32.338 24.006 32.42 25.998L32.426 26.334V35H38V25.714C38 20.74 35.25 18.424 31.584 18.424ZM15.152 11C13.246 11 12 12.21 12 13.798C12 15.258 13.072 16.448 14.756 16.582L15.116 16.594C17.058 16.594 18.27 15.352 18.27 13.798C18.232 12.21 17.06 11 15.152 11Z"
        fill="white"
        fillOpacity="1"
      />
    </svg>
  );
}
