import Highlight from "react-highlight";
import "highlight.js/styles/ir-black.css";

import "./CodeBlock.css";

export default function CodeBlock(inputHTML) {
  const renderHTMLOutput = (inputHTML) => {
    return inputHTML.children;
  };

  return (
    <>
      <div className="code-block-window-wrapper">
        <div className="window-top-buttons">
          <div className="window-inner-buttons"></div>
        </div>
        <Highlight className="language-shell" innerHTML={true}>
          {`<pre><code>${renderHTMLOutput(inputHTML)}</code></pre>`}
        </Highlight>
      </div>
    </>
  );
}
