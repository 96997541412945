import React from "react";

import TestimonialsBlock from "../../../../Common/TestimonialsBlock/TestimonialsBlock.js";

import "./TestimonialsBlock.css";

export default function ProductTestimonialsBlock(props) {
  // const { item } = props;

  return (
    <div className="product-testimonials-block">
      <TestimonialsBlock />
    </div>
  );
}
