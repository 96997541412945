import React from "react";

import "./IntegrationsListItem.css";

export default function IntegrationsListItem(props) {
  return (
    <a href={"/integrations/" + props.integration.id}>
      <article className="h-full w-full flex flex-row items-center gap-8 px-12 py-16 lg:py-12 border border-sym-blue bg-gradient-fade integrations-list-hover">
        <div className="w-full flex-shrink-0" style={{ maxWidth: "60px" }}>
          <img
            src={props.integration.previewImage}
            alt={props.integration.title}
            className="w-full"
          />
        </div>
        <div className="inline-flex flex-row items-center gap-4">
          <p className="text-2xl font-bold">{props.integration.title}</p>
          <div className="flex-shrink-0">
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.172852 10.565L4.43401 6.29452L0.172851 2.02405L1.48469 0.712204L7.06699 6.29452L1.48469 11.8768L0.172852 10.565Z"
                fill="#EC0FFF"
              />
            </svg>
          </div>
        </div>
      </article>
    </a>
  );
}
