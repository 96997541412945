import Helmet from "react-helmet";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const PAGE_TITLE = "Authorization | Sym";

export default function Authorization() {
  const { integration } = useParams();

  // Get the query params as one long string
  const queryString = useLocation().search;
  // Parse the query params into an object
  const queryParams = new URLSearchParams(queryString);

  // If someone is initiating the OAuth flow from the Datadog tile, then Datadog will pass through a 'site' parameter,
  // indicating which Datadog site URL to use when redirecting the user to Datadog's /authorize.
  // We will need to forward this site parameter to Queuer's oauth/login/start.
  const site = queryParams.get("site") || "";

  const [orgID, setOrgID] = useState("");
  const [displayError, setDisplayError] = useState(
    queryParams.get("error") || ""
  );
  const capitalizedIntegration =
    integration.toString()[0].toUpperCase() +
    integration.toString().substring(1);
  let base_authorization_endpoint =
    process.env.REACT_APP_SYM_API_URL +
    "/oauth/login/start?integration_type=" +
    integration +
    "&org_id=";

  const handleButtonClick = async (event) => {
    event.preventDefault();
    let authorization_endpoint = base_authorization_endpoint + orgID;

    // If a site parameter exists, pass it through to Queuer
    if (site !== "") {
      authorization_endpoint = authorization_endpoint + "&site=" + site;
    }

    const response = await fetch(authorization_endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).catch(() => {
      setDisplayError(
        "Something unexpected went wrong. Please refresh this page and try again."
      );
    });

    // If we caught an error above (e.g. couldn't reach the API), response will be undefined and
    // the unexpected error text should display, so stop evaluating the form submission.
    if (!response) {
      return;
    }

    const result = await response.json();
    // We successfully got an API response but it was not a 200, so display the error if possible,
    // or just display a generic error message if not.
    if (!response.ok) {
      setOrgID("");
      if (result.message) {
        setDisplayError(result.message);
      } else {
        setDisplayError(
          "Something unexpected went wrong. Please refresh this page and try again."
        );
      }
    } else {
      window.location.href = result["url"];
    }
  };

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link
          rel="canonical"
          href={"https://symops.com/authorization/" + integration}
        />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>

      <div className="hero-bg-wrapper h-screen">
        <div className="mx-16 md:mx-48 max-w-full">
          <div className="flex flex-col mx-auto my-20 px-8 py-10 justify-center bg-gradient-fade text-center">
            <h3 className="my-10">Connect Sym and {capitalizedIntegration}</h3>
            <div className="flex flex-col sm:w-72 w-5/6 mx-auto justify-center space-y-7">
              <input
                value={orgID}
                onChange={(e) => setOrgID(e.target.value)}
                type="text"
                id="orgID"
                name="orgID"
                className="border-b border-black bg-transparent py-3 focus:outline-none"
                placeholder="Enter your Sym Org ID"
                required
              />
              <input
                type="submit"
                disabled={!orgID}
                onClick={(event) => {
                  handleButtonClick(event);
                }}
                className="cta-button w-full"
                value="Add Sym"
              />
              <div className="errors">
                {displayError && <p className="text-red-600">{displayError}</p>}
              </div>
            </div>
          </div>
        </div>
        <div className="hero-bg login-bg"></div>
      </div>
    </>
  );
}
