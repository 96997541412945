import React from "react";

import "./UseCasePostIntegrations.css";
import { Integrations } from "../../../../../pages/Integrations/IntegrationsList.js";
import IntegrationsList from "../../../integrations/IntegrationsList/IntegrationsList.js";

export default function UseCasePostIntegrations(props) {
  const { item } = props;

  const integrationsList = [];

  // Fetch list of integrations from ids
  item.integrationIds.forEach((integrationId) => {
    const integration = Integrations.find((i) => i.id === integrationId);
    if (integration) {
      integrationsList.push(integration);
    }
  });

  return (
    <div className="flex flex-col text-center items-center gap-12">
      <h2>Featured Integrations</h2>
      <IntegrationsList integrations={integrationsList} />
    </div>
  );
}
