import Helmet from "react-helmet";
import "./SelfServiceSignUpEmailVerified.css";
import Layout from "../../components/Layout";
import { useLocation } from "react-router-dom";
import SetupInstructions from "./SetupInstructions";
import React from "react";

const PAGE_TITLE = "Email Verified | Sym";

export default function SelfServiceSignUpEmailVerified() {
  const queryString = useLocation().search; // Get the query params as one long string
  const queryParams = new URLSearchParams(queryString); // Parse the query params into an object
  const orgId = queryParams.get("org_id");

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/signup/email-verified" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="hero-bg-wrapper">
          <div className="w-full max-w-6xl flex flex-col mx-auto px-6 py-0 lg:py-12 gap-24">
            <div className="flex flex-col mx-auto self-center w-full max-w-6xl px-5 md:px-12 lg:px-20 py-16 my-14 rounded-lg shadow-md text-center bg-gradient-fade">
              <h2>Congratulations!</h2>
              <div
                className={`bg-green-700 bg-opacity-20 lg:mx-20 p-6 mt-10 rounded lg:px-20`}
              >
                <p className="font-medium text-2xl">
                  Your email has been verified.
                </p>

                {orgId && (
                  <div className="mt-6">
                    <h4 className="md:px-14 text-2xl">
                      Your Organization ID is
                    </h4>
                    <h4 className="font-courier font-bold text-2xl">{orgId}</h4>
                  </div>
                )}
              </div>

              <div className="mt-10 lg:px-20">
                <SetupInstructions />
              </div>
            </div>
          </div>
          <div className="hero-bg login-bg"></div>
        </div>
      </Layout>
    </>
  );
}
