import Helmet from "react-helmet";
import "./SelfServicePricing.css";
import Layout from "../../components/Layout";
import TestimonialsBlock from "../../components/Common/TestimonialsBlock/TestimonialsBlock";
import React from "react";
import CalendlyPopupTrigger from "../../components/Common/CalendlyPopupTrigger";

const PAGE_TITLE = "Pricing | Sym";

function Button(props: {
  text: string;
  link?: string;
  target?: string;
  id?: string;
}) {
  return (
    <>
      {props.link ? (
        <a
          href={props.link}
          target={props.target ? props.target : "_self"}
          id={props.id ?? undefined}
        >
          <button className="cta-button">{props.text}</button>
        </a>
      ) : (
        <button className="cta-button" id={props.id ?? undefined}>
          {props.text}
        </button>
      )}
    </>
  );
}

export default function SelfServicePricing(freeTrialFlag: {
  freeTrialFlag: Number;
}) {
  // const freeTrialLength = freeTrialFlag.freeTrialFlag;

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/pricing" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="hero-bg-wrapper">
          <div className="mx-auto py-10 max-w-full self-center">
            <div className="w-100 max-w-7xl mx-auto">
              <div className="mt-8 mb-24 text-center">
                <h1 className="w-full mx-auto" style={{ maxWidth: "690px" }}>
                  Start making better access decisions
                </h1>
              </div>

              <div className="flex flex-col lg:flex-row gap-8 mx-auto justify-center">
                {/* Free Panel */}
                <div className="w-full relative p-8 pt-12 rounded-lg shadow-lg flex flex-col justify-between bg-sym-dark bg-opacity-80 border border-sym-blue">
                  <div>
                    <div>
                      <div className="text-3xl">Free</div>
                      <div className="text-xl mt-2">
                        For small teams looking for just-in-time access
                      </div>
                    </div>
                    <div className="pricing-panel-separator"></div>
                    <ul className="pricing-feature-list">
                      <li>Up to 3 workflows</li>
                      <li>Up to 10 users</li>
                      <li>
                        <span>
                          Access to all integrations: PagerDuty, Okta, AWS
                          Identity Center,{" "}
                          <a href="/integrations" target="_blank">
                            and more
                          </a>
                        </span>
                      </li>
                      <li>Developer-friendly SDK</li>
                      <li>API Access</li>
                    </ul>
                  </div>

                  <div className="mt-8">
                    <Button
                      text="Start Now"
                      link="/signup/free"
                      id="btn-pricing-plan-free"
                    ></Button>
                  </div>
                </div>

                {/* Enterprise Panel */}
                <div className="w-full relative p-8 pt-12 rounded-lg shadow-lg flex flex-col justify-between bg-sym-dark bg-opacity-80 border border-sym-blue">
                  <div>
                    <div>
                      <div className="text-3xl">Enterprise</div>
                      <div className="text-xl mt-2">
                        For teams needing centralized approval and access
                      </div>
                    </div>
                    <div className="pricing-panel-separator"></div>
                    <ul className="pricing-feature-list">
                      <li>Audit logs</li>
                      <li>Live support in Slack</li>
                      <li>SOC 2 Type II report</li>
                      <li>Multi-domain user identities</li>
                      <li>Custom contracts</li>
                      <li>SAML SSO for admins</li>
                    </ul>
                  </div>

                  <div className="flex w-full mt-8">
                    <CalendlyPopupTrigger calendlyLink="https://calendly.com/hello-sym/sym-product-25">
                      <Button
                        text="Contact Us"
                        id="btn-pricing-plan-custom"
                      ></Button>
                    </CalendlyPopupTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hero-bg pricing-hero-bg"></div>
        </div>

        <TestimonialsBlock />
      </Layout>
    </>
  );
}
