import React from "react";

import "./ProductPage.css";
import FeatureGrid from "./FeatureGrid/FeatureGrid.js";
import FeatureBlock from "./FeatureBlock/FeatureBlock.js";
import ProductTestimonialsBlock from "./TestimonialsBlock/TestimonialsBlock.js";
import CtaVideoBlock from "./CtaVideoBlock/CtaVideoBlock.js";
import FeatureColumns from "./FeatureColumns/FeatureColumns.js";

export default function ProductPage(props) {
  const productPage = props.productPage;

  return (
    <div>
      <div className="w-full max-w-7xl mx-auto px-6">
        <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-between gap-8 py-16 lg:py-20">
          <div className="w-full max-w-lg">
            <div className="text-center lg:text-left">
              <h1 className="mb-4">{productPage.title}</h1>
              <p className="text-2xl">{productPage.subTitle}</p>
            </div>
          </div>
          <div
            className="w-full relative mt-8 lg:mt-0"
            style={{ maxWidth: "600px" }}
          >
            <img
              alt="Hero"
              src={productPage.heroImage}
              className="w-full block"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-24 lg:gap-32 mt-24">
        {productPage.content.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item.type === "features-grid" && (
                <div className="w-full max-w-7xl mx-auto px-6">
                  <FeatureGrid item={item} />
                </div>
              )}
              {item.type === "feature-block" && (
                <div className="w-full max-w-7xl mx-auto px-6">
                  <FeatureBlock item={item} />
                </div>
              )}
              {item.type === "testimonials" && (
                <ProductTestimonialsBlock item={item} />
              )}
              {item.type === "cta-video" && (
                <div className="w-full max-w-7xl mx-auto px-6">
                  <CtaVideoBlock item={item} />
                </div>
              )}
              {item.type === "feature-columns" && (
                <div className="w-full max-w-7xl mx-auto px-6">
                  <FeatureColumns item={item} />
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
