import React from "react";

import "./SecurityDisclosure.css";

export default function SecurityDisclosure() {
  return (
    <div className="flex flex-col h-full gap-6 p-8 md:p-16 relative text-center bg-gradient-fade">
      <h3 className="text-3xl">Need to report a security issue?</h3>

      <p className="text-lg">
        If you've identified a potential security vulnerability within Sym,
        please contact us at{" "}
        <strong>
          <a href="mailto:security@symops.io">security@symops.io</a>
        </strong>
      </p>
      <p className="text-md opacity-80">
        For more details, please see our{" "}
        <a href="/security/disclosure">Responsible Disclosure Policy</a>
      </p>
    </div>
  );
}
