import React from "react";

import "./UseCaseListItem.css";

export default function UseCaseList(props) {
  return (
    <a href={"/use-cases/" + props.useCase.id} className="h-full flex">
      <article className="flex-1 w-full flex flex-col border border-sym-blue">
        <img
          className="w-full h-full max-h-56 block flex-shrink-0 preview-image"
          src={props.useCase.previewImage}
          alt={props.useCase.title}
        ></img>

        <div className="flex-1 flex flex-col justify-center gap-4 p-6 bg-gradient-fade">
          <h3 className="text-2xl">{props.useCase.title}</h3>
          <p
            className="text-md"
            dangerouslySetInnerHTML={{ __html: props.useCase.shortDescription }}
          ></p>
        </div>
      </article>
    </a>
  );
}
