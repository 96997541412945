import React, { useCallback, useRef, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import "./SelfServiceSignUpForm.css";
import Layout from "../../components/Layout";
import ReCAPTCHA from "react-google-recaptcha";
import GithubIcon from "../../static/img/icons/github.svg";
import EmailIcon from "../../static/img/icons/email.svg";
import GoogleIcon from "../../static/img/icons/google.svg";
import CodeBlock from "../../components/Common/CodeBlock/CodeBlock";
import TestimonialsBlock from "../../components/Common/TestimonialsBlock/TestimonialsBlock";

const PAGE_TITLE = "Sign Up | Sym";
const API_URL = process.env.REACT_APP_SYM_API_URL;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_SIGNUP_RECAPTCHA_KEY || ""; // suppress undefined error
const ALLOWED_ROUTE_PLANS = ["free", "standard"];

declare global {
  interface Window {
    analytics: any;
  }
}

export default function SelfServiceSignUpForm(freeTrialFlag: {
  freeTrialFlag: Number;
}) {
  const location = useLocation();
  const history = useHistory();
  const routePlan = location.pathname.split("/")[2];

  useEffect(() => {
    if (routePlan && !ALLOWED_ROUTE_PLANS.includes(routePlan)) {
      // Redirect if invalid plan name back to main signup URL
      return history.push("/signup");
    }
  }, [history, routePlan]);

  const [connectionType, setConnectionType] = useState("");
  const [email, setEmail] = useState("");
  const [showEmailFields, setShowEmailFields] = useState(false);
  const [displayError, setDisplayError] = useState("");
  const [inputEnabled, setInputEnabled] = useState(true);
  const [reCAPTCHAValue, setReCAPTCHAValue] = useState("");

  // Password
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [displayPasswordMatchError, setDisplayPasswordMatchError] = useState(
    false
  );
  const [
    displayPasswordTooLongError,
    setDisplayPasswordTooLongError,
  ] = useState(false);
  const [
    displayPasswordMatchSuccess,
    setDisplayPasswordMatchSuccess,
  ] = useState(false);

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const recaptchaBypassKeyRef = useRef<HTMLInputElement>(null);

  // The argument names here are intentionally different from the corresponding state values
  // so that it is unambiguous which one is being used.
  const handleSubmit = useCallback(
    async (submitConnectionType: string, submitReCAPTCHAValue: string) => {
      setInputEnabled(false);

      var requestData = {
        connection_type: submitConnectionType,
        recaptcha_response: submitReCAPTCHAValue,
        recaptcha_bypass_key: recaptchaBypassKeyRef.current?.value,
        signup_origin: routePlan,
      };

      try {
        // windows.analytics.user() throws an exception if the user has an ad blocker.
        // Only set the segment_anonymous_id if we're able to load it.
        let anonymousId = window.analytics.user().anonymousId();
        Object.assign(requestData, {
          segment_anonymous_id: anonymousId,
        });
      } catch (e) {}

      if (submitConnectionType === "auth0") {
        Object.assign(requestData, {
          email: email,
          password: password,
        });
      }

      const response = await fetch(API_URL + "/signup/start", {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).catch(() => {
        setDisplayError(
          "Something unexpected went wrong. Please refresh this page and try again."
        );
        setInputEnabled(false);
        return;
      });

      // If we caught an error above (e.g. couldn't reach the API), response will be undefined and
      // the unexpected error text should display, so stop evaluating the form submission.
      if (!response) {
        setDisplayError(
          "Something unexpected went wrong. Please refresh this page and try again."
        );
        setInputEnabled(false);
        return;
      }

      let result;
      try {
        result = await response.json();
      } catch (e) {}

      if (!result) {
        setDisplayError(
          "Something unexpected went wrong. Please refresh this page and try again."
        );
        setInputEnabled(false);
        return;
      }

      // We successfully got an API response but it was not a 200, so display the error if possible,
      // or just display a generic error message if not.
      if (!response.ok) {
        // clear password + all displayed messages
        setPassword("");
        setPasswordConfirmation("");
        setDisplayPasswordMatchError(false);
        setDisplayPasswordTooLongError(false);
        setDisplayPasswordMatchSuccess(false);

        if (result.error) {
          if (result.error === "user_exists") {
            // Redirect to the User exists error page
            window.location.href =
              "/signup/error?error=user_exists&user_email=" + btoa(email);
          } else {
            setDisplayError(result.error);
          }
        } else {
          setDisplayError(
            "Something unexpected went wrong. Please refresh this page and try again."
          );
        }

        // Re-enable the form input fields
        setInputEnabled(true);
        return;
      }

      // Our API request was successful, continue to the next step of signup.
      window.analytics.track("Signup API Call Succeeded", {
        email: email,
      });

      window.location.href = result.redirect_url;
    },
    [email, password, routePlan]
  );

  // useEffect will be called any time a state value in the list given changes.
  // Any state value used within the function passed to useEffect must be included in the list,
  // or else attempting to pull the value from state may result in a stale value.
  useEffect(() => {
    // Since this function will be called if ANY of connectionType, recaptchaValue, email, or password changes,
    // it may be called before we actually want to submit. As long as we don't have a reCAPTCHA value yet, then
    // we can assume we should not submit.
    //
    // When bypassing reCAPTCHA, this useEffect function will not be called at all, as handleSubmit will be called
    // manually in order to bypass reCAPTCHA.
    if (!reCAPTCHAValue.length) {
      return;
    }

    // Because we're using useEffect, handleSubmit could technically pull connectionType from state
    // instead of having it passed in. However, since handleSubmit also gets called outside this
    // function when we bypass reCAPTCHA, it needs to take connectionType as an argument.
    handleSubmit(connectionType, reCAPTCHAValue);
  }, [connectionType, reCAPTCHAValue, email, password, handleSubmit]);

  const handleReCAPTCHASubmit = (value: string | null) => {
    if (value !== null) {
      setReCAPTCHAValue(value);
    } else {
      window.analytics.track("reCAPTCHA Failed", {
        email: email,
      });
      setDisplayError(
        "reCAPTCHA verification failed. Please refresh and try again."
      );
    }
  };

  const handleSubmitButtonClick = (
    event: React.MouseEvent,
    connectionType: string
  ) => {
    event.preventDefault();

    window.analytics.track("Sign Up Clicked", {
      connectionType: connectionType,
    });

    // Set the connectionType so that it can be used in the useEffect function when
    // reCAPTCHA is done executing.
    setConnectionType(connectionType);

    if (recaptchaBypassKeyRef.current?.value === "") {
      recaptchaRef.current?.execute();
    } else {
      // If we have a reCAPTCHA bypass key, then skip reCAPTCHA and submit the form directly.
      // This is used for automated testing purposes only.
      setInputEnabled(false);
      handleSubmit(connectionType, "");
    }
  };

  const handlePasswordMatching = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirmation(event.target.value);
    if (event.target.value.length > 64) {
      setDisplayPasswordTooLongError(true);
      setDisplayPasswordMatchSuccess(false);
    } else if (password !== event.target.value) {
      window.analytics.track("Password Not Matched", {
        email: email,
      });
      setDisplayPasswordMatchError(true);
      setDisplayPasswordMatchSuccess(false);
    } else {
      window.analytics.track("Password Matched", {
        email: email,
      });
      setDisplayPasswordTooLongError(false);
      setDisplayPasswordMatchError(false);
      setDisplayPasswordMatchSuccess(true);
    }
  };

  // For email & password sign up
  function EmailFormButtons() {
    return (
      <div className="my-1 flex flex-row gap-4">
        {/* Back button */}
        <a href="/signup" className="cta-button secondary">
          Back
        </a>

        {/* Submit button */}
        <input
          type="submit"
          disabled={
            displayPasswordMatchError || !passwordConfirmation || !inputEnabled
          }
          onClick={(event) => {
            handleSubmitButtonClick(event, "auth0");
          }}
          className="cta-button flex-1"
          value="Submit"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/signup" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="hero-bg-wrapper">
          <div className="w-full max-w-8xl flex flex-col mx-auto px-6 py-12 gap-24">
            <div>
              <div className="mb-12 text-center">
                <h2 className="text-center">Sign Up For Sym</h2>
              </div>
              <div className="w-full max-w-6xl mx-auto p-12 lg:p-16 bg-gradient-fade">
                <div className="flex flex-col lg:flex-row gap-16 justify-center lg:justify-between items-center">
                  <div className="flex-1">
                    <h3 className="text-2xl">
                      Sign up to start building on our{" "}
                      {routePlan === "standard" ? "standard" : "free"} plan. Get
                      immediate access to:
                    </h3>
                    <ul className="flex flex-col gap-5 mt-8 text-xl">
                      <li className="flex flex-row items-center gap-4">
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.48047 0.200195C4.06447 0.200195 0.480469 3.7842 0.480469 8.2002C0.480469 12.6162 4.06447 16.2002 8.48047 16.2002C12.8965 16.2002 16.4805 12.6162 16.4805 8.2002C16.4805 3.7842 12.8965 0.200195 8.48047 0.200195ZM6.88047 12.2002L2.88047 8.2002L4.00847 7.0722L6.88047 9.9362L12.9525 3.8642L14.0805 5.0002L6.88047 12.2002Z"
                            fill="#EC0FFF"
                          />
                        </svg>
                        Just-in-time access workflows
                      </li>
                      <li className="flex flex-row items-center gap-4">
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.85524 10.4147C3.85524 11.3446 3.10365 12.0962 2.17372 12.0962C1.24378 12.0962 0.492188 11.3446 0.492188 10.4147C0.492188 9.48475 1.24378 8.73315 2.17372 8.73315H3.85524V10.4147ZM4.69601 10.4147C4.69601 9.48475 5.4476 8.73315 6.37754 8.73315C7.30747 8.73315 8.05907 9.48475 8.05907 10.4147V14.6185C8.05907 15.5484 7.30747 16.3 6.37754 16.3C5.4476 16.3 4.69601 15.5484 4.69601 14.6185V10.4147Z"
                            fill="#00F0FF"
                          />
                          <path
                            d="M6.37856 3.66311C5.44862 3.66311 4.69703 2.91151 4.69703 1.98158C4.69703 1.05164 5.44862 0.300049 6.37856 0.300049C7.30849 0.300049 8.06009 1.05164 8.06009 1.98158V3.66311H6.37856ZM6.37856 4.51661C7.30849 4.51661 8.06009 5.2682 8.06009 6.19814C8.06009 7.12807 7.30849 7.87967 6.37856 7.87967H2.162C1.23206 7.87967 0.480469 7.12807 0.480469 6.19814C0.480469 5.2682 1.23206 4.51661 2.162 4.51661H6.37856Z"
                            fill="#00F0FF"
                          />
                          <path
                            d="M13.1179 6.19814C13.1179 5.2682 13.8695 4.51661 14.7994 4.51661C15.7293 4.51661 16.4809 5.2682 16.4809 6.19814C16.4809 7.12807 15.7293 7.87967 14.7994 7.87967H13.1179V6.19814ZM12.2771 6.19814C12.2771 7.12807 11.5255 7.87967 10.5956 7.87967C9.66565 7.87967 8.91406 7.12807 8.91406 6.19814V1.98158C8.91406 1.05164 9.66565 0.300049 10.5956 0.300049C11.5255 0.300049 12.2771 1.05164 12.2771 1.98158V6.19814Z"
                            fill="#00F0FF"
                          />
                          <path
                            d="M10.5956 12.937C11.5255 12.937 12.2771 13.6886 12.2771 14.6185C12.2771 15.5484 11.5255 16.3 10.5956 16.3C9.66565 16.3 8.91406 15.5484 8.91406 14.6185V12.937H10.5956ZM10.5956 12.0962C9.66565 12.0962 8.91406 11.3446 8.91406 10.4147C8.91406 9.48475 9.66565 8.73315 10.5956 8.73315H14.8122C15.7421 8.73315 16.4937 9.48475 16.4937 10.4147C16.4937 11.3446 15.7421 12.0962 14.8122 12.0962H10.5956Z"
                            fill="#00F0FF"
                          />
                        </svg>
                        Easy-to-use app for Slack
                      </li>
                      <li className="flex flex-row items-center gap-4">
                        <svg
                          width="15"
                          height="17"
                          viewBox="0 0 15 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.1796 4.3926L7.82925 0.736328L1.1582 4.3926L7.70841 8.13385L14.1796 4.3926Z"
                            fill="#47A7FF"
                          />
                          <path
                            d="M8.53125 9.35508L14.882 5.84546V13.0875L8.53125 16.7364V9.35508Z"
                            fill="#47A7FF"
                          />
                          <path
                            d="M6.83203 9.35508L0.481325 5.84546V13.0875L6.83203 16.7364V9.35508Z"
                            fill="#47A7FF"
                          />
                        </svg>
                        Developer-friendly SDK
                      </li>
                      <li className="flex flex-row items-center gap-4">
                        <svg
                          width="15"
                          height="17"
                          viewBox="0 0 18 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.99414 0.342041H11.5252V5.87307H9.33609V7.72054H15.0325V10.811H17.2361V16.3421H11.7051V10.811H13.8802V8.87284H3.64089V10.811H5.8279V16.342H0.296875V10.811H2.48859V7.72054H8.1838V5.87307H5.99414V0.342041Z"
                            fill="#EC0FFF"
                          />
                        </svg>
                        Integrations to AWS, Okta, and more
                      </li>
                    </ul>
                    <div className="mt-8">
                      <a href="/pricing" className="cta-button tertiary -ml-4">
                        Learn more about our pricing &#x203A;
                      </a>
                    </div>
                  </div>
                  <div className="w-full max-w-md">
                    {(showEmailFields || displayError) && (
                      <div>
                        {/* Sign Up by Email is clicked */}
                        {showEmailFields && (
                          <div className="flex flex-col gap-6">
                            <div className="">
                              <label>Email*:</label>

                              {/* id is email-input to avoid id clash with newsletter email input field */}
                              <div className="input-wrapper mt-2">
                                <input
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  type="email"
                                  id="email-input"
                                  name="email"
                                  placeholder="jane@symops.io"
                                  pattern="^.+@.+\..+$"
                                  className="text-input"
                                  required
                                  disabled={!inputEnabled}
                                />
                              </div>
                            </div>

                            <div className="">
                              <label htmlFor="password">Password*:</label>
                              <div className="text-white opacity-40 text-xs mt-1">
                                Your password must contain a minimum of 8
                                characters, with at least 3 of the following 4
                                types of characters: lower case letters (a-z),
                                upper case letters (A-Z), numbers (0-9), special
                                characters (!@#$%^&*).
                              </div>
                              <div className="input-wrapper mt-2">
                                <input
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  type="password"
                                  id="password"
                                  name="password"
                                  className="text-input"
                                  required
                                  disabled={!inputEnabled}
                                />
                              </div>
                            </div>

                            <div className="">
                              <label>Enter your password again*:</label>
                              <div className="input-wrapper mt-2">
                                <input
                                  value={passwordConfirmation}
                                  onChange={handlePasswordMatching}
                                  type="password"
                                  id="passwordConfirmation"
                                  name="passwordConfirmation"
                                  className="text-input"
                                  required
                                  disabled={!inputEnabled}
                                />
                              </div>
                              {showEmailFields && displayPasswordMatchError && (
                                <p className="text-red-600 mt-2">
                                  Passwords do not match
                                </p>
                              )}
                              {showEmailFields &&
                                displayPasswordTooLongError && (
                                  <p className="text-red-600 mt-2">
                                    Password exceeds the 64 character limit
                                  </p>
                                )}
                              {showEmailFields &&
                                displayPasswordMatchSuccess && (
                                  <p className="text-green-600 mt-2">
                                    Passwords match!
                                  </p>
                                )}
                            </div>

                            <EmailFormButtons />
                          </div>
                        )}
                        {displayError && (
                          <p className="text-red-600 mt-6">{displayError}</p>
                        )}
                      </div>
                    )}

                    {/* Sign up by Google / Github / Email buttons */}
                    {!showEmailFields && (
                      <div className="flex flex-auto items-start flex-col text-center">
                        <div
                          className={`my-3 mx-auto flex flex-row text-lg bg-sym-blue rounded-sm text-white shadow-sm ${
                            inputEnabled ? "" : "cursor-default opacity-50"
                          }`}
                        >
                          <img src={GoogleIcon} alt="google" className="w-16" />
                          <input
                            type="submit"
                            id="google-oauth2-signup-button"
                            disabled={!inputEnabled}
                            onClick={(event) => {
                              handleSubmitButtonClick(event, "google-oauth2");
                            }}
                            className={"cursor-pointer bg-sym-blue w-full"}
                            style={{ minWidth: "230px" }}
                            value="Sign up with Google"
                          />
                        </div>

                        <div
                          className={`my-3 mx-auto flex flex-row text-lg bg-black text-white rounded-sm shadow-sm ${
                            inputEnabled ? "" : "cursor-default opacity-50"
                          }`}
                        >
                          <img src={GithubIcon} alt="github" className="w-16" />
                          <input
                            type="submit"
                            disabled={!inputEnabled}
                            onClick={(event) => {
                              handleSubmitButtonClick(event, "github");
                            }}
                            className={"cursor-pointer bg-black w-full"}
                            style={{ minWidth: "230px" }}
                            value="Sign up with GitHub"
                          />
                        </div>

                        <div
                          className={`my-3 mx-auto flex flex-row text-lg bg-sym-purple rounded-sm shadow-sm ${
                            inputEnabled ? "" : "cursor-default opacity-50"
                          }`}
                        >
                          <img src={EmailIcon} alt="email" className="w-16" />
                          <input
                            type="button"
                            disabled={!inputEnabled}
                            onClick={() => {
                              setShowEmailFields(true);
                              window.analytics.track("Sign Up Clicked", {
                                connectionType: "email",
                              });
                            }}
                            className={
                              "cursor-pointer bg-sym-purple text-sym-dark w-full"
                            }
                            style={{ minWidth: "230px" }}
                            value="Sign up with Email"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={handleReCAPTCHASubmit}
              />
              <input
                type="hidden"
                id="recaptcha-bypass-key"
                name="recaptcha-bypass-key"
                ref={recaptchaBypassKeyRef}
              ></input>
            </div>
          </div>
          <div className="hero-bg login-bg"></div>
        </div>

        <div className="hero-bg-wrapper">
          <div className="w-full max-w-8xl flex flex-col mx-auto px-6 py-24 gap-24">
            <div className="flex flex-col justify-between">
              <div className="flex flex-col items-center text-center">
                <div className="flex flex-col gap-4">
                  <h1>Stuck? Need help?!</h1>
                </div>
              </div>

              <div className="flex flex-col xl:flex-row items-center gap-16 xl:gap-24 mt-24">
                <div className="w-full max-w-2xl xl:max-w-none flex flex-col gap-12 p-12 bg-gradient-fade">
                  <div>
                    <h4 className="text-2xl font-bold">
                      1. Find your Sym Organization ID
                    </h4>
                    <div className="mt-4">
                      If your team already created an account, your Organization
                      ID was emailed to the address you used when signing up.
                      Ask your Sym implementers for help! If you want to set up
                      a new org, then just sign up above.
                    </div>
                  </div>
                  <div>
                    <h4 className="text-2xl font-bold">
                      2. Log in with the symflow CLI
                    </h4>
                    <div className="mt-4">
                      The symflow CLI is the central tool for logging in,
                      managing users and other resources, and authenticating
                      with our Terraform provider. You can install the CLI using
                      brew, pip or via curl. For more information, see our{" "}
                      <a
                        href="https://docs.symops.com/docs/install-sym-flow-cli"
                        style={{ color: "#3377f3" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        documentation here
                      </a>
                      .
                    </div>
                  </div>
                  <div>
                    Once you're logged in, run <code>symflow -h</code> to see
                    the other commands you can run.
                  </div>
                </div>
                <div>
                  <CodeBlock>
                    {`<mark class="hjs-mark-custom hjs-custom-white">~/Documents/sym/</mark>
<mark class="hjs-mark-custom hjs-custom-purple">&#x276f;</mark> <mark class="hjs-mark-custom hjs-custom-blue">brew install symopsio/tap/symflow</mark>

<mark class="hjs-mark-custom hjs-custom-white">~/Documents/sym/</mark>
<mark class="hjs-mark-custom hjs-custom-purple">&#x276f;</mark> <mark class="hjs-mark-custom hjs-custom-blue">symflow login</mark>

<mark class="hjs-mark-custom hjs-custom-light-blue">Login succeeded</mark>

<mark class="hjs-mark-custom hjs-custom-white">~/Documents/sym/</mark>
<mark class="hjs-mark-custom hjs-custom-purple">&#x276f;</mark> <mark class="hjs-mark-custom hjs-custom-blue">symflow -h</mark>

<mark class="hjs-mark-custom hjs-custom-blue">Usage: symflow [OPTIONS] COMMAND [ARGS]...</mark>

   <mark class="hjs-mark-custom hjs-custom-blue">Manage Sym Services and Users in your organization</mark>

   <mark class="hjs-mark-custom hjs-custom-blue">https://docs.symops.com/docs/using-the-symflow-cli</mark>`}
                  </CodeBlock>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center gap-8 text-center">
              <h2>Still not sure what to do?</h2>
              <div>
                <a href="mailto:support@symops.com" className="cta-button">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
        <TestimonialsBlock />
      </Layout>
    </>
  );
}
