import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../../components/Layout";
import styles from "../Legal.module.css";

const PAGE_TITLE = "Terms of Service | Sym";

export default function Terms() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/terms" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>

      <Layout>
        <div
          className={`${styles.legalPage} " flex flex-col w-full justify-center px-4 md:px-8 lg:px-8 xl:px-16 2xl:px-40 relative`}
        >
          <h1>SYM SERVICE TERMS</h1>
          <br />

          <p>Last updated: April 17, 2023</p>
          <br />

          <p>
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THE
            SERVICE OFFERED BY SYMOPS, INC. (“<strong>SYM</strong>”). BY
            MUTUALLY EXECUTING ONE OR MORE ORDER FORMS WITH SYM WHICH REFERENCE
            THESE TERMS (EACH, A “<strong>SERVICE ORDER</strong>”) OR BY
            ACCESSING OR USING THE SERVICES IN ANY MANNER, YOU (“
            <strong>YOU</strong>” OR “<strong>CUSTOMER</strong>”) AGREE TO BE
            BOUND BY THESE TERMS (TOGETHER WITH ALL ORDER FORMS, THE “
            <strong>AGREEMENT</strong>”) TO THE EXCLUSION OF ALL OTHER TERMS.
            YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO ENTER INTO
            THIS AGREEMENT; IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF
            AN ORGANIZATION OR ENTITY, REFERENCES TO “CUSTOMER” AND “YOU” IN
            THIS AGREEMENT, EXCEPT THIS SENTENCE, REFER TO THAT ORGANIZATION OR
            ENTITY. IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE
            OR ACCESS THE SERVICES IN ANY MANNER. IF THE TERMS OF THIS AGREEMENT
            ARE CONSIDERED AN OFFER, ACCEPTANCE IS EXPRESSLY LIMITED TO SUCH
            TERMS.
          </p>

          <br />

          <h2>1. SCOPE OF SERVICE AND RESTRICTIONS</h2>
          <br />
          <br />

          <p>
            <strong>1.1 Access to and Scope of Service.</strong> Subject to
            Sym’s receipt of the applicable Fees with respect to the service
            specified in the corresponding Service Order (the “
            <strong>Service</strong>”), Sym will use commercially reasonable
            efforts to make the Service available to Customer as set forth in
            this Agreement and the Service Order. Subject to Customer’s
            compliance with the terms and conditions of the Agreement and the
            Service Order, Customer may access and use the Service during the
            period specified in the Service Order. Any such use of the Service
            by Customer is solely for Customer’s internal business.
          </p>
          <br />

          <p>
            <strong>1.2 No-Fee Access.</strong> If Customer is accessing or
            making use of the Service on a trial basis or no-fee basis (the “
            <strong>No Fee Access</strong>” or “<strong>NFA</strong>”), Customer
            acknowledges and agrees that the NFA is provided on an “as-is”
            basis, and the NFA is provided without any indemnification, support,
            or warranties or representation of any kind. Further, the NFA is
            subject to the additional restrictions, conditions, and limitations
            published by Sym at the following URL: https://symops.com/pricing,
            as updated from time-to-time . Sym may terminate, restrict,
            discontinue or limit Customer’s access to the NFA at any time.
          </p>
          <br />

          <p>
            <strong>1.3 Restrictions.</strong> Customer will use the Service
            only in accordance with all applicable laws, including, but not
            limited to, laws related to data (whether applicable within the
            United States, the European Union, or otherwise). Customer agrees
            not to (and will not allow any third party to):
            <div className={styles.indentedBlock}>
              <br />
              (a) remove or otherwise alter any proprietary notices or labels
              from the Service or any portion thereof;
              <br />
              <br />
              (b) reverse engineer, decompile, disassemble, or otherwise attempt
              to discover the underlying structure, ideas, or algorithms of the
              Service or any software used to provide or make the Service
              available; or
              <br />
              <br />
              (c) rent, resell or otherwise allow any third party access to or
              use of the Service.
            </div>
          </p>
          <br />

          <p>
            <strong>1.4 Ownership.</strong> Sym retains all right, title, and
            interest in and to the Service, and any software, products, works or
            other intellectual property created, used, provided or made
            available by Sym under or in connection with the Service. Customer
            may from time to time provide suggestions, comments or other
            feedback to Sym with respect to the Service (“
            <strong>Feedback</strong>”). Feedback, even if designated as
            confidential by Customer, shall not create any confidentiality
            obligation for Sym notwithstanding anything else. Customer shall,
            and hereby does, grant to Sym a nonexclusive, worldwide, perpetual,
            irrevocable, transferable, sublicensable, royalty-free, fully paid
            up license to use and exploit the Feedback for any purpose.
          </p>
          <br />

          <p>
            <strong>1.5 Software.</strong> Subject to the terms and conditions
            of this Agreement, including but not limited to receipt of all
            applicable Fees, to the extent Sym makes Sym proprietary software
            available to Customer, Sym hereby grants to Customer, a limited,
            non-exclusive, non-transferable, non-assignable and
            non-sublicenseable license to: run such software solely as necessary
            to make use of the Service. Customer agrees that, it shall not:
            <div className={styles.indentedBlock}>
              <br />
              (a) exceed the scope of the licenses granted herein;
              <br />
              <br />
              (b) make copies of the software;
              <br />
              <br />
              (c) distribute, sublicense, assign, delegate, rent, lease, sell,
              time-share or otherwise transfer the benefits of, use under, or
              rights to, the license granted herein;
              <br />
              <br />
              (d) reverse engineer, decompile, disassemble or otherwise attempt
              to learn the source code, structure or algorithms underlying the
              software, except to the extent required to be permitted under
              applicable law;
              <br />
              <br />
              (e) modify, translate or create derivative works of the software;
              or
              <br />
              <br />
              (f) remove any copyright, trademark, patent or other proprietary
              notice that appears on the software or copies thereof.
            </div>
          </p>
          <br />

          <p>
            <strong>1.6 Customer Data.</strong> Customer hereby grants to Sym a
            limited, worldwide, non-exclusive, royalty-free license during the
            Term to use, reproduce, electronically distribute, transmit, have
            transmitted, display, store, archive, modify and make derivative
            works of Customer Data in fulfilment of its obligations under this
            Agreement (only), and to improve the Service. Notwithstanding
            anything to the contrary, Customer acknowledges and agrees that Sym
            may generate Aggregated De-identified Data (as defined below), and
            freely use and make available Aggregated De-identified Data for
            Sym’s business purposes (including without limitation, for purposes
            of improving, testing, operating, promoting and marketing Sym’
            products and services). “<strong>Aggregated De-identified </strong>
            <strong>Data</strong>” means data submitted to, collected by, or
            generated by Sym in connection with Customer’s use of the Service,
            but only in aggregate, de-identified form which is not linked
            specifically to Customer or any individual. For purposes of this
            Agreement, “<strong>Customer Data</strong>” shall mean any data,
            information or other material provided, uploaded, or submitted by
            Customer to the Service in the course of using the Service. Sym
            shall use commercially reasonable efforts to maintain the security
            and integrity of the Service and the Customer Data. Customer is
            responsible for the use of the Service by any person to whom
            Customer has given access to the Service, even if Customer did not
            authorize such use. Customer hereby represents and warrants that it
            will not provide or make available to Sym any Customer Data subject
            to the EU General Data Protection Regulation (the “GDPR”) or other
            applicable law or regulation with respect to data privacy, unless
            the parties first agree in writing to a Data Protection Addendum
            (the “<strong>DPA</strong>”). Sym may retain Customer Data for up to
            thirty (30) days following the termination or expiration of the
            corresponding Service Order; thereafter, Customer acknowledges that
            Customer Data may be irretrievably deleted.
          </p>
          <br />

          <p>
            <strong>1.7 Uptime and Support.</strong> Subject to Customer’s
            payment of the corresponding fees, Sym will use commercially
            reasonable efforts to make the Service available to Customer in
            accordance with the Sym support and service level terms specified in
            the corresponding Service Order (the “
            <strong>Sym Support Terms</strong>”).
          </p>
          <br />

          <p>
            <strong>1.8 Service Suspension.</strong> Sym may suspend Customer’s
            access to or use of the Service as follows:
            <div className={styles.indentedBlock}>
              <br />
              (a) immediately if Sym reasonably believes Customer’s use of the
              Service may pose a security risk to or may adversely impact the
              Service;
              <br />
              <br />
              (b) immediately if Customer become insolvent, has ceased to
              operate in the ordinary course, made an assignment for the benefit
              of creditors, or becomes the subject of any bankruptcy,
              reorganization, liquidation, dissolution or similar proceeding;
              <br />
              <br />
              (c) following thirty (30) days written notice if Customer is in
              breach of this Agreement or any Service Order (and has not cured
              such breach, if curable, within the thirty (30) days of such
              notice); or
              <br />
              <br />
              (d) Customer has failed to pay Sym the Fees with respect to the
              Service.{" "}
            </div>
          </p>

          <br />
          <h2>2. FEES AND TAXES</h2>
          <br />
          <br />

          <p>
            <strong>2.1 Fees.</strong> Customer shall pay to Sym the fees as set
            forth in each applicable Service Order(s) (collectively, the “Fees”)
            and will provide accurate and updated billing contact information.
          </p>
          <br />

          <p>
            <strong>2.2 Invoicing Terms.</strong> Sym will invoice Customer
            according to the billing frequency stated in the Service Order.
            invoices are due as specified in the corresponding Service Order. If
            any invoiced amount is not received by Sym by the due date, then
            without limiting Sym’s rights or remedies those charges may accrue
            late interest at the rate of 1.5% of the outstanding balance per
            month, or the maximum rate permitted by law, whichever is lower.
          </p>
          <br />

          <p>
            <strong>2.3 Taxes.</strong> Any and all payments made by Sym in
            accordance with this Agreement are exclusive of any taxes that might
            be assessed against Customer by any jurisdiction. Customer shall pay
            or reimburse Sym for all valueadded, sales, use, property and
            similar taxes; all customs duties, import fees, stamp duties,
            license fees and similar charges; and all other mandatory payments
            to government agencies of whatever kind, except taxes imposed on the
            net or gross income of Sym. All amounts payable to Sym under this
            Agreement shall be without set-off and without deduction of any
            taxes, levies, imposts, charges, withholdings and/or duties of any
            nature which may be levied or imposed, including without limitation,
            value added tax, customs duty and withholding tax.
          </p>

          <br />

          <h2>3. TERM AND TERMINATION</h2>
          <br />
          <br />

          <p>
            <strong>3.1 Term.</strong> The term of this Agreement shall commence
            on the Effective and unless terminated earlier according to this
            Section 3, will end on the last day of the term specified in a last
            Service Order (the “<strong>Term</strong>”).
          </p>
          <br />

          <p>
            <strong>3.2 Termination.</strong> This Agreement and the Service
            Orders hereunder may be terminated:
            <div className={styles.indentedBlock}>
              <br />
              (a) by either party if the other has materially breached this
              Agreement, within thirty (30) calendar days after written notice
              of such breach to the other party if the breach is remediable or
              immediately upon notice if the breach is not remediable; or
              <br />
              <br />
              (b) by either party upon written notice to the other party if said
              party
              <div className={styles.indentedBlock}>
                <br />
                (i) has made or attempted to make any assignment for the benefit
                of its creditors or any compositions with creditors,
                <br />
                <br />
                (ii) has any action or proceedings under any bankruptcy or
                insolvency laws taken by or against it which have not been
                dismissed within sixty (60) days,
                <br />
                <br />
                (iii) has effected a compulsory or voluntary liquidation or
                dissolution, or (iv) has undergone the occurrence of any event
                analogous to any of the foregoing under the law of any
                jurisdiction.
              </div>
            </div>
          </p>
          <br />

          <p>
            <strong>3.3 Effect of Termination.</strong> Upon any expiration or
            termination of this Agreement, Customer shall
            <div className={styles.indentedBlock}>
              <br />
              (a) immediately cease use of the Service, and
              <br />
              <br />
              (b) return all Sym Confidential Information and other materials
              and information provided by Sym. Any termination or expiration
              shall not relieve Customer of its obligation to pay all Fees
              accruing prior to termination. If the Agreement is terminated by
              Sym due to Section 3.2 (a), Customer shall pay to Sym all Fees set
              forth in the corresponding Service Order(s).
              <br />
            </div>
          </p>
          <br />

          <p>
            <strong>3.4 Survival.</strong> The following provisions will survive
            termination of this Agreement: Sections 1.4 (Ownership), 3.3 (Effect
            of Termination), Section 3.4 (Survival), Section 4
            (Confidentiality), Section 7 (Limitation of Liability), Section 8
            (Miscellaneous).
          </p>
          <br />

          <h2>4. Confidentiality</h2>
          <br />
          <br />

          <p>
            During the term of this Agreement, either party may provide the
            other party with confidential and/or proprietary materials and
            information (“<strong>Confidential Information</strong>”). All
            materials and information provided by the disclosing party and
            identified at the time of disclosure as “Confidential” or bearing a
            similar legend, and all other information that the receiving party
            reasonably should have known was the Confidential Information of the
            disclosing party, shall be considered Confidential Information. This
            Agreement is Confidential Information, and all pricing terms are Sym
            Confidential Information. The receiving party shall maintain the
            confidentiality of the Confidential Information and will not
            disclose such information to any third party without the prior
            written consent of the disclosing party. The receiving party will
            only use the Confidential Information internally for the purposes
            contemplated hereunder. The obligations in this Section shall not
            apply to any information that:
            <div className={styles.indentedBlock}>
              <br />
              (a) is made generally available to the public without breach of
              this Agreement,
              <br />
              <br />
              (b) is developed by the receiving party independently from and
              without reference to the Confidential Information,
              <br />
              <br />
              (c) is disclosed to the receiving party by a third party without
              restriction, or
              <br />
              <br />
              (d) was in the receiving party’s lawful possession prior to the
              disclosure and was not obtained by the receiving party either
              directly or indirectly from the disclosing party. The receiving
              party may disclose Confidential Information as required by law or
              court order; provided that, the receiving party provides the
              disclosing with prompt written notice thereof and uses the
              receiving party’s best efforts to limit disclosure. At any time,
              upon the disclosing party’s written request, the receiving party
              shall return to the disclosing party all disclosing party’s
              Confidential Information in its possession, including, without
              limitation, all copies and extracts thereof.
            </div>
          </p>
          <br />

          <h2>5. INDEMNIFICATION</h2>
          <br />
          <br />

          <p>
            <strong>5.1 Indemnification by Customer.</strong> Customer will
            defend, indemnify, and hold Sym, its affiliates, suppliers and
            licensors harmless and each of their respective officers, directors,
            employees and representatives from and against any claims, damages,
            losses, liabilities, costs, and expenses (including reasonable
            attorneys’ fees) arising out of or relating to any third party claim
            with respect to:
            <div className={styles.indentedBlock}>
              <br />
              (a) Customer Data;
              <br />
              <br />
              (b) breach of this Agreement or violation of applicable law by
              Customer; or
              <br />
              <br />
              (c) alleged infringement or misappropriation of third-party’s
              intellectual property rights resulting from Customer Data.
            </div>
          </p>
          <br />

          <p>
            <strong>5.2 Indemnification by Sym.</strong> Sym will defend,
            indemnify, and hold Customer harmless from and against any third
            party claims, damages, losses, liabilities, costs, and expenses
            (including reasonable attorneys’ fees) arising out of or relating to
            any third party claim with respect to:
            <div className={styles.indentedBlock}>
              <br />
              (a) a breach of this Agreement or violation of applicable law by
              Sym; or
              <br />
              <br />
              (b) claims by a thirty party that Customer’s use of the Service
              directly infringes or misappropriates a third party’sintellectual
              property rights(an “<strong>Infringement Claim</strong>”).
              Notwithstanding any other provision in this Agreement, Sym shall
              have no obligation to indemnify or reimburse Customer with respect
              to any Infringement Claim to the extent arising from:
              <div className={styles.indentedBlock}>
                <br />
                (i) the combination of any products or services, other than
                those provided by Sym to Customer under this Agreement, with the
                Service; or
                <br />
                <br />
                (ii) non-discretionary designs or specifications provided to Sym
                by Customer that caused such Infringement Claim. Customer agrees
                to reimburse Sym for any and all damages, losses, costs and
                expenses incurred as a result of any of the foregoing actions.
              </div>
            </div>
          </p>
          <br />

          <p>
            <strong>5.3 Notice of Claim and Indemnity Procedure.</strong> In the
            event of a claim for which a party seeks indemnity or reimbursement
            under this Section 5 (each an “<strong>Indemnified Party</strong>”)
            and as conditions of the indemnity, the Indemnified Party shall:
            <div className={styles.indentedBlock}>
              <br />
              (a) notify the indemnifying party in writing as soon as
              practicable, but in no event later than thirty (30) days after
              receipt of such claim, together with such further information as
              is necessary for the indemnifying party to evaluate such claim;
              and
              <br />
              <br />
              (b) the Indemnified Party allows the indemnifying party to assume
              full control of the defense of the claim, including retaining
              counsel of its own choosing. Upon the assumption by the
              indemnifying party of the defense of a claim with counsel of its
              choosing, the indemnifying party will not be liable for the fees
              and expenses of additional counsel retained by any Indemnified
              Party. The Indemnified Party shall cooperate with the indemnifying
              party in the defense of any such claim. Notwithstanding the
              foregoing provisions, the indemnifying party shall have no
              obligation to indemnify or reimburse for any losses, damages,
              costs, disbursements, expenses, settlement liability of a claim or
              other sums paid by any Indemnified Party voluntarily, and without
              the indemnifying party’s prior written consent, to settle a claim.
              Subject to the maximum liability set forth in Section 7, the
              provisions of this Section 5 constitute the entire understanding
              of the parties regarding each party’s respective liability under
              this Section 5, including but not limited to Infringement Claims
              (including related claims for breach of warranty) and each party’s
              sole obligation to indemnify and reimburse any Indemnified Party.
            </div>
          </p>
          <br />

          <h2>6. WARRANTY</h2>
          <br />
          <br />

          <p>
            <strong>6.1 Warranty.</strong> The Service will perform, in all
            material respects, the functions described in the in the
            corresponding Order Form (the “<strong>Documentation</strong>”)
            during the Term.
          </p>
          <br />

          <p>
            <strong>6.2 Exclusive Remedies.</strong> Customer shall report to
            Sym, pursuant to the notice provision of this Agreement, any breach
            of the warranties set forth in this Section 6. In the event of a
            breach of warranty by Sym under this Agreement, Sym shall promptly
            correct any non-conformance in order to minimize any material
            adverse effect on Customer’s business.
          </p>
          <br />

          <p>
            <strong>6.3 Disclaimer of Warranty.</strong> Sym does not represent
            nor warrant that the operation of the Service (or any portion
            thereof) will be uninterrupted or error free, or that the Service
            (or any portion thereof) will operate in combination with other
            hardware, software, systems or data not provided by Sym, except as
            expressly specified in the applicable Documentation. CUSTOMER
            ACKNOWLEDGES THAT, EXCEPT AS EXPRESSLY SET FORTH IN SECTION 6.1, SYM
            MAKES NO EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES OF ANY
            KIND WITH RESPECT TO THE SERVICE OR SERVICES, OR THEIR CONDITION.
            SYM IS FURNISHING THE WARRANTIES SET FORTH IN SECTION 6.1 IN LIEU
            OF, AND SYM HEREBY EXPRESSLY EXCLUDES, ANY AND ALL OTHER EXPRESS OR
            IMPLIED REPRESENTATIONS OR WARRANTIES, WHETHER UNDER COMMON LAW,
            STATUTE OR OTHERWISE, INCLUDING WITHOUT LIMITATION ANY AND ALL
            WARRANTIES AS TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            SATISFACTORY QUALITY OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS.
          </p>
          <br />

          <h2>7. LIMITATIONS OF LIABILITY</h2>
          <br />
          <br />

          <p>
            IN NO EVENT SHALL SYM BE LIABLE FOR ANY LOST DATA, LOST PROFITS,
            BUSINESS INTERRUPTION, REPLACEMENT SERVICE OR OTHER SPECIAL,
            INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR INDIRECT DAMAGES, HOWEVER
            CAUSED AND REGARDLESS OF THEORY OF LIABILITY. OTHER THAN WITH
            RESPECT TO SYM’S OBLIGATIONS UNDER SECTION 5 (INDEMNIFICATION),
            SYM’S LIABILITY FOR ALL CLAIMS ARISING UNDER THIS AGREEMENT, WHETHER
            IN CONTRACT, TORT OR OTHERWISE, SHALL NOT EXCEED THE AMOUNT OF FEES
            PAID OR PAYABLE BY CUSTOMER UNDER THE APPLICABLE SERVICE ORDER
            DURING THE TWELVE (12) MONTH PERIOD PRECEEDING THE CLAIM.
            NOTWITHSTANDING ANYTHING TO THE CONTRARY, SYM’S TOTAL LIABILITY WITH
            RESPECT TO SECTION 5 (INDEMNIFICATION) SHALL NOT EXCEED TWO TIME
            (2X) THE FEES PAID OR PAYABLE BY CUSTOMER UNDER THE APPLICABLE
            SERVICE ORDER.
          </p>
          <br />

          <h2>8. MISCELLANEOUS</h2>
          <br />
          <br />

          <p>
            <strong>8.1 Export Control.</strong> Customer hereby certifies that
            Customer will comply with all current US Export Control laws.
            Customer agrees to defend, indemnify and hold Sym harmless from any
            liability for Customer’s violation of U.S. Export Control laws.
          </p>
          <br />

          <p>
            <strong>8.2 Compliance with Laws.</strong> The parties shall comply
            with all applicable laws and regulations in its use or provision of
            the Service, including without limitation the unlawful gathering or
            collecting, or assisting in the gathering or collecting of
            information in violation of any privacy laws or regulations. Each
            party shall, at its own expense, defend, indemnify and hold harmless
            the other party from and against any and all claims, losses,
            liabilities, damages, judgments, government or federal sanctions,
            costs and expenses (including attorneys’ fees) incurred by it
            arising from any claim or assertion by any third party of violation
            of privacy laws or regulations by the other party or any of its
            agents, officers, directors or employees.
          </p>
          <br />

          <p>
            <strong>8.3 Assignment.</strong> Neither party may transfer and
            assign its rights and obligations under this Agreement without the
            prior written consent of the other party. Notwithstanding the
            foregoing, Sym may freely transfer and assign its rights under this
            Agreement in connection with a change in control, acquisition or
            sale of all or substantially all of its assets.
          </p>
          <br />

          <p>
            <strong>8.4 Force Majeure.</strong> Neither party shall be
            responsible for failure or delay in performance by events out of
            their reasonable control, including but not limited to, acts of God,
            Internet outage, terrorism, war, fires, earthquakes, pandemic and
            other disasters (each a “<strong>Force Majeure</strong>”).
            Notwithstanding the foregoing:
            <div className={styles.indentedBlock}>
              <br />
              (a) Customer shall be liable for payment obligations for Service
              rendered; and
              <br />
              <br />
              (b) if a Force Majeure continues for more than thirty (30) days,
              either party may to terminate this agreement by written notice to
              the other party.
            </div>
          </p>
          <br />

          <p>
            <strong>8.5 Notice.</strong> All notices between the parties shall
            be in writing and shall be deemed to have been given if personally
            delivered or sent by registered or certified mail (return receipt),
            by recognized courier service, or via email, provided the other
            party acknowledges such transmission.
          </p>
          <br />

          <p>
            <strong>8.6 No Agency.</strong> Both parties agree that no agency,
            partnership, joint venture, or employment is created as a result of
            this Agreement. Customer does not have any authority of any kind to
            bind Sym.
          </p>
          <br />

          <p>
            <strong>8.7 Governing Law.</strong> This Agreement shall be governed
            exclusively by, and construed exclusively in accordance with, the
            laws of the United States and the State of California, without
            regard to its conflict of laws provisions. The federal courts of the
            United States in the Northern District of California and the state
            courts of the State of California shall have exclusive jurisdiction
            to adjudicate any dispute arising out of or relating to this
            Agreement. Each party hereby consents to the jurisdiction of such
            courts and waives any right it may otherwise have to challenge the
            appropriateness of such forums, whether on the basis of the doctrine
            of forum non conveniens or otherwise. The United Nations Convention
            on Contracts for the International Sale of Goods shall not apply to
            this Agreement or any Purchase Order issued under this Agreement.
          </p>
          <br />

          <p>
            <strong>8.8 Publicity.</strong> Customer hereby grants Sym the right
            to identify Customer as a Sym Customer, and use Customer’s name,
            mark and logo on Sym’s website and in Sym’s marketing materials with
            respect to the same.
          </p>
          <br />

          <p>
            <strong>8.9 Insurance.</strong> During the Term, Sym shall, at its
            own expense, maintain and carry in full force and effect at least
            the types and amounts of insurance coverage listed in Appendix A,
            subject to the requirements therein.
          </p>
          <br />

          <p>
            <strong>8.10 Entire Agreement.</strong> This Agreement is the
            complete and exclusive statement of the mutual understanding of the
            parties and supersedes and cancels all previous written and oral
            agreements, communications, and other understandings relating to the
            subject matter of this Agreement, and all waivers and modifications
            must be in a writing signed by both parties, except as otherwise
            provided herein. Any term or provision of this Agreement held to be
            illegal or unenforceable shall be, to the fullest extent possible,
            interpreted so as to be construed as valid, but in any event the
            validity or enforceability of the remainder hereof shall not be
            affected. In the event of a conflict between this Agreement and the
            Service Order document, the terms of this Agreement shall control.
          </p>
          <br />
          <br />
          <br />
        </div>
      </Layout>
    </>
  );
}
