import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import "./UseCasePostSlideshow.css";
import ImageSlider from "../../../../HomePage/Components/ImageSlider/ImageSlider";
import Slider from "../../../../Common/Slider/Slider";

const INITIAL_INDEX = 0;

export default function UseCasePostSlideshow(props) {
  const { item } = props;

  const sliderRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(INITIAL_INDEX);
  const [width, setWidth] = useState(0);
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    // Set up array of slides
    if (item.content && item.content.length > 0) {
      setSliderData(
        item.content.map((item, index) => {
          return {
            name: item.title,
            description: item.description,
            image: item.image,
          };
        })
      );
    }

    sliderRef.current?.slickGoTo(INITIAL_INDEX);
  }, [item.content]);

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  });

  const swipedHandler = (eventData, isNext) => {
    const {
      event: {
        target: { className },
      },
    } = eventData;
    if (
      className?.includes &&
      className.includes("swipable-area") &&
      width < 1024.01
    ) {
      if (isNext) {
        sliderRef.current?.slickNext();
      } else {
        sliderRef.current?.slickPrev();
      }
    }
  };

  return (
    <div className="use-case-post-slideshow">
      <div className="slider-wrapper">
        <Slider
          ref={sliderRef}
          onChange={setCurrentSlide}
          onSwipedLeft={(eventData) => swipedHandler(eventData, true)}
          onSwipedRight={(eventData) => swipedHandler(eventData, false)}
        >
          {sliderData.map((item, index) => (
            <ImageSlider
              key={`carousel-${index}`}
              {...item}
              selected={currentSlide === index}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}
