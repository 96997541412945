import React from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import Left from "../../../Icons/Left";
import Right from "../../../Icons/Right";
import styles from "./ImageSlider.module.css";

export default function ImageSlider(props) {
  const {
    name,
    description,
    selected,
    imageLeft,
    imageRight,
    image,
    isCompare,
    altOverlay,
  } = props;

  return (
    <div className="flex flex-col justify-between h-full mx-5 md:mx-14 image-slider-wrapper">
      <div className="flex-1 w-full pb-11 mt-3 flex flex-col items-center px-2 swipable-area">
        <span
          className={`${styles.sliderTitle} md:text-xl text-sm font-semibold text-center uppercase swipable-area image-slider-title`}
        >
          {name}
        </span>
        <span className="mt-2 image-slider-description">
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className={`${styles.sliderDesc} swipable-area image-slider-description-text`}
          ></div>
        </span>
      </div>

      {isCompare && (
        <ReactCompareSlider
          className={`rounded-b-none border border-black ${styles.compareSlider}`}
          handle={
            selected ? (
              <div className="flex justify-center items-center h-full">
                <div className={styles["slider-handle"]}>
                  <div className={styles["slider-handle-arrow-box"]} />
                  <div className="flex z-10">
                    <div className="w-5">
                      <Left></Left>
                    </div>
                    <div className="w-5">
                      <Right></Right>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )
          }
          itemOne={
            <div>
              <ReactCompareSliderImage
                src={imageLeft}
                style={{ maxHeight: "700px" }}
                alt="one"
              />
              <div
                className={`${
                  styles["sliderOverlay"]
                } transition-opacity duration-500 ease-out ${
                  selected ? "opacity-0" : "opacity-100"
                }`}
              />
            </div>
          }
          itemTwo={
            <div>
              <ReactCompareSliderImage
                src={imageRight}
                style={{ maxHeight: "700px" }}
                alt="two"
              />
              <div
                className={`${
                  styles["sliderOverlay"]
                } transition-opacity duration-500 ease-out ${
                  selected ? "opacity-0" : "opacity-100"
                }`}
              />
            </div>
          }
        />
      )}
      {isCompare && (
        <div style={{ height: "83px" }} className="swipable-area" />
      )}

      {!isCompare && (
        <div
          className={`image-slider-image-wrapper ${
            selected ? "is-active" : "is-not-active"
          }`}
        >
          <div
            className={`${styles["imageSliderImageContainer"]} ${
              selected ? "is-active" : "is-not-active"
            }`}
          >
            <img
              className={`${styles["sliderImage"]} image-slider-image ${
                selected ? "is-active" : "is-not-active"
              }`}
              src={image}
              alt=""
            />
            {altOverlay && (
              <div
                className={`${styles["imageSliderImageOverlay"]} ${
                  styles["fadein"]
                } ${selected ? styles["hideImageSliderOverlay"] : ""}`}
              ></div>
            )}
          </div>
          {!altOverlay && (
            <div
              className={`${
                styles["sliderOverlay"]
              } image-slider-overlay transition-opacity duration-500 ease-out ${
                selected ? "opacity-0" : "opacity-100"
              }`}
            />
          )}
        </div>
      )}
    </div>
  );
}
