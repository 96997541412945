import Helmet from "react-helmet";
import "./SelfServiceSignUpError.css";
import Layout from "../../components/Layout";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useState } from "react";

const PAGE_TITLE = "Sign Up | Sym";
const API_URL = process.env.REACT_APP_SYM_API_URL;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_SIGNUP_RECAPTCHA_KEY || ""; // suppress undefined error

declare global {
  interface Window {
    analytics: any;
  }
}

export default function SelfServiceSignUpError() {
  const queryString = useLocation().search; // Get the query params as one long string
  const queryParams = new URLSearchParams(queryString); // Parse the query params into an object
  const errorMessage = queryParams.get("error");
  // The user_email query param is base64-encoded. Decode it to get the plain string.
  const email = atob(queryParams.get("user_email") || "");
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [inputEnabled, setInputEnabled] = useState(true);
  const [displayError, setDisplayError] = useState("");

  const handleResendEmailButtonClick = () => {
    window.analytics.track("Resend Welcome Email Clicked", {
      email: email,
    });
    // Trigger the ReCAPTCHA challenge, which will then trigger the POST request after the challenge succeeds
    recaptchaRef.current?.execute();
  };
  const handleBackButtonClick = () => {
    window.location.href = "/signup";
  };

  const handleReCAPTCHASubmit = async (value: string | null) => {
    if (value !== null) {
      // Disable the button while we wait for the request to process
      setInputEnabled(false);

      const response = await fetch(API_URL + "/signup/resend-welcome", {
        method: "POST",
        body: JSON.stringify({
          email: email,
          recaptcha_response: value,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).catch(() => {
        setDisplayError(
          "Something unexpected went wrong. Please refresh this page and try again."
        );
      });

      // If we caught an error above (e.g. couldn't reach the API), response will be undefined and
      // the unexpected error text should display, so stop evaluating the form submission.
      if (!response) {
        return;
      }

      // We successfully got an API response, but it was not a 200
      if (!response.ok) {
        // Re-enable the button
        setInputEnabled(true);

        // Sometimes this will throw an error "Cannot read property 'style' of null"
        // It can be ignored, see: https://github.com/dozoisch/react-google-recaptcha/issues/129
        recaptchaRef.current?.reset();
        setDisplayError("Something unexpected went wrong. Please try again.");
        return;
      }

      // Our API request was successful, show the confirmation page
      window.location.href =
        "/signup/resent-welcome?user_email=" + encodeURIComponent(btoa(email));
    } else {
      // The reCAPTCHA expired, so reset it.
      // Sometimes this will throw an error "Cannot read property 'style' of null"
      // It can be ignored, see: https://github.com/dozoisch/react-google-recaptcha/issues/129
      recaptchaRef.current?.reset();
    }
  };

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/signup/error" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="w-full max-w-6xl flex flex-col mx-auto px-6 py-0 lg:py-12 gap-24">
          <div className="mx-auto self-center w-full max-w-4xl px-5 md:px-12 lg:px-20 py-20 my-14 rounded-lg shadow-md text-center bg-gradient-fade">
            {/*Error = User already exists*/}
            {errorMessage && errorMessage === "user_exists" && email && (
              <div>
                <h2 className="text-4xl uppercase">Hang on a sec.</h2>
                <p className="mt-10 text-xl">
                  It looks like you already have a Sym account associated with
                  this email address (<b>{email}</b>). Please check your email
                  for your Organization ID, or hit the button below to resend
                  it.
                </p>
                <p className="mt-10 text-md">
                  If you would like to associate this email with multiple
                  organizations, please reach out to{" "}
                  <a href="mailto:support@symops.com" className="text-blue-500">
                    support@symops.com
                  </a>{" "}
                  and we can help you!
                </p>
                <div className="mt-10">
                  <button
                    onClick={handleBackButtonClick}
                    className="cta-button secondary"
                  >
                    Back
                  </button>
                  <button
                    onClick={handleResendEmailButtonClick}
                    disabled={!inputEnabled}
                    className="cta-button ml-6"
                  >
                    Resend email
                  </button>
                  <div className="errors">
                    {displayError && (
                      <p className="text-red-600">{displayError}</p>
                    )}
                  </div>
                </div>
                {/*
                  ReCAPTCHA will be triggered when someone clicks on the resend email button
                  After the ReCAPTCHA is processed, it'll make the POST request to Queuer with the user's email.
                */}
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={RECAPTCHA_SITE_KEY}
                  onChange={handleReCAPTCHASubmit}
                />
              </div>
            )}

            {/*No error message, or the error message is some other message from Queuer*/}
            {(!errorMessage || errorMessage !== "user_exists") && (
              <div className="flex flex-col gap-6">
                <h2>Oops</h2>
                <h4>Something went wrong.</h4>

                {errorMessage && (
                  <p className="text-red-600 text-xl max-w-2xl">
                    {errorMessage}
                  </p>
                )}
                <div className="mt-10">
                  <button
                    onClick={handleBackButtonClick}
                    className="cta-button secondary"
                  >
                    Back
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}
