import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import MeetOurTeam from "../components/sections/about/Team/Team";
import Investors from "../components/sections/about/Investors/Investors";
import AnimationContainer from "../components/AnimationContainer/AnimationContainer";

const PAGE_TITLE = "Learn About Us | Sym";

export default function About() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/about" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="hero-bg-wrapper">
          <AnimationContainer animationClass="yellow-section-animation">
            <div className="w-full max-w-7xl mx-auto px-6">
              <div className="py-12 sm:py-20 text-center">
                <div className="flex flex-col gap-6">
                  <h1>Meet Our Team</h1>
                  <p className="w-full max-w-lg mx-auto text-xl">
                    We empower engineers to be secure and go fast. Meet the team
                    that makes it all happen.
                  </p>
                </div>
              </div>

              <div>
                <MeetOurTeam />
              </div>

              <div className="mt-20">
                <Investors />
              </div>
            </div>
          </AnimationContainer>
          <div className="hero-bg about-hero-bg"></div>
        </div>
      </Layout>
    </>
  );
}
