export default function BottomCta(props) {
  return null;
}
/*
import React from "react";

import "./BottomCta.css";
import AnimationContainer from "../../../AnimationContainer/AnimationContainer";
import CalendlyPopupTrigger from "../../../Common/CalendlyPopupTrigger.js";

export default function BottomCta(props) {
  return (
    <AnimationContainer animationClass="product-features-section-animation">
      <section className="w-full flex content-center px-16 py-32 lg:py-48 relative bg-bottom-cta">
        <div className="w-full max-w-4xl mx-auto flex flex-col items-center justify-center text-center">
          <h2 className="mb-4">
            {props.title ? props.title : "Start making better access decisions"}
          </h2>
          <p className="text-lg">
            {props.text
              ? props.text
              : "Replace complex, manual authorization processes with maintainable, automated workflows"}
          </p>
          <div className="mt-12">
            {props.demoButton ? (
              <CalendlyPopupTrigger calendlyLink="https://calendly.com/hello-sym/sym-product-25">
                <div
                  className="cta-button"
                  id={
                    props.id
                      ? `btn-${props.id}-bottom-cta-schedule-demo`
                      : "btn-bottom-cta-schedule-demo"
                  }
                >
                  Schedule a Demo
                </div>
              </CalendlyPopupTrigger>
            ) : (
              <a
                href={`/signup`}
                className="cta-button"
                id={
                  props.id
                    ? `btn-${props.id}-bottom-try-sym`
                    : "btn-bottom-try-sym"
                }
              >
                Get Started
              </a>
            )}
          </div>
        </div>
      </section>
    </AnimationContainer>
  );
}
*/
