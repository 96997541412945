import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import HeroSection from "../components/sections/compliance/HeroSection/HeroSection";
import Logging from "../components/sections/compliance/Logging/Logging";
import Workflow from "../components/sections/compliance/Workflow/Workflow";
import AnimationContainer from "../components/AnimationContainer/AnimationContainer";
import SalesForm from "../components/Common/SalesForm/SalesForm";

const PAGE_TITLE = "Compliance | Sym";

export default function Compliance() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/use-cases/compliance" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="hero-bg-wrapper">
          <div className="w-full max-w-7xl mx-auto px-6">
            <AnimationContainer animationClass="platform-white-sections-animation">
              <HeroSection />
              <div className="mb-16">
                <SalesForm mailchimpUseCaseTag="[Use Cases] Compliance Page" />
              </div>
              <Logging />
              <Workflow />
            </AnimationContainer>
          </div>
          <div className="hero-bg pricing-hero-bg"></div>
        </div>
      </Layout>
    </>
  );
}
