import React from "react";

import "../../sections.css";
import "./heroSection.css";

export default function HeroSection() {
  return (
    <div className="flex flex-col items-center justify-center gap-12 py-16 lg:py-24 text-center">
      <h1 className="text-4xl">
        Evidence of your secure workflows and compliance is a natural byproduct
        of using <span className="text-blue">Sym</span>.
      </h1>
      <div className="text-2xl">
        For every workflow you build with our SDK, we generate policy diagrams,
        and for every access request, escalation, and deescalation, we generate
        robust audit logs. All you have to do is connect us to a log destination
        of your choice.
      </div>
      <div className="text-2xl font-bold">
        Build your Sym workflows for security, and the evidence comes for free —
        as it should.
      </div>
    </div>
  );
}
