import React from "react";

import auditIllustration from "../images/audit-illustration.svg";
import privacyIllustration from "../images/privacy-illustration.svg";
import foundationIllustration from "../images/foundation-illustration.svg";
import detailsIllustration from "../images/details-illustration.svg";

import "./SecurityParagraphs.css";

export default function SecurityParagraphs() {
  const securityParagraphsArray = [
    {
      title: "Assurance Through Audit",
      text:
        "SOC 2 Type II – We have established processes and internal monitoring controls to give us the confidence that we’re operating at the highest standards. Externally, we undergo an annual SOC 2 Type II audit to ensure that our security program is appropriately managed and there is continuous improvement. Our report is available to all customers and prospects upon request.",
      icon: auditIllustration,
    },
    {
      title: "Respect Privacy",
      text:
        'We are deliberate in determining what information is collected and what is not collected. Our <a href="privacy">Privacy Policy</a> outlines all of our customer’s rights with regards to GDPR and CCPA. Our goal is to minimize the amount of information collected, and have full transparency along the way.',
      icon: privacyIllustration,
    },
    {
      title: "Our Secure Foundation",
      text:
        "Sym is fully hosted in Amazon Web Services (AWS), which provides industry-leading physical security and environmental controls. We utilize multiple availability zones, which guarantees that data is kept in separate physical locations, provides redundancy, and ensure systems are not subject to the same environmental risks. System automation is used heavily to minimize the likelihood of human error and ensure business continuity processes are sound.",
      icon: foundationIllustration,
    },
    {
      title: "Security in the Details",
      text:
        "We adopt a number of security best practices when it comes to data and access security. Our networks are segregated in VPCs with a minimal number of external endpoints. Network access is controlled by a firewall and key services require MFA. All sensitive system data is encrypted at rest using AES 256bit encryption. Data transmitted through our encrypted APIs or via the customer's browser is transferred over HTTPS/TLS 1.2. System administration occurs only over encrypted protocols.",
      icon: detailsIllustration,
    },
  ];

  return (
    <div className="flex grid grid-cols-1 lg:grid-cols-2 gap-8">
      {securityParagraphsArray.map((paragraph, i) => {
        return (
          <div
            key={i}
            className="flex flex-col h-full gap-6 p-8 md:p-12 relative bg-gradient-fade"
          >
            <div
              className="w-full mb-4 flex-shrink-0 flex flex-row items-center"
              style={{ height: "100px" }}
            >
              <img
                src={paragraph.icon}
                alt={`${paragraph.title}`}
                className="h-full w-auto"
                style={{ maxHeight: "100px" }}
              />
            </div>
            <p className="text-3xl font-bold">{paragraph.title}</p>
            <div>
              <p
                className="text-md opacity-80"
                dangerouslySetInnerHTML={{
                  __html: paragraph.text,
                }}
              ></p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
