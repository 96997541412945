import React from "react";

import "./UseCasePostImage.css";

export default function UseCasePostImage(props) {
  const { item } = props;

  return (
    <div className="use-case-post-image">
      <img src={item.src} alt="" />
      {item.caption && <p className="image-caption">{item.caption}</p>}
    </div>
  );
}
