import React, { useEffect } from "react";
import Helmet from "react-helmet";
import "./Webinar.css";

import Layout from "../../components/Layout";
import AnimationContainer from "../../components/AnimationContainer/AnimationContainer";

export default function Customers() {
  const PAGE_TITLE = "Webinar | Sym";

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "24420351",
          formId: "0aafa48b-6db1-42f3-b5ae-56e253cb8997",
          target: "#hubspotForm",
          css: "",
        });
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="hero-bg-wrapper">
          <AnimationContainer animationClass="platform-white-sections-animation">
            <div className="w-full max-w-7xl mx-auto px-6">
              <div className="mb-12 py-12 sm:py-16">
                <div className="flex flex-col lg:flex-row items-center gap-12">
                  <div className="flex flex-col gap-6">
                    <h1>Why does everyone hate security?</h1>
                    <p className="text-2xl">
                      Watch the hour-long panel discussion and Q&A with security
                      experts from Orum, Courier, and HYPR, covering topics
                      like:
                    </p>
                    <ul className="flex flex-col gap-1 text-xl list-disc pl-6">
                      <li>Compliance vs. efficiency</li>
                      <li>
                        Dealing with external pressures like budgets and
                        headcount
                      </li>
                      <li>How to successfully implement access controls</li>
                    </ul>
                  </div>
                  <div className="w-full max-w-none lg:max-w-md flex-shrink-0 bg-gradient-fade p-6">
                    <div>
                      <h3 className="mb-6">Get the recording</h3>
                      <div id="hubspotForm"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-24 text-center">
                <div className="mb-16">
                  <h2>Meet the panelists</h2>
                </div>
                <div className="w-full max-w-5xl mx-auto flex flex-wrap flex-col justify-center">
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-12 md:gap-8">
                    <div className="flex flex-col justify-center items-center gap-4">
                      <img
                        src="/assets/webinar/webinar-panelist-heidi.jpg"
                        alt="Heidi Waterhouse"
                        className="w-full flex-shrink-0 inline-block rounded-full"
                        style={{ maxWidth: "200px" }}
                      />
                      <div>
                        <h5 className="font-bold">Heidi Waterhouse</h5>
                        <p className="opacity-80">Panel host</p>
                      </div>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-4">
                      <img
                        src="/assets/webinar/webinar-panelist-justin.jpg"
                        alt="Justin Hadley"
                        className="w-full flex-shrink-0 inline-block rounded-full"
                        style={{ maxWidth: "200px" }}
                      />
                      <div>
                        <h5 className="font-bold">Justin Hadley</h5>
                        <p className="opacity-80">Director of Infosec @ Orum</p>
                      </div>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-4">
                      <img
                        src="/assets/webinar/webinar-panelist-seth.jpg"
                        alt="Seth Carney"
                        className="w-full flex-shrink-0 inline-block rounded-full"
                        style={{ maxWidth: "200px" }}
                      />
                      <div>
                        <h5 className="font-bold">Seth Carney</h5>
                        <p className="opacity-80">CTO @ Courier</p>
                      </div>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-4">
                      <img
                        src="/assets/webinar/webinar-panelist-anton.jpg"
                        alt="Anton Gurav"
                        className="w-full flex-shrink-0 inline-block rounded-full"
                        style={{ maxWidth: "200px" }}
                      />
                      <div>
                        <h5 className="font-bold">Anton Gurav</h5>
                        <p className="opacity-80">CISO @ HYPR</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AnimationContainer>
          <div className="hero-bg pricing-hero-bg"></div>
        </div>
      </Layout>
    </>
  );
}
