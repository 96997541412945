import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/Layout";
import BottomCta from "../../components/HomePage/Sections/BottomCta/BottomCta";
import IntegrationOtherPosts from "../../components/sections/integrations/IntegrationOtherPosts/IntegrationOtherPosts";

const PAGE_TITLE = "Integrations | Sym";

export default function Security() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/integrations" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="hero-bg-wrapper">
          <div className="w-full max-w-7xl mx-auto px-6">
            <div className="mb-12 py-12 sm:py-16 text-center">
              <div className="flex flex-col items-center gap-6">
                <h1 className="w-full max-w-5xl">
                  Manage access and integrate context with ease
                </h1>
              </div>
            </div>
            <IntegrationOtherPosts />
          </div>
          <div className="hero-bg integration-hero-bg"></div>
        </div>
        <BottomCta id="security" />
      </Layout>
    </>
  );
}
