import add_to_slack from "../../static/img/add_to_slack.png";

export default function AddToSlack() {
  return (
    <>
      <div className="text-center">
        <a
          href="https://api.symops.com/api/v1/services/slack/install"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => window.analytics.track("Clicked Add To Slack")}
        >
          <img
            className="inline-block"
            alt="Add to Slack"
            style={{ width: "140px" }}
            src={add_to_slack}
          />
        </a>
        <p className="font-bold mt-2">
          <a
            href="https://static.symops.com/slack/add"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => window.analytics.track("Clicked About Sym + Slack")}
          >
            About Sym + Slack
          </a>
        </p>
      </div>
    </>
  );
}
