import React from "react";

import "./UseCaseOtherPosts.css";

import UseCaseListItem from "../../UseCaseList/UseCaseListItem/UseCaseListItem";
import UseCases from "../../../../../pages/UseCases/UseCasesList";

export default function UseCaseOtherPosts(props) {
  const index = props.index;

  let prevIndex = index === 0 ? UseCases.length - 1 : index - 1;
  let nextIndex = index === UseCases.length - 1 ? 0 : index + 1;

  return (
    <div className="flex flex-col text-center items-center gap-12">
      <h2>Other Use Cases</h2>
      <div className="w-full max-w-md lg:max-w-4xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12">
        <UseCaseListItem useCase={UseCases[prevIndex]} />
        <UseCaseListItem useCase={UseCases[nextIndex]} />
      </div>
    </div>
  );
}
