import React from "react";
import Helmet from "react-helmet";
import "./Whitepaper.css";

import Layout from "../../components/Layout";
import AnimationContainer from "../../components/AnimationContainer/AnimationContainer";

import { useEffect } from "react";
import BottomCta from "../../components/HomePage/Sections/BottomCta/BottomCta";

export default function JITAccessWhitepaper() {
  const PAGE_TITLE = "JIT Access Guide | Sym";

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: "24420351",
          formId: "82f7ba43-635c-4466-98b9-cf39367a8f08",
          target: "#hubspotForm",
          css: "",
        });
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <AnimationContainer animationClass="platform-white-sections-animation">
          <div className="hero-bg-wrapper">
            <div className="w-full max-w-7xl mx-auto px-6 pb-24">
              <div className="py-12 sm:py-16 text-center">
                <div className="flex flex-col gap-6">
                  <h1>How to implement JIT access in AWS</h1>
                  <p className="w-full mx-auto text-xl">
                    Securing your infrastructure without slowing down is hard.
                    Our guide walks you through it.
                  </p>
                </div>
              </div>
              <div className="w-full max-w-4xl mx-auto flex flex-col lg:flex-row gap-12 lg:gap-16 justify-between">
                <div className="w-full max-w-none lg:max-w-sm flex-shrink-0">
                  <img
                    src="../assets/whitepapers/whitepaper-jit-graphic.svg"
                    alt="Whitepaper Graphic"
                    className="w-full max-w-sm mx-auto lg:mx-0"
                  />
                </div>
                <div className="w-full max-w-none lg:max-w-md flex flex-col p-8 lg:p-12 bg-gradient-fade gap-4 border border-sym-blue">
                  <h3 className="text-3xl">What you'll learn</h3>
                  <ul className="list-disc pl-6 flex flex-col gap-1">
                    <li>The principle of least privilege</li>
                    <li>Least privilege in the cloud and AWS</li>
                    <li>Designing least privilege policies</li>
                    <li>Replacing risky access with automation</li>
                  </ul>
                  <p>
                    We'll also cover why just-in-time access is important, how
                    to implement it in AWS, and steps towards automating access
                    for your organization.
                  </p>
                  <div className="mt-6 text-left">
                    <div id="hubspotForm"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-bg jit-hero-bg"></div>
          </div>
          <BottomCta id="jit" />
        </AnimationContainer>
      </Layout>
    </>
  );
}
