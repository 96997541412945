import React from "react";
import Helmet from "react-helmet";
import { useParams, Redirect } from "react-router-dom";

import Layout from "../../components/Layout";
import AnimationContainer from "../../components/AnimationContainer/AnimationContainer";
import UseCasePost from "../../components/sections/useCases/UseCasePost/UseCasePost";

import UseCases from "./UseCasesList";

export default function UseCasePosts() {
  const { id } = useParams();
  const useCase = UseCases.find((u) => u.id === id);

  // Redirect to use cases page if not found
  if (!useCase) {
    return <Redirect to="/product" />;
  }

  const PAGE_TITLE = `${useCase.title} | Sym`;

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link
          rel="canonical"
          href={`https://symops.com/use-cases/${useCase.id}`}
        />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <React.Fragment>
        <Layout>
          <div className="hero-bg-wrapper">
            <AnimationContainer animationClass="platform-white-sections-animation">
              <section className="w-full max-w-7xl mx-auto px-6">
                <UseCasePost useCase={useCase} />
              </section>
            </AnimationContainer>
            <div className="hero-bg product-hero-bg"></div>
          </div>
        </Layout>
      </React.Fragment>
    </>
  );
}
