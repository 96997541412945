import React from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router-dom";
import { parse } from "@billjs/query-string";

const UtmTracker: React.FunctionComponent<RouteComponentProps> = () => {
  const location = useLocation();
  const parsedParams = parse(location.search);
  const utmParamsWhitelist = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];

  // Set localStorage with UTM params (if they exist)
  utmParamsWhitelist.forEach((term) => {
    if (parsedParams[term]) {
      localStorage.setItem(term, parsedParams[term]);
    }
  });

  return null;
};

export default withRouter(UtmTracker);
