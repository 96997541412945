import React from "react";
import styles from "./Footer.module.css";
import LinkedIn from "./Icons/LinkedIn";
import Twitter from "./Icons/Twitter";
import Github from "./Icons/Github";
import Logo from "./Common/Logo";

export default function Footer() {
  return (
    <footer className="w-full items-center flex flex-col bg-sym-dark md:px-8 py-16 px-4">
      <div className="flex md:max-w-7xl w-full md:flex-row flex-col justify-between">
        <div className="md:w-4/12 flex flex-col w-full">
          <div className="w-24 mb-8">
            <Logo />
          </div>

          <p>
            Sym is the most developer-friendly way to secure your production
            infrastructure.
          </p>
        </div>

        <nav
          className="flex flex-1 md:w-5/12 w-full md:flex-col flex-col-reverse
          md:pl-10 pl-0 justify-between md:mt-0 mt-10"
        >
          <div className="flex justify-between w-full md:mt-0 mt-10">
            <div className={`${styles.navBlock}`}>
              <h5>Use Cases</h5>
              <ul>
                <li>
                  <a
                    title="Reduce default access"
                    href="/product/reduce-access"
                  >
                    Reduce default access
                  </a>
                </li>
                <li>
                  <a title="Achieve compliance" href="/product/compliance">
                    Achieve compliance
                  </a>
                </li>
                <li>
                  <a
                    title="Protect customer data"
                    href="/product/customer-data"
                  >
                    Protect customer data
                  </a>
                </li>
                <li>
                  <a title="AWS Identity Center" href="/integrations/aws-sso">
                    Temporary AWS Access
                  </a>
                </li>
              </ul>
            </div>

            <div className={`${styles.navBlock}`}>
              <h5>Resources</h5>
              <ul className="text-sm md:text-lg">
                <li>
                  <a title="Blog" href="https://blog.symops.com">
                    Blog
                  </a>
                </li>
                <li>
                  <a title="Docs" href="https://docs.symops.com">
                    Docs
                  </a>
                </li>
                <li>
                  <a
                    title="Changelog"
                    href="https://headwayapp.co/sym-changelog"
                  >
                    Changelog
                  </a>
                </li>
              </ul>
            </div>

            <div className={`${styles.navBlock}`}>
              <h5>Company</h5>
              <ul className="text-sm md:text-lg">
                <li>
                  <a title="About" href="/about">
                    About
                  </a>
                </li>
                <li>
                  <a title="Security" href="/security">
                    Security
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="mt-16 flex md:max-w-7xl w-full flex-col md:flex-row items-start md:items-center justify-between gap-6 md:text-base font-normal text-sm">
        <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
          <a
            href="https://www.aicpa-cima.com/resources/landing/system-and-organization-controls-soc-suite-of-services"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="assets/soc-logo.png"
              alt="AICPA SOC"
              className="w-16 block"
            />
          </a>
          <p className="font-mono text-xs uppercase">
            COPYRIGHT {new Date().getFullYear()} SYMOPS, INC.
            <br />
            Made with{" "}
            <span role="img" aria-label="heart">
              ❤️
            </span>
            &nbsp;from all over.
          </p>
        </div>
        <ul className="flex flex-row gap-8 text-sm opacity-80">
          <li>
            <a title="Sales" href="/terms">
              Terms of Service
            </a>
          </li>
          <li>
            <a title="Privacy" href="/privacy">
              Privacy Policy
            </a>
          </li>
          <li>
            <a title="Sales" href="/subprocessors">
              Authorized Subprocessors
            </a>
          </li>
        </ul>
        <div className="flex flex-row gap-3">
          <a
            className="w-10"
            href="https://github.com/symopsio"
            rel="noreferrer"
            target="_blank"
          >
            <Github />
            <span className="sr-only">Github</span>
          </a>
          <a
            className="w-10"
            href="https://www.linkedin.com/company/symops"
            rel="noreferrer"
            target="_blank"
          >
            <LinkedIn />
            <span className="sr-only">LinkedIn</span>
          </a>
          <a
            className="w-10"
            href="https://twitter.com/symops"
            rel="noreferrer"
            target="_blank"
          >
            <Twitter />
            <span className="sr-only">Twitter</span>
          </a>
        </div>
      </div>
    </footer>
  );
}
