import Helmet from "react-helmet";
import "./SelfServiceSignUpSuccess.css";
import Layout from "../../components/Layout";
import { useLocation } from "react-router-dom";
import React from "react";

const PAGE_TITLE = "Welcome Email Sent! | Sym";

export default function SelfServiceResendWelcomeSuccess() {
  const queryString = useLocation().search; // Get the query params as one long string
  const queryParams = new URLSearchParams(queryString); // Parse the query params into an object

  // The user_email query param is base64-encoded. Decode it to get the plain string.
  const email = atob(queryParams.get("user_email") || "");

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/signup/resent-welcome" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout hideBanner={true}>
        <div className="w-full max-w-6xl flex flex-col mx-auto px-6 py-0 lg:py-12 gap-24">
          <div className="mx-auto self-center w-full max-w-4xl px-5 md:px-12 lg:px-20 py-20 my-14 rounded-lg shadow-md text-center bg-gradient-fade">
            <h2>All Set!</h2>
            {email && (
              <p className="mt-10 text-xl">
                The welcome email with your Organization ID has been sent to{" "}
                <b>{email}</b>.
              </p>
            )}
            {!email && (
              <p className="mt-10 text-xl">
                The welcome email with your Organization ID has been sent to
                your email.
              </p>
            )}

            <p className="mt-10 text-md">
              If you are having any issues with your account, please reach out
              to us at{" "}
              <a href="mailto:support@symops.com">support@symops.com</a>
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
}
