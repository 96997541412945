import React, { useState, useEffect, useMemo } from "react";

import "./HowItWorksSection.css";
import AnimationContainer from "../../../AnimationContainer/AnimationContainer";

export default function HowItWorksSection() {
  const featuresArray = useMemo(
    () => [
      {
        tabTitle: "Terraform",
        title: "Define your Flows in Terraform",
        desc:
          "Declare your access targets, environments, and user-facing fields in Terraform, and deploy your configuration with the rest of your infrastructure.",
        img: "assets/how-it-works/how-it-works-1.svg",
        bgImg: "assets/how-it-works/how-it-works-bg-terraform.svg",
      },
      {
        tabTitle: "Rules in Code",
        title: "Write your rules in code",
        desc:
          "Sym’s SDK makes it easy to encode the complexities of access management into a simple package using your SDLC.",
        img: "assets/how-it-works/how-it-works-2.svg",
        bgImg: "assets/how-it-works/how-it-works-bg-code.svg",
      },
      {
        tabTitle: "Slack Approvals",
        title: "Request approvals in Slack",
        desc:
          "Once your flows are configured, anyone on your team can run `/sym` to kick off an approval or access flow in your Slack workspace.",
        img: "assets/how-it-works/how-it-works-3.svg",
        bgImg: "assets/how-it-works/how-it-works-bg-slack.svg",
      },
      {
        tabTitle: "Audit Logs",
        title: "Collect and view audit logs anywhere",
        desc:
          "Sym can push event logs to nearly any destination, so every point of access is documented and compliant.",
        img: "assets/how-it-works/how-it-works-4.svg",
        bgImg: "assets/how-it-works/how-it-works-bg-logs.svg",
      },
    ],
    []
  );

  const [featureIndex, setFeatureIndex] = useState(0);
  const [activeFeature, setActiveFeature] = useState(featuresArray[0]);

  useEffect(() => {
    setActiveFeature(featuresArray[featureIndex]);
  }, [featureIndex, featuresArray]);

  return (
    <AnimationContainer animationClass="home-how-it-works-section-animation">
      <section className="w-full justify-center flex">
        <div className="w-full md:max-w-7xl flex flex-col">
          <div className="flex flex-col w-full px-4">
            <div className="mb-16 text-center">
              <h2 className="mb-6">Reduce friction. Unblock your engineers.</h2>
              <p className="w-full max-w-3xl mx-auto">
                Sym's SDK helps platform, security and infrastructure teams
                build authorization flows with the tools that they love. All
                with centralized governance using your organization's SDLC.
              </p>
            </div>
            <div className="w-full flex flex-col rounded-xl overflow-hidden how-it-works-wrapper">
              <div className="bg-sym-darkBlue rounded-xl">
                <div className="w-full flex flex-row items-center justify-between px-4 relative rounded-t-xl border-b border-sym-purple how-it-works-top-bar">
                  <div className="flex flex-row gap-2 items-center">
                    <div className="w-3 h-3 block rounded-full bg-sym-dark"></div>
                    <div className="w-3 h-3 block rounded-full bg-sym-dark"></div>
                    <div className="w-3 h-3 block rounded-full bg-sym-dark"></div>
                  </div>
                  <div className="hidden lg:flex flex-row gap-2 absolute bottom-0 right-16">
                    {featuresArray.map((step, i) => {
                      return (
                        <div
                          onClick={() => {
                            setFeatureIndex(i);
                          }}
                          className={`inline-flex flex-row align-center px-8 py-2 text-sm font-bold uppercase bg-sym-dark rounded-t-lg cursor-pointer how-it-works-tab ${
                            featureIndex === i ? "active text-sym-purple" : ""
                          }`}
                          key={i}
                        >
                          <span className="inline-block">{step.tabTitle}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="w-full flex flex-col lg:flex-row items-center justify-between p-8 lg:p-16 bg-sym-dark rounded-b-xl">
                  <div className="grid grid-cols-2 gap-4 mb-8 lg:hidden">
                    {featuresArray.map((step, i) => {
                      return (
                        <div
                          onClick={() => {
                            setFeatureIndex(i);
                          }}
                          className={`inline-flex flex-row items-center justify-center px-8 py-4 text-sm font-bold uppercase text-center rounded-lg cursor-pointer how-it-works-tab ${
                            featureIndex === i
                              ? "active bg-sym-dark text-sym-purple"
                              : "bg-sym-darkLightBlue"
                          }`}
                          key={i}
                        >
                          {step.tabTitle}
                        </div>
                      );
                    })}
                  </div>
                  <div className="w-100 max-w-xl relative">
                    <div
                      className="flex flex-col justify-center pr-0 lg:pr-12 mb-8 lg:mb-0 text-center lg:text-left how-it-works-bg-img"
                      style={{ backgroundImage: `url(${activeFeature.bgImg})` }}
                    >
                      <h3 className="mb-4">{activeFeature.title}</h3>
                      <div>{activeFeature.desc}</div>
                    </div>
                  </div>
                  <div className="inline-block w-auto h-64">
                    <img
                      className="w-auto h-full block"
                      src={activeFeature.img}
                      alt={activeFeature.title}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AnimationContainer>
  );
}
