import React from "react";
import "../../sections.css";
import "./team.css";

import AnimationContainer from "../../../AnimationContainer/AnimationContainer";

//angel/advisor images
import jonBassSrc from "../images/JonBass.jpeg";
import nickMeansSrc from "../images/NickMeans.jpeg";
import sandyKwonSrc from "../images/SandyKwon.jpeg";

import linkedinImgSrc from "../images/linkedin.svg";

const team = [
  {
    name: "Jon Bass",
    job: "Co-Founder & CEO",
    imgSrc: jonBassSrc,
    linkedinHref: "https://www.linkedin.com/in/jonbass/",
  },
  {
    name: "Nick Means",
    job: "VP Engineering",
    imgSrc: nickMeansSrc,
    linkedinHref: "https://www.linkedin.com/in/nickolasmeans/",
  },
  {
    name: "Sandy Kwon",
    job: "VP Operations",
    imgSrc: sandyKwonSrc,
    linkedinHref: "https://www.linkedin.com/in/sandykwon/",
  },
];

const TeamProfile = ({ profile }) => {
  const { name, job, imgSrc, linkedinHref } = profile;
  return (
    <div className="w-full max-w-xs lg:max-w-none m-auto">
      <img alt={name} src={imgSrc} className="w-full" />
      <div className="flex flex-row justify-between items-start mt-4">
        <div>
          <h3 className="text-xl">{name}</h3>
          <p className="text-sym-blue">{job}</p>
        </div>
        <a
          href={linkedinHref}
          className=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="linkedin"
            src={linkedinImgSrc}
            style={{ width: "100%" }}
            className="filter invert opacity-50"
          />
        </a>
      </div>
    </div>
  );
};

export default function MeetOurTeam() {
  return (
    <AnimationContainer animationClass="team-animation">
      <div className="flex flex-col">
        <div className="w-full mb-12 text-center">
          <h2 className="inline-block mx-auto">Leadership</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 sm:gap-9">
          {team.map((profile) => (
            <TeamProfile key={profile.name} profile={profile} />
          ))}
        </div>
      </div>
    </AnimationContainer>
  );
}
