import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations as SentryIntegrations } from "@sentry/tracing";
import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import ScrollToTop from "./ScrollToTop";
import UtmTracker from "./UtmTracker";

import "./index.css";

import { asyncWithLDProvider, useFlags } from "launchdarkly-react-client-sdk";
import Home from "./pages/Home.js";
import About from "./pages/About";
import Authorization from "./pages/Authorization/Authorization.js";
import AuthorizationSuccess from "./pages/Authorization/AuthorizationSuccess.js";
import Compliance from "./pages/Compliance";
import UseCase from "./pages/UseCases/UseCase";
import UseCases from "./pages/UseCases/UseCases";
import Feature from "./pages/Features/Feature";
import Product from "./pages/Product/Product";
import IntegrationLanding from "./pages/Integrations/IntegrationLanding";
import Integration from "./pages/Integrations/Integration";
import Security from "./pages/Security";
import SecurityDisclosure from "./pages/Legal/Disclosure/SecurityDisclosure";
import Sales from "./pages/Sales/Sales";
import Privacy from "./pages/Legal/Privacy/PrivacyPolicy";
import ToS from "./pages/Legal/ToS/ToS";
import Subprocessors from "./pages/Legal/Subprocessors/Subprocessors";
import SelfServicePricing from "./pages/SelfService/SelfServicePricing";
import SelfServiceSignUpForm from "./pages/SelfService/SelfServiceSignUpForm";
import SelfServiceSignUpEmailVerified from "./pages/SelfService/SelfServiceSignUpEmailVerified";
import SelfServiceSignUpSuccess from "./pages/SelfService/SelfServiceSignUpSuccess";
import SelfServiceSignUpError from "./pages/SelfService/SelfServiceSignUpError";
import SelfServiceResendWelcomeSuccess from "./pages/SelfService/SelfServiceResendWelcomeSuccess";
import JITAccessWhitepaper from "./pages/Whitepapers/JITAccessWhitepaper";
import Customers from "./pages/Customers/Customers";
import Webinar from "./pages/Webinar/Webinar";

function App() {
  useEffect(() => {}, []);

  const freeTrialFlag = useFlags().freeTrialLength;
  const headline = "Sym: Secure Your Production Infrastructure";
  const subhead =
    "Implement just-in-time access without slowing down. Sym's SDKs and intuitive Slack UI give you enterprise-grade security in hours, not weeks.";

  return (
    <Router>
      <Helmet>
        <title>Sym: Secure Your Production Infrastructure</title>
        <link rel="canonical" href="https://symops.com/" />
        <meta content={subhead} name="description" />
        <meta content={headline} property="og:title" />
        <meta content={subhead} property="og:description" />
        <meta
          content="https://assets.symops.com/www/code-new.png"
          property="og:image"
        />
        <meta content={headline} property="twitter:title" />
        <meta content={subhead} property="twitter:description" />
        <meta
          content="https://assets.symops.com/www/code-new.png"
          property="twitter:image"
        />
        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta name="twitter:card" value="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://assets.symops.com/www/code-new.png"
        />
        {/* <meta
          name="twitter:player"
          value="https://assets.symops.com/social/player.html?1"
        /> */}
      </Helmet>
      <div className={`w-full`}>
        {/* forces scroll to top on each internal page transition */}
        {/* https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition */}
        <ScrollToTop />
        {/* custom utm tracker to store in localStorage, used upon submitting Mailchimp forms */}
        <UtmTracker />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/sales">
            <Sales />
          </Route>
          <Route path="/demo">
            <Redirect to="/sales" />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/use-cases/compliance">
            <Compliance />
          </Route>
          <Route path="/use-cases/privileged-access">
            <Redirect to="/product/reduce-access" />
          </Route>
          <Route path="/use-cases/day-to-day-compliance">
            <Redirect to="/product/compliance" />
          </Route>
          <Route path="/use-cases/:id">
            <UseCase />
          </Route>
          <Route path="/use-cases">
            <UseCases />
          </Route>
          <Route path="/features/integrations">
            <Redirect to="/integrations" />
          </Route>
          <Route path="/features/:id">
            <Feature />
          </Route>
          <Route path="/product/aws">
            <Redirect to="/integrations/aws-sso" />
          </Route>
          <Route path="/product/:id">
            <Product />
          </Route>
          <Route path="/whitepapers/jit-access-guide">
            <JITAccessWhitepaper />
          </Route>
          <Route path="/integrations/:id">
            <Integration />
          </Route>
          <Route path="/integrations">
            <IntegrationLanding />
          </Route>
          <Route path="/authorization/success/:integration">
            <AuthorizationSuccess />
          </Route>
          <Route path="/authorization/:integration">
            <Authorization />
          </Route>
          <Route path="/why">
            <Redirect to="/features/extensible-platform" />
          </Route>
          <Route path="/security/disclosure">
            <SecurityDisclosure />
          </Route>
          <Route path="/security">
            <Security />
          </Route>
          <Route path="/terms">
            <ToS />
          </Route>
          <Route path="/TermsOfService">
            <Redirect to="/terms" />
          </Route>
          <Route path="/subprocessors">
            <Subprocessors />
          </Route>
          <Route
            path="/blog"
            component={() => {
              window.location.href = "https://blog.symops.com";
              return null;
            }}
          />
          <Route path="/pricing">
            <SelfServicePricing freeTrialFlag={freeTrialFlag} />
          </Route>
          <Route path="/signup/resent-welcome">
            <SelfServiceResendWelcomeSuccess />
          </Route>
          <Route path="/signup/email-verified">
            <SelfServiceSignUpEmailVerified />
          </Route>
          <Route path="/signup/success">
            <SelfServiceSignUpSuccess />
          </Route>
          <Route path="/signup/error">
            <SelfServiceSignUpError />
          </Route>
          <Route path="/signup/:plan/success">
            <SelfServiceSignUpSuccess />
          </Route>
          <Route path="/signup/:plan">
            <SelfServiceSignUpForm freeTrialFlag={freeTrialFlag} />
          </Route>
          <Route path="/signup">
            <Redirect to="/" />
          </Route>
          <Route path="/customers">
            <Customers />
          </Route>
          <Route path="/webinar">
            <Webinar />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

// only run sentry and logrocket on a deployed instance
if (process.env.NODE_ENV !== "development") {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new SentryIntegrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }

  if (process.env.REACT_APP_LOG_ROCKET_APP_ID) {
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);
    setupLogRocketReact(LogRocket);
  }

  if (
    process.env.REACT_APP_SENTRY_DSN &&
    process.env.REACT_APP_LOG_ROCKET_APP_ID
  ) {
    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra("sessionURL", sessionURL);
      });
    });
  }
}

(async () => {
  // ensures that flags and LaunchDarkly client are ready at start of app lifecycle
  const LDProvider = await asyncWithLDProvider({
    clientSideID: "61baa6c942189d14dec41c78",
  });

  ReactDOM.render(
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback="an error has occurred" showDialog>
        <LDProvider>
          <App />
        </LDProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();
