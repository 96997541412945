import React, { forwardRef } from "react";
import { useSwipeable } from "react-swipeable";
import SlickSlider from "react-slick";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick.css";

const Slider = forwardRef((props, ref) => {
  const {
    children,
    onChange,
    onSwipedLeft,
    onSwipedRight,
    centerPaddingPercent,
  } = props;

  const settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: `${centerPaddingPercent || 25}%`,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: `${centerPaddingPercent || 25}%`,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: `${centerPaddingPercent - 10 || 15}%`,
        },
      },
      {
        breakpoint: 640,
        settings: {
          centerPadding: `${centerPaddingPercent - 15 || 10}%`,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: () => {},
    beforeChange: (_, next) => onChange(next),
  };

  const handlers = useSwipeable({
    onSwipedLeft,
    onSwipedRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="w-full" {...handlers}>
      <SlickSlider ref={ref} {...settings}>
        {children}
      </SlickSlider>
    </div>
  );
});

export default Slider;
