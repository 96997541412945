import React, { useState, useEffect, useRef } from "react";
import "./animation-container.css";
import classNames from "classnames";

type SectionContainerOwnProps = {
  animationClass: string;
  animationActiveClass?: string;
};
const SectionContainer: React.FunctionComponent<SectionContainerOwnProps> = ({
  children,
  animationClass,
  animationActiveClass,
}) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef() as any;

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // is isVisible is ever true, it will lock onto the value
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(true);
        }
      });
    });
    observer.observe(domRef.current);
  }, []);

  const classes = ["animation-container"];

  if (isVisible) {
    classes.push("animation-visible");
  }

  if (animationClass) {
    classes.push(animationClass);
    if (isVisible && animationActiveClass) {
      classes.push(animationActiveClass);
    }
  } else {
    classes.push("animation-default");
  }

  return (
    <div className={classNames(...classes)} ref={domRef}>
      {children}
    </div>
  );
};

export default SectionContainer;
