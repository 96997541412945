import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import HeroSection from "../components/HomePage/Sections/HeroSection/HeroSection";
import WhitepaperSection from "../components/HomePage/Sections/WhitepaperSection/WhitepaperSection";
import HowItWorksSection from "../components/HomePage/Sections/HowItWorksSection/HowItWorksSection";
import ProductFeaturesSection from "../components/HomePage/Sections/ProductFeaturesSection/ProductFeaturesSection";
import TestimonialSection from "../components/HomePage/Sections/TestimonialSection/TestimonialSection";
import BottomCta from "../components/HomePage/Sections/BottomCta/BottomCta";

const PAGE_TITLE = "Sym: Secure Your Production Infrastructure";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="mb-8 lg:mb-16">
          <HeroSection />
        </div>
        <div className="main-content-bg">
          <div className="py-12 sm:py-16">
            <TestimonialSection />
          </div>
          <div className="py-12 sm:py-16">
            <ProductFeaturesSection />
          </div>
          <div className="py-12 sm:py-16">
            <WhitepaperSection />
          </div>
          <div className="py-12 sm:py-16">
            <HowItWorksSection />
          </div>

          <div className="main-content-bg-wrapper">
            <div className="main-content-bg-element-1"></div>
            <div className="main-content-bg-element-2"></div>
            <div className="main-content-bg-element-3"></div>
            <div className="main-content-bg-element-4"></div>
          </div>
        </div>
        <BottomCta id="home" />
      </Layout>
    </>
  );
}
