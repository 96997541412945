import React from "react";
import Footer from "./Footer";
import TopNav from "./Nav/TopNav/TopNav";

// Set this to "const topBanner = null" if no banner needed
const topBanner = null;
const topBannerLink = null;

export default function Layout(props) {
  return (
    <div className="w-full">
      <TopNav topBanner={topBanner} topBannerLink={topBannerLink}></TopNav>
      <div
        className={`page-content ${
          topBanner && topBanner.length > 0 ? "page-content-has-top-banner" : ""
        }`}
      >
        {props.children}
        <Footer></Footer>
      </div>
    </div>
  );
}
