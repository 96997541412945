import React from "react";

import "./TestimonialSection.css";
import AnimationContainer from "../../../AnimationContainer/AnimationContainer";
import TestimonialsBlock from "../../../Common/TestimonialsBlock/TestimonialsBlock";

export default function TestimonialSection() {
  return (
    <AnimationContainer animationClass="home-testimonial-section-animation">
      <TestimonialsBlock />
    </AnimationContainer>
  );
}
