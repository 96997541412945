import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../../components/Layout";
import styles from "../Legal.module.css";

const PAGE_TITLE = "Privacy Policy | Sym";

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/privacy" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div
          className={`${styles.legalPage} " flex flex-col w-full justify-center px-4 md:px-8 lg:px-8 xl:px-16 2xl:px-40 relative`}
        >
          <div>
            <div>
              <h1>SymOps Privacy Policy</h1>
              <br />
              <br />
              <p>
                Last updated: February 16, 2021
                <br />
                <br />
                This Privacy Policy describes Our policies and procedures on the
                collection, use and disclosure of Your information when You use
                the Service and tells You about Your privacy rights and how the
                law protects You. We use Your Personal data to provide and
                improve the Service. By using the Service, You agree to the
                collection and use of information in accordance with this
                Privacy Policy.
                <br />
                <br />
              </p>
              <br />
              <h2>Interpretation and Definitions</h2>
              <br />
              <h3>Interpretation</h3>
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>
              <br />
              <h3>Definitions</h3>
              <p>
                For the purposes of this Privacy Policy:
                <br />
                <br />
                <p className={styles.indentedBlock}>
                  <strong>Account</strong> means a unique account created for
                  You to access our Service or parts of our Service.
                  <br />
                  <br />
                  <strong>Affiliate</strong> means an entity that controls, is
                  controlled by or is under common control with a party, where
                  "control" means ownership of 50% or more of the shares, equity
                  interest or other securities entitled to vote for election of
                  directors or other managing authority.
                  <br />
                  <br />
                  <strong>Application</strong> means the software program
                  provided by the Company downloaded by You on any electronic
                  device, named Sym
                  <br />
                  <br />
                  <strong>Business</strong>, for the purpose of the CCPA
                  (California Consumer Privacy Act), refers to the Company as
                  the legal entity that collects Consumers' personal information
                  and determines the purposes and means of the processing of
                  Consumers' personal information, or on behalf of which such
                  information is collected and that alone, or jointly with
                  others, determines the purposes and means of the processing of
                  consumers' personal information, that does business in the
                  State of California.
                  <br />
                  <br />
                  <strong>Company</strong> (referred to as either "the Company",
                  "We", "Us" or "Our" in this Agreement) refers to SymOps, c/o
                  Gunderson Dettmer, One Marina Park Drive (Suite 900) Boston,
                  MA 02210.
                  <br />
                  <br />
                  For the purpose of the GDPR, the Company is the Data
                  Controller.
                  <br />
                  <br />
                  <strong>Consumer</strong>, for the purpose of the CCPA
                  (California Consumer Privacy Act), means a natural person who
                  is a California resident. A resident, as defined in the law,
                  includes (1) every individual who is in the USA for other than
                  a temporary or transitory purpose, and (2) every individual
                  who is domiciled in the USA who is outside the USA for a
                  temporary or transitory purpose.
                  <br />
                  <br />
                  <strong>Country</strong> refers to: Massachusetts, United
                  States
                  <br />
                  <br />
                  <strong>Data Controller</strong>, for the purposes of the GDPR
                  (General Data Protection Regulation), refers to the Company as
                  the legal person which alone or jointly with others determines
                  the purposes and means of the processing of Personal Data.
                  <br />
                  <br />
                  <strong>Device</strong> means any device that can access the
                  Service such as a computer, a cellphone or a digital tablet.
                  <br />
                  <br />
                  <strong>Do Not Track</strong> (DNT) is a concept that has been
                  promoted by US regulatory authorities, in particular the U.S.
                  Federal Trade Commission (FTC), for the Internet industry to
                  develop and implement a mechanism for allowing internet users
                  to control the tracking of their online activities across
                  websites.
                  <br />
                  <br />
                  <strong>Personal Data</strong> is any information that relates
                  to an identified or identifiable individual.
                  <br />
                  <br />
                  For the purposes for GDPR, Personal Data means any information
                  relating to You such as a name, an identification number,
                  location data, online identifier or to one or more factors
                  specific to the physical, physiological, genetic, mental,
                  economic, cultural or social identity.
                  <br />
                  <br />
                  For the purposes of the CCPA, Personal Data means any
                  information that identifies, relates to, describes or is
                  capable of being associated with, or could reasonably be
                  linked, directly or indirectly, with You.
                  <br />
                  <br />
                  <strong>Sale</strong>, for the purpose of the CCPA (California
                  Consumer Privacy Act), means selling, renting, releasing,
                  disclosing, disseminating, making available, transferring, or
                  otherwise communicating orally, in writing, or by electronic
                  or other means, a Consumer's personal information to another
                  business or a third party for monetary or other valuable
                  consideration.
                  <br />
                  <br />
                  <strong>Service</strong> refers to the Application.
                  <br />
                  <br />
                  <strong>Service Provider</strong> means any natural or legal
                  person who processes the data on behalf of the Company. It
                  refers to third-party companies or individuals employed by the
                  Company to facilitate the Service, to provide the Service on
                  behalf of the Company, to perform services related to the
                  Service or to assist the Company in analyzing how the Service
                  is used. For the purpose of the GDPR, Service Providers are
                  considered Data Processors.
                  <br />
                  <br />
                  <strong>Third-party Social Media Service</strong> refers to
                  any website or any social network website through which a User
                  can log in or create an account to use the Service.
                  <br />
                  <br />
                  <strong>Usage Data</strong> refers to data collected
                  automatically, either generated by the use of the Service or
                  from the Service infrastructure itself (for example, the
                  duration of a page visit).
                  <br />
                  <br />
                  <strong>You</strong> means the individual accessing or using
                  the Service, or the company, or other legal entity on behalf
                  of which such individual is accessing or using the Service, as
                  applicable.
                  <br />
                  <br />
                  Under GDPR (General Data Protection Regulation), You can be
                  referred to as the Data Subject or as the User as you are the
                  individual using the Service.
                </p>
              </p>
              <br />
              <br />
              <h2>Collecting and Using Your Personal Data</h2>
              <br />
              <h3>Types of Data Collected</h3>
              <br />
              <h4>Personal Data</h4>
              <p>
                While using Our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may
                include, but is not limited to:
                <br />
                <br />
                <p className={styles.indentedBlock}>
                  Email address
                  <br />
                  <br />
                  First name and last name
                  <br />
                  <br />
                  Phone number
                  <br />
                  <br />
                  Address, State, Province, ZIP/Postal code, City
                  <br />
                  <br />
                  Usage Data
                </p>
              </p>
              <br />
              <h4>Usage Data</h4>
              <p>
                Usage Data is collected automatically when using the Service.
                <br />
                <br />
                Usage Data may include information such as Your Device's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that You visit, the
                time and date of Your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
                <br />
                <br />
                When You access the Service by or through a mobile device, We
                may collect certain information automatically, including, but
                not limited to, the type of mobile device You use, Your mobile
                device unique ID, the IP address of Your mobile device, Your
                mobile operating system, the type of mobile Internet browser You
                use, unique device identifiers and other diagnostic data.
                <br />
                <br />
                We may also collect information that Your browser sends whenever
                You visit our Service or when You access the Service by or
                through a mobile device.
              </p>
              <br />
              <h3>Use of Your Personal Data</h3>
              <br />
              <p>
                The Company may use Personal Data for the following purposes:
                <br />
                <br />
                <p className={styles.indentedBlock}>
                  <strong>To provide and maintain our Service</strong>,
                  including to monitor the usage of our Service.
                  <br />
                  <br />
                  <strong>To manage Your Account:</strong> to manage Your
                  registration as a user of the Service. The Personal Data You
                  provide can give You access to different functionalities of
                  the Service that are available to You as a registered user.
                  <br />
                  <br />
                  <strong>For the performance of a contract:</strong> the
                  development, compliance and undertaking of the purchase
                  contract for the products, items or services You have
                  purchased or of any other contract with Us through the
                  Service.
                  <br />
                  <br />
                  <strong>To contact You:</strong> To contact You by email,
                  telephone calls, SMS, or other equivalent forms of electronic
                  communication, such as a mobile application's push
                  notifications regarding updates or informative communications
                  related to the functionalities, products or contracted
                  services, including the security updates, when necessary or
                  reasonable for their implementation.
                  <br />
                  <br />
                  <strong>To provide You</strong> with news, special offers and
                  general information about other goods, services and events
                  which we offer that are similar to those that you have already
                  purchased or enquired about unless You have opted not to
                  receive such information.
                  <br />
                  <br />
                  <strong>To manage Your requests:</strong> To attend and manage
                  Your requests to Us.
                  <br />
                  <br />
                  <strong>For business transfers:</strong> We may use Your
                  information to evaluate or conduct a merger, divestiture,
                  restructuring, reorganization, dissolution, or other sale or
                  transfer of some or all of Our assets, whether as a going
                  concern or as part of bankruptcy, liquidation, or similar
                  proceeding, in which Personal Data held by Us about our
                  Service users is among the assets transferred.
                  <br />
                  <br />
                  <strong>For other purposes:</strong> We may use Your
                  information for other purposes, such as data analysis,
                  identifying usage trends, determining the effectiveness of our
                  promotional campaigns and to evaluate and improve our Service,
                  products, services, marketing and your experience.
                  <br />
                  <br />
                </p>
              </p>
              <p>
                We may share Your personal information in the following
                situations:
                <br />
                <br />
                <ul
                // className="list-disc px-8"
                >
                  <li>
                    With Service Providers: We may share Your personal
                    information with Service Providers to monitor and analyze
                    the use of our Service, to contact You.
                  </li>
                  <li>
                    For business transfers: We may share or transfer Your
                    personal information in connection with, or during
                    negotiations of, any merger, sale of Company assets,
                    financing, or acquisition of all or a portion of Our
                    business to another company.
                  </li>
                  <li>
                    With Affiliates: We may share Your information with Our
                    affiliates, in which case we will require those affiliates
                    to honor this Privacy Policy. Affiliates include Our parent
                    company and any other subsidiaries, joint venture partners
                    or other companies that We control or that are under common
                    control with Us.
                  </li>
                  <li>
                    With business partners: We may share Your information with
                    Our business partners to offer You certain products,
                    services or promotions.
                  </li>
                  <li>
                    With other users: when You share personal information or
                    otherwise interact in the public areas with other users,
                    such information may be viewed by all users and may be
                    publicly distributed outside. If You interact with other
                    users or register through a Third-Party Social Media
                    Service, Your contacts on the Third-Party Social Media
                    Service may see Your name, profile, pictures and description
                    of Your activity. Similarly, other users will be able to
                    view descriptions of Your activity, communicate with You and
                    view Your profile.
                  </li>
                  <li>
                    With Your consent: We may disclose Your personal information
                    for any other purpose with Your consent.
                  </li>
                </ul>
              </p>
              <br />
              <h3>Retention of Your Personal Data</h3>
              <br />
              <p>
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
                <br />
                <br />
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of Our Service, or We
                are legally obligated to retain this data for longer time
                periods.
              </p>
              <br />
              <h3>Transfer of Your Personal Data</h3>
              <br />
              <p>
                Your information, including Personal Data, is processed at the
                Company's operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction.
                <br />
                <br />
                Your consent to this Privacy Policy followed by Your submission
                of such information represents Your agreement to that transfer.
                <br />
                <br />
                The Company will take all steps reasonably necessary to ensure
                that Your data is treated securely and in accordance with this
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information.
              </p>
              <br />
              <h3>Disclosure of Your Personal Data</h3>
              <br />
              <h4>Business Transactions</h4>
              <p>
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </p>
              <br />
              <h4>Law enforcement</h4>
              <p>
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
              </p>
              <br />
              <h4>Other legal requirements</h4>
              <p>
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
                <br />
                <ul>
                  <li>Comply with a legal obligation</li>
                  <li>
                    Protect and defend the rights or property of the Company
                  </li>
                  <li>
                    Prevent or investigate possible wrongdoing in connection
                    with the Service
                  </li>
                  <li>
                    Protect the personal safety of Users of the Service or the
                    public
                  </li>
                  <li>Protect against legal liability</li>
                </ul>
              </p>
              <br />
              <h3>Security of Your Personal Data</h3>
              <br />
              <p>
                The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to
                use commercially acceptable means to protect Your Personal Data,
                We cannot guarantee its absolute security.
              </p>
              <br />
              <br />
              <h2>
                Detailed Information on the Processing of Your Personal Data
              </h2>
              <br />
              <p>
                The Service Providers We use may have access to Your Personal
                Data. These third-party vendors collect, store, use, process and
                transfer information about Your activity on Our Service in
                accordance with their Privacy Policies.
              </p>
              <br />
              <h3>Analytics</h3>
              <br />
              <p>
                We may use third-party Service providers to monitor and analyze
                the use of our Service.
                <br />
                <br />
                <p className={styles.indentedBlock}>
                  <strong>Google Analytics</strong>
                  <br />
                  <br />
                  Google Analytics is a web analytics service offered by Google
                  that tracks and reports website traffic. Google uses the data
                  collected to track and monitor the use of our Service. This
                  data is shared with other Google services. Google may use the
                  collected data to contextualize and personalize the ads of its
                  own advertising network.
                  <br />
                  <br />
                  You may opt-out of certain Google Analytics features through
                  your mobile device settings, such as your device advertising
                  settings or by following the instructions provided by Google
                  in their Privacy Policy: https://policies.google.com/privacy
                  <br />
                  <br />
                  For more information on the privacy practices of Google,
                  please visit the Google Privacy & Terms web page:
                  https://policies.google.com/privacy
                </p>
              </p>
              <br />
              <h3>Email Marketing</h3>
              <br />
              <p>
                We may use Your Personal Data to contact You with newsletters,
                marketing or promotional materials and other information that
                may be of interest to You. You may opt-out of receiving any, or
                all, of these communications from Us by following the
                unsubscribe link or instructions provided in any email We send
                or by contacting Us.
                <br />
                <br />
                We may use Email Marketing Service Providers to manage and send
                emails to You.
                <br />
                <br />
                <p className={styles.indentedBlock}>
                  <strong>Mailchimp</strong>
                  <br />
                  <br />
                  Mailchimp is an email marketing sending service provided by
                  The Rocket Science Group LLC.
                  <br />
                  <br />
                  For more information on the privacy practices of Mailchimp,
                  please visit their Privacy policy:
                  https://mailchimp.com/legal/privacy/
                </p>
              </p>
              <br />
              <br />
              <h2>GDPR Privacy</h2>
              <br />
              <h3>Legal Basis for Processing Personal Data under GDPR</h3>
              <br />
              <p>
                We may process Personal Data under the following conditions:
                <br />
                <ul>
                  <li>
                    <strong>Consent:</strong> You have given Your consent for
                    processing Personal Data for one or more specific purposes.
                  </li>
                  <li>
                    <strong>Performance of a contract:</strong> Provision of
                    Personal Data is necessary for the performance of an
                    agreement with You and/or for any pre-contractual
                    obligations thereof.
                  </li>
                  <li>
                    <strong>Legal obligations:</strong> Processing Personal Data
                    is necessary for compliance with a legal obligation to which
                    the Company is subject.
                  </li>
                  <li>
                    <strong>Vital interests:</strong> Processing Personal Data
                    is necessary in order to protect Your vital interests or of
                    another natural person.
                  </li>
                  <li>
                    <strong>Public interests:</strong> Processing Personal Data
                    is related to a task that is carried out in the public
                    interest or in the exercise of official authority vested in
                    the Company.
                  </li>
                  <li>
                    <strong>Legitimate interests:</strong> Processing Personal
                    Data is necessary for the purposes of the legitimate
                    interests pursued by the Company.
                  </li>
                </ul>
                In any case, the Company will gladly help to clarify the
                specific legal basis that applies to the processing, and in
                particular whether the provision of Personal Data is a statutory
                or contractual requirement, or a requirement necessary to enter
                into a contract.
              </p>
              <br />
              <h3>Your Rights under the GDPR</h3>
              <br />
              <p>
                The Company undertakes to respect the confidentiality of Your
                Personal Data and to guarantee You can exercise Your rights.
                <br />
                <br />
                You have the right under this Privacy Policy, and by law if You
                are within the EU, to:
                <ul>
                  <li>
                    <strong>Request access to Your Personal Data.</strong> The
                    right to access, update or delete the information We have on
                    You. Whenever made possible, you can access, update or
                    request deletion of Your Personal Data directly within Your
                    account settings section. If you are unable to perform these
                    actions yourself, please contact Us to assist You. This also
                    enables You to receive a copy of the Personal Data We hold
                    about You.
                  </li>
                  <li>
                    <strong>
                      Request correction of the Personal Data that We hold about
                      You.
                    </strong>{" "}
                    You have the right to to have any incomplete or inaccurate
                    information We hold about You corrected.
                  </li>
                  <li>
                    <strong>Object to processing of Your Personal Data.</strong>{" "}
                    This right exists where We are relying on a legitimate
                    interest as the legal basis for Our processing and there is
                    something about Your particular situation, which makes You
                    want to object to our processing of Your Personal Data on
                    this ground. You also have the right to object where We are
                    processing Your Personal Data for direct marketing purposes.
                  </li>
                  <li>
                    <strong>Request erasure of Your Personal Data.</strong> You
                    have the right to ask Us to delete or remove Personal Data
                    when there is no good reason for Us to continue processing
                    it.
                  </li>
                  <li>
                    <strong>Request the transfer of Your Personal Data.</strong>{" "}
                    We will provide to You, or to a third-party You have chosen,
                    Your Personal Data in a structured, commonly used,
                    machine-readable format. Please note that this right only
                    applies to automated information which You initially
                    provided consent for Us to use or where We used the
                    information to perform a contract with You.
                  </li>
                  <li>
                    <strong>Withdraw Your consent.</strong> You have the right
                    to withdraw Your consent on using your Personal Data. If You
                    withdraw Your consent, We may not be able to provide You
                    with access to certain specific functionalities of the
                    Service.
                  </li>
                </ul>
              </p>
              <br />
              <h3>Exercising of Your GDPR Data Protection Rights</h3>
              <br />
              <p>
                You may exercise Your rights of access, rectification,
                cancellation and opposition by contacting Us. Please note that
                we may ask You to verify Your identity before responding to such
                requests. If You make a request, We will try our best to respond
                to You as soon as possible.
                <br />
                <br />
                You have the right to complain to a Data Protection Authority
                about Our collection and use of Your Personal Data. For more
                information, if You are in the European Economic Area (EEA),
                please contact Your local data protection authority in the EEA.
              </p>
              <br />
              <br />
              <h2>CCPA Privacy</h2>
              <br />
              <p>
                This privacy notice section for California residents supplements
                the information contained in Our Privacy Policy and it applies
                solely to all visitors, users, and others who reside in the
                State of California.
              </p>
              <br />
              <h3>Categories of Personal Information Collected</h3>
              <br />
              <p>
                We collect information that identifies, relates to, describes,
                references, is capable of being associated with, or could
                reasonably be linked, directly or indirectly, with a particular
                Consumer or Device. The following is a list of categories of
                personal information which we may collect or may have been
                collected from California residents within the last twelve (12)
                months.
                <br />
                <br />
                Please note that the categories and examples provided in the
                list below are those defined in the CCPA. This does not mean
                that all examples of that category of personal information were
                in fact collected by Us, but reflects our good faith belief to
                the best of our knowledge that some of that information from the
                applicable category may be and may have been collected. For
                example, certain categories of personal information would only
                be collected if You provided such personal information directly
                to Us.
                <br />
                <br />
                <p className={styles.indentedBlock}>
                  <strong>Category A: Identifiers.</strong>
                  <br />
                  <br />
                  Examples: A real name, alias, postal address, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, account name, driver's license number, passport
                  number, or other similar identifiers.
                  <br />
                  <br />
                  Collected: Yes.
                  <br />
                  <br />
                  <strong>
                    Category B: Personal information categories listed in the
                    California Customer Records statute (Cal. Civ. Code §
                    1798.80(e)).
                  </strong>
                  <br />
                  <br />
                  Examples: A name, signature, Social Security number, physical
                  characteristics or description, address, telephone number,
                  passport number, driver's license or state identification card
                  number, insurance policy number, education, employment,
                  employment history, bank account number, credit card number,
                  debit card number, or any other financial information, medical
                  information, or health insurance information. Some personal
                  information included in this category may overlap with other
                  categories.
                  <br />
                  <br />
                  Collected: Yes.
                  <br />
                  <br />
                  <strong>
                    Category C: Protected classification characteristics under
                    California or federal law.
                  </strong>
                  <br />
                  <br />
                  Examples: Age (40 years or older), race, color, ancestry,
                  national origin, citizenship, religion or creed, marital
                  status, medical condition, physical or mental disability, sex
                  (including gender, gender identity, gender expression,
                  pregnancy or childbirth and related medical conditions),
                  sexual orientation, veteran or military status, genetic
                  information (including familial genetic information).
                  <br />
                  <br />
                  Collected: No.
                  <br />
                  <br />
                  <strong>Category D: Commercial information.</strong>
                  <br />
                  <br />
                  Examples: Records and history of products or services
                  purchased or considered.
                  <br />
                  <br />
                  Collected: No.
                  <br />
                  <br />
                  <strong>Category E: Biometric information.</strong>
                  <br />
                  <br />
                  Examples: Genetic, physiological, behavioral, and biological
                  characteristics, or activity patterns used to extract a
                  template or other identifier or identifying information, such
                  as, fingerprints, faceprints, and voiceprints, iris or retina
                  scans, keystroke, gait, or other physical patterns, and sleep,
                  health, or exercise data.
                  <br />
                  <br />
                  Collected: No.
                  <br />
                  <br />
                  <strong>
                    Category F: Internet or other similar network activity.
                  </strong>
                  <br />
                  <br />
                  Examples: Interaction with our Service or advertisement.
                  <br />
                  <br />
                  Collected: Yes.
                  <br />
                  <br />
                  <strong>Category G: Geolocation data.</strong>
                  <br />
                  <br />
                  Examples: Approximate physical location.
                  <br />
                  <br />
                  Collected: No.
                  <br />
                  <br />
                  <strong>Category H: Sensory data.</strong>
                  <br />
                  <br />
                  Examples: Audio, electronic, visual, thermal, olfactory, or
                  similar information.
                  <br />
                  <br />
                  Collected: No.
                  <br />
                  <br />
                  <strong>
                    Category I: Professional or employment-related information.
                  </strong>
                  <br />
                  <br />
                  Examples: Current or past job history or performance
                  evaluations.
                  <br />
                  <br />
                  Collected: No.
                  <br />
                  <br />
                  <strong>
                    Category J: Non-public education information (per the Family
                    Educational Rights and Privacy Act (20 U.S.C. Section 1232g,
                    34 C.F.R. Part 99)).
                  </strong>
                  <br />
                  <br />
                  Examples: Education records directly related to a student
                  maintained by an educational institution or party acting on
                  its behalf, such as grades, transcripts, class lists, student
                  schedules, student identification codes, student financial
                  information, or student disciplinary records.
                  <br />
                  <br />
                  Collected: No.
                  <br />
                  <br />
                  <strong>
                    Category K: Inferences drawn from other personal
                    information.
                  </strong>
                  <br />
                  <br />
                  Examples: Profile reflecting a person's preferences,
                  characteristics, psychological trends, predispositions,
                  behavior, attitudes, intelligence, abilities, and aptitudes.
                  <br />
                  <br />
                  Collected: No.
                  <br />
                  <br />
                </p>
                Under CCPA, personal information does not include:
                <ul>
                  <li>
                    Publicly available information from government records
                  </li>
                  <li>De-identified or aggregated consumer information</li>
                  <li>Information excluded from the CCPA's scope, such as:</li>
                  <ul>
                    <li>
                      Health or medical information covered by the Health
                      Insurance Portability and Accountability Act of 1996
                      (HIPAA) and the California Confidentiality of Medical
                      Information Act (CMIA) or clinical trial data
                    </li>
                    <li>
                      Personal Information covered by certain sector-specific
                      privacy laws, including the Fair Credit Reporting Act
                      (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California
                      Financial Information Privacy Act (FIPA), and the Driver's
                      Privacy Protection Act of 1994
                    </li>
                  </ul>
                </ul>
              </p>
              <br />
              <h3>Sources of Personal Information</h3>
              <br />
              <p>
                We obtain the categories of personal information listed above
                from the following categories of sources:
                <ul>
                  <li>
                    <strong>Directly from You.</strong> For example, from the
                    forms You complete on our Service, preferences You express
                    or provide through our Service.
                  </li>
                  <li>
                    <strong>Indirectly from You.</strong> For example, from
                    observing Your activity on our Service.
                  </li>
                  <li>
                    <strong>Automatically from You.</strong> For example,
                    through cookies We or our Service Providers set on Your
                    Device as You navigate through our Service.
                  </li>
                  <li>
                    <strong>From Service Providers.</strong> For example,
                    third-party vendors to monitor and analyze the use of our
                    Service, or other third-party vendors that We use to provide
                    the Service to You.
                  </li>
                </ul>
              </p>
              <br />
              <h3>
                Use of Personal Information for Business Purposes or Commercial
                Purposes
              </h3>
              <br />
              <p>
                We may use or disclose personal information We collect for
                "business purposes" or "commercial purposes" (as defined under
                the CCPA), which may include the following examples:
                <ul>
                  <li>
                    To operate our Service and provide You with our Service.
                  </li>
                  <li>
                    To provide You with support and to respond to Your
                    inquiries, including to investigate and address Your
                    concerns and monitor and improve our Service.
                  </li>
                  <li>
                    To fulfill or meet the reason You provided the information.
                    For example, if You share Your contact information to ask a
                    question about our Service, We will use that personal
                    information to respond to Your inquiry.
                  </li>
                  <li>
                    To respond to law enforcement requests and as required by
                    applicable law, court order, or governmental regulations.
                  </li>
                  <li>
                    As described to You when collecting Your personal
                    information or as otherwise set forth in the CCPA.
                  </li>
                  <li>For internal administrative and auditing purposes.</li>
                  <li>
                    To detect security incidents and protect against malicious,
                    deceptive, fraudulent or illegal activity, including, when
                    necessary, to prosecute those responsible for such
                    activities.
                  </li>
                </ul>
                Please note that the examples provided above are illustrative
                and not intended to be exhaustive. For more details on how we
                use this information, please refer to the "Use of Your Personal
                Data" section.
                <br />
                <br />
                If We decide to collect additional categories of personal
                information or use the personal information We collected for
                materially different, unrelated, or incompatible purposes We
                will update this Privacy Policy.
              </p>
              <br />
              <h3>
                Disclosure of Personal Information for Business Purposes or
                Commercial Purposes
              </h3>
              <br />
              <p>
                We may use or disclose and may have used or disclosed in the
                last twelve (12) months the following categories of personal
                information for business or commercial purposes:
                <br />
                <br />
                <p className={styles.indentedBlock}>
                  Category A: Identifiers
                  <br />
                  <br />
                  Category B: Personal information categories listed in the
                  California Customer Records statute (Cal. Civ. Code §
                  1798.80(e))
                  <br />
                  <br />
                  Category F: Internet or other similar network activity
                  <br />
                  <br />
                </p>
                Please note that the categories listed above are those defined
                in the CCPA. This does not mean that all examples of that
                category of personal information were in fact disclosed, but
                reflects our good faith belief to the best of our knowledge that
                some of that information from the applicable category may be and
                may have been disclosed.
                <br />
                <br />
                When We disclose personal information for a business purpose or
                a commercial purpose, We enter a contract that describes the
                purpose and requires the recipient to both keep that personal
                information confidential and not use it for any purpose except
                performing the contract.
              </p>
              <br />
              <h3>Share of Personal Information</h3>
              <br />
              <p>
                We may share Your personal information identified in the above
                categories with the following categories of third parties:
                <br />
                <br />
                <p className={styles.indentedBlock}>
                  Service Providers
                  <br />
                  <br />
                  Our affiliates
                  <br />
                  <br />
                  Our business partners
                  <br />
                  <br />
                  Third party vendors to whom You or Your agents authorize Us to
                  disclose Your personal information in connection with products
                  or services We provide to You
                </p>
              </p>
              <br />
              <h3>Your Rights under the CCPA</h3>
              <br />
              <p>
                The CCPA provides California residents with specific rights
                regarding their personal information. If You are a resident of
                California, You have the following rights:
                <ul>
                  <li>
                    <strong>The right to notice.</strong> You have the right to
                    be notified which categories of Personal Data are being
                    collected and the purposes for which the Personal Data is
                    being used.
                  </li>
                  <li>
                    <strong>The right to request.</strong> Under CCPA, You have
                    the right to request that We disclose information to You
                    about Our collection, use, sale, disclosure for business
                    purposes and share of personal information. Once We receive
                    and confirm Your request, We will disclose to You:
                  </li>
                  <ul>
                    <li>
                      The categories of personal information We collected about
                      You
                    </li>
                    <li>
                      The categories of sources for the personal information We
                      collected about You
                    </li>
                    <li>
                      Our business or commercial purpose for collecting or
                      selling that personal information
                    </li>
                    <li>
                      The categories of third parties with whom We share that
                      personal information
                    </li>
                    <li>
                      The specific pieces of personal information We collected
                      about You
                    </li>
                    <li>
                      If we sold Your personal information or disclosed Your
                      personal information for a business purpose, We will
                      disclose to You:
                    </li>
                    <ul>
                      <li>
                        The categories of personal information categories sold
                      </li>
                      <li>
                        The categories of personal information categories
                        disclosed
                      </li>
                    </ul>
                  </ul>
                  <li>
                    <strong>
                      The right to say no to the sale of Personal Data
                      (opt-out).
                    </strong>{" "}
                    You have the right to direct Us to not sell Your personal
                    information. To submit an opt-out request please contact Us.
                  </li>
                  <li>
                    <strong>The right to delete Personal Data.</strong> You have
                    the right to request the deletion of Your Personal Data,
                    subject to certain exceptions. Once We receive and confirm
                    Your request, We will delete (and direct Our Service
                    Providers to delete) Your personal information from our
                    records, unless an exception applies. We may deny Your
                    deletion request if retaining the information is necessary
                    for Us or Our Service Providers to:
                  </li>
                  <ul>
                    <li>
                      Complete the transaction for which We collected the
                      personal information, provide a good or service that You
                      requested, take actions reasonably anticipated within the
                      context of our ongoing business relationship with You, or
                      otherwise perform our contract with You.
                    </li>
                    <li>
                      Detect security incidents, protect against malicious,
                      deceptive, fraudulent, or illegal activity, or prosecute
                      those responsible for such activities.
                    </li>
                    <li>
                      Debug products to identify and repair errors that impair
                      existing intended functionality.
                    </li>
                    <li>
                      Exercise free speech, ensure the right of another consumer
                      to exercise their free speech rights, or exercise another
                      right provided for by law.
                    </li>
                    <li>
                      Comply with the California Electronic Communications
                      Privacy Act (Cal. Penal Code § 1546 et. seq.).
                    </li>
                    <li>
                      Engage in public or peer-reviewed scientific, historical,
                      or statistical research in the public interest that
                      adheres to all other applicable ethics and privacy laws,
                      when the information's deletion may likely render
                      impossible or seriously impair the research's achievement,
                      if You previously provided informed consent.
                    </li>
                    <li>
                      Enable solely internal uses that are reasonably aligned
                      with consumer expectations based on Your relationship with
                      Us.
                    </li>
                    <li>Comply with a legal obligation.</li>
                    <li>
                      Make other internal and lawful uses of that information
                      that are compatible with the context in which You provided
                      it.
                    </li>
                  </ul>
                  <li>
                    <strong>The right not to be discriminated against.</strong>{" "}
                    You have the right not to be discriminated against for
                    exercising any of Your consumer's rights, including by:
                  </li>
                  <ul>
                    <li>Denying goods or services to You</li>
                    <li>
                      Charging different prices or rates for goods or services,
                      including the use of discounts or other benefits or
                      imposing penalties
                    </li>
                    <li>
                      Providing a different level or quality of goods or
                      services to You
                    </li>
                    <li>
                      Suggesting that You will receive a different price or rate
                      for goods or services or a different level or quality of
                      goods or services
                    </li>
                  </ul>
                </ul>
              </p>
              <br />
              <h3>Exercising Your CCPA Data Protection Rights</h3>
              <br />
              <p>
                In order to exercise any of Your rights under the CCPA, and if
                You are a California resident, You can contact Us:
                <ul>
                  <li>By email: privacy@symops.io</li>
                </ul>
                Only You, or a person registered with the California Secretary
                of State that You authorize to act on Your behalf, may make a
                verifiable request related to Your personal information.
                <br />
                <br />
                Your request to Us must:
                <br />
                <br />
                <ul>
                  <li>
                    Provide sufficient information that allows Us to reasonably
                    verify You are the person about whom We collected personal
                    information or an authorized representative
                  </li>
                  <li>
                    Describe Your request with sufficient detail that allows Us
                    to properly understand, evaluate, and respond to it
                  </li>
                </ul>
                We cannot respond to Your request or provide You with the
                required information if We cannot:
                <ul>
                  <li>Verify Your identity or authority to make the request</li>
                  <li>
                    And confirm that the personal information relates to You
                  </li>
                </ul>
                We will disclose and deliver the required information free of
                charge within 45 days of receiving Your verifiable request. The
                time period to provide the required information may be extended
                once by an additional 45 days when reasonable necessary and with
                prior notice.
                <br />
                <br />
                Any disclosures We provide will only cover the 12-month period
                preceding the verifiable request's receipt.
                <br />
                <br />
                For data portability requests, We will select a format to
                provide Your personal information that is readily useable and
                should allow You to transmit the information from one entity to
                another entity without hindrance.
              </p>
              <br />
              <h4>Website</h4>
              <p>
                You can opt out of receiving ads that are personalized as served
                by our Service Providers by following our instructions presented
                on the Service:
                <ul>
                  <li>
                    The NAI's opt-out platform:
                    http://www.networkadvertising.org/choices/
                  </li>
                  <li>
                    The EDAA's opt-out platform
                    http://www.youronlinechoices.com/
                  </li>
                  <li>
                    The DAA's opt-out platform:
                    http://optout.aboutads.info/?c=2&lang=EN
                  </li>
                </ul>
                The opt out will place a cookie on Your computer that is unique
                to the browser You use to opt out. If you change browsers or
                delete the cookies saved by your browser, You will need to opt
                out again.
              </p>
              <br />
              <h4>Mobile Devices</h4>
              <p>
                Your mobile device may give You the ability to opt out of the
                use of information about the apps You use in order to serve You
                ads that are targeted to Your interests:
                <ul>
                  <li>
                    "Opt out of Interest-Based Ads" or "Opt out of Ads
                    Personalization" on Android devices
                  </li>
                  <li>"Limit Ad Tracking" on iOS devices</li>
                </ul>
                You can also stop the collection of location information from
                Your mobile device by changing the preferences on Your mobile
                device.
              </p>
              <br />
              <br />
              <h2>
                "Do Not Track" Policy as Required by California Online Privacy
                Protection Act (CalOPPA)
              </h2>
              <br />
              <p>
                Our Service does not respond to Do Not Track signals.
                <br />
                <br />
                However, some third party websites do keep track of Your
                browsing activities. If You are visiting such websites, You can
                set Your preferences in Your web browser to inform websites that
                You do not want to be tracked. You can enable or disable DNT by
                visiting the preferences or settings page of Your web browser.
                <br />
                <br />
              </p>
              <br />
              <br />
              <h2>
                Your California Privacy Rights (California's Shine the Light
                law)
              </h2>
              <br />
              <p>
                Under California Civil Code Section 1798 (California's Shine the
                Light law), California residents with an established business
                relationship with us can request information once a year about
                sharing their Personal Data with third parties for the third
                parties' direct marketing purposes.
                <br />
                <br />
                If you'd like to request more information under the California
                Shine the Light law, and if You are a California resident, You
                can contact Us using the contact information provided below.
              </p>
              <br />
              <br />
              <h2>
                California Privacy Rights for Minor Users (California Business
                and Professions Code Section 22581)
              </h2>
              <br />
              <p>
                California Business and Professions Code section 22581 allow
                California residents under the age of 18 who are registered
                users of online sites, services or applications to request and
                obtain removal of content or information they have publicly
                posted.
                <br />
                <br />
                To request removal of such data, and if You are a California
                resident, You can contact Us using the contact information
                provided below, and include the email address associated with
                Your account.
                <br />
                <br />
                Be aware that Your request does not guarantee complete or
                comprehensive removal of content or information posted online
                and that the law may not permit or require removal in certain
                circumstances.
              </p>
              <br />
              <br />
              <h2>Links to Other Websites</h2>
              <br />
              <p>
                Our Service may contain links to other websites that are not
                operated by Us. If You click on a third party link, You will be
                directed to that third party's site. We strongly advise You to
                review the Privacy Policy of every site You visit.
                <br />
                <br />
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
                <br />
                <br />
              </p>
              <br />
              <br />
              <h2>Changes to this Privacy Policy</h2>
              <br />
              <p>
                We may update Our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page.
                <br />
                <br />
                We will let You know via email and/or a prominent notice on Our
                Service, prior to the change becoming effective and update the
                "Last updated" date at the top of this Privacy Policy.
                <br />
                <br />
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
                <br />
                <br />
              </p>
              <br />
              <br />
              <h2>Contact Us</h2>
              <br />
              <p>
                If you have any questions about this Privacy Policy, or would
                like to review, or would like us to delete, any of Your personal
                data that we have collected, You can contact us:
                <ul>
                  <li>By email: privacy@symops.io</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
