import React from "react";

import "./FeatureColumns.css";

export default function FeatureColumns(props) {
  const { item } = props;

  return (
    <div className="grid grid-cols-1 gap-24 w-full max-w-6xl mx-auto">
      {item.title && (
        <div className="w-full max-w-2xl mx-auto text-center">
          <h2>{item.title}</h2>
        </div>
      )}
      <div className={`flex flex-col gap-24 items-center justify-between`}>
        {item.content.map((feature, i) => {
          return (
            <article
              key={i}
              className={`flex flex-col gap-16 lg:gap-32 items-center justify-between ${
                item.reverseColumns
                  ? i % 2 === 0
                    ? "lg:flex-row-reverse"
                    : "lg:flex-row"
                  : i % 2 === 0
                  ? "lg:flex-row"
                  : "lg:flex-row-reverse"
              }`}
            >
              <div
                className="w-full flex flex-col gap-4"
                style={{ maxWidth: "450px" }}
              >
                <h3 className="text-2xl">{feature.title}</h3>
                <p
                  dangerouslySetInnerHTML={{ __html: feature.text }}
                  className="text-lg"
                ></p>
              </div>
              {feature.image && (
                <div className="w-full max-w-md">
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className="w-full"
                  />
                </div>
              )}
            </article>
          );
        })}
      </div>
    </div>
  );
}
