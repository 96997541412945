import CodeBlock from "../../components/Common/CodeBlock/CodeBlock";
import AddToSlack from "./AddToSlack";

import install_instructions_slack_request from "../../static/img/install_instructions_slack_request.png";

export default function SetupInstructions() {
  return (
    <div className="text-left">
      <div className="mb-10">
        <h4 className="mb-3 font-bold">Welcome! Let’s get started.</h4>

        <p>
          Sym helps developers safely manage daily access to cloud resources
          with workflows built in Terraform and Python.
        </p>
        <p className="mt-4">
          This tutorial will help you get Sym up and running with a sample
          workflow, and then point you toward instructions for connecting Sym to
          AWS, Okta, GitHub, and more.
        </p>
      </div>

      <div className="mb-10">
        <h4 className="mb-3 font-bold">
          1. Install Sym in your Slack workspace
        </h4>
        <p>
          Sym’s setup starts in Slack. Install the Sym app for your Slack
          workspace by clicking the button below. After installing our app, you
          can run <code>/sym</code>, which will guide you through the rest of
          the process.
        </p>
        <div className="mt-4">
          <AddToSlack />
        </div>
      </div>

      <div className="mb-10">
        <h4 className="mb-3 font-bold">
          2. Install the symflow CLI to manage Sym
        </h4>
        <p>
          The <code>symflow</code> CLI is where you can log in, manage users and
          other resources, and make updates to your Terraform and Python
          configurations. For methods other than brew, please refer to our{" "}
          <a
            href="https://docs.symops.com/docs/install-sym-flow-cli"
            target="_blank"
            rel="noreferrer"
          >
            documentation
          </a>
          .
        </p>
        <div className="mt-6">
          <CodeBlock>
            {`<mark class="hjs-mark-custom hjs-custom-blue">~/Documents/sym</mark>
<mark class="hjs-mark-custom hjs-custom-yellow">&#x276f;</mark> <mark class="hjs-mark-custom hjs-custom-white">brew install symopsio/tap/symflow</mark>

<mark class="hjs-mark-custom hjs-custom-blue">~/Documents/sym</mark>
<mark class="hjs-mark-custom hjs-custom-yellow">&#x276f;</mark> <mark class="hjs-mark-custom hjs-custom-white">symflow login</mark>
<mark class="hjs-mark-custom hjs-custom-green">Login succeeded</mark>`}
          </CodeBlock>
        </div>
      </div>

      <div className="mb-10">
        <h4 className="mb-3 font-bold">
          3. Generate your first Sym configuration
        </h4>
        <p>
          Sym provides a <code>symflow init</code> command which will create a
          new directory for your configuration, and a{" "}
          <code>symflow generate</code> command that will generate your first
          Terraform and Python files. You'll need to provide your Slack
          workspace ID, which you can find by running <code>/sym whoami</code>{" "}
          in Slack.
        </p>
        <div className="mt-6">
          <CodeBlock>
            {`<mark class="hjs-mark-custom hjs-custom-blue">~/Documents/sym</mark>
<mark class="hjs-mark-custom hjs-custom-yellow">&#x276f;</mark> <mark class="hjs-mark-custom hjs-custom-white">symflow init</mark>
<mark class="hjs-mark-custom hjs-custom-purple">What should we name the directory:</mark> <mark class="hjs-mark-custom hjs-custom-white">my-first-flow</mark>
<mark class="hjs-mark-custom hjs-custom-purple">Please enter your Slack workspace ID:</mark>
# You can find your workspace ID by running \`/sym whoami\` in Slack.

<mark class="hjs-mark-custom hjs-custom-blue">~/Documents/sym</mark>
<mark class="hjs-mark-custom hjs-custom-yellow">&#x276f;</mark> <mark class="hjs-mark-custom hjs-custom-white">cd my-first-flow && symflow generate</mark>
<mark class="hjs-mark-custom hjs-custom-green">Successfully generated your Sym Terraform configuration!</mark>

<mark class="hjs-mark-custom hjs-custom-blue">~/Documents/sym/my-first-flow</mark>
<mark class="hjs-mark-custom hjs-custom-yellow">&#x276f;</mark> <mark class="hjs-mark-custom hjs-custom-white">terraform init && terraform plan</mark>

<mark class="hjs-mark-custom hjs-custom-blue">~/Documents/sym/my-first-flow</mark>
<mark class="hjs-mark-custom hjs-custom-yellow">&#x276f;</mark> <mark class="hjs-mark-custom hjs-custom-white">terraform apply</mark>`}
          </CodeBlock>
        </div>
      </div>

      <div className="mb-10">
        <h4 className="mb-3 font-bold">4. Run your first Sym Flow in Slack</h4>
        <p>
          Once you <code>apply</code> your initial configuration, you can run{" "}
          <code>/sym</code> again from anywhere in Slack to see your new Flow in
          action. By default, you will be prompted to select what you need
          access to, why you need that access, and how long you will need it
          before you are automatically de-escalated.
          <br />
          <br />
          <b>
            Note: by default, Sym posts to #sym-requests. Please make sure it
            exists.
          </b>
        </p>
        <div className="mt-6 text-center">
          <img
            className="inline-block"
            alt="Add to Slack"
            style={{ width: "100%", maxWidth: "520px" }}
            src={install_instructions_slack_request}
          />
        </div>
      </div>

      <div>
        <h4 className="mb-3 font-bold">5. Add your own Targets and Flows</h4>
        <p>
          Now that you have Sym up and running, you can customize your Terraform
          configurations and Python implementation to add your own access
          targets, rules, and automations. Here are some resources to help you
          take your next steps:
        </p>
        <ol className="mt-4">
          <li>
            <a
              href="https://docs.symops.com/docs"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              <span
                className="mr-1"
                style={{ fontSize: "10px", position: "relative", top: "-2px" }}
              >
                &#x25cf;
              </span>{" "}
              Documentation portal
            </a>
          </li>
          <li>
            <a
              href="https://github.com/symopsio/examples"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              <span
                className="mr-1"
                style={{ fontSize: "10px", position: "relative", top: "-2px" }}
              >
                &#x25cf;
              </span>{" "}
              Example repository of fully-built Flows
            </a>
          </li>
        </ol>
        <p className="mt-4">
          Questions? Stuck? Email us at{" "}
          <a href="mailto:support@symops.io" className="text-blue-500">
            support@symops.com
          </a>
        </p>
      </div>
    </div>
  );
}
