import React from "react";

import IntegrationsListItem from "./IntegrationsListItem/IntegrationsListItem";

import "./IntegrationsList.css";

export default function IntegrationsList(props) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 lg:gap-10 xl:gap-12 pb-1">
      {props.integrations.map((item, i) => {
        return <IntegrationsListItem key={i} integration={item} />;
      })}
    </div>
  );
}
