import React, { useCallback, useState } from "react";
import Helmet from "react-helmet";
import Layout from "../../components/Layout";

import "./Sales.css";
import { submitMailchimpForm } from "../../util";

const PAGE_TITLE = "Request a Demo | Sym";

const useCases = {
  opt0: "Protect sensitive infrastructure",
  opt1: "Gain application-level functionality",
  opt2: "Work towards a compliance standard",
  opt3: "I'm just curious",
};

export default function DemoPage() {
  const rand = Math.floor(Math.random() * Math.floor(1000));

  const [invalidEmailSubmitted, setInvalidEmailSubmitted] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const [fakeEmailSubmitted, setFakeEmailSubmitted] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [useCase, setUseCase] = useState("opt0");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [uc, setUC] = useState<keyof typeof useCases>("opt0");

  const clearAll = () => {
    setName("");
    setEmail("");
    setUC("opt0");
    setUseCase("");
  };

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setGenericError(false);
      setFakeEmailSubmitted(false);
      setInvalidEmailSubmitted(false);

      const useCaseText = useCases[uc]
        ? useCases[uc]
        : `Other: ${uc ? uc : "Not specified"}`;
      const data = {
        NAME: name,
        EMAIL: email,
        USECASE: useCaseText,
      };

      const onSuccess = () => {
        clearAll();
        setSubmitted(true);
      };
      const onFailure = (message: string) => {
        console.error(message);

        if (
          message ===
          "This email cannot be added to this list. Please enter a different email address."
        ) {
          setFakeEmailSubmitted(true);
        } else if (
          message.includes("is an invalid email address and cannot be imported")
        ) {
          setInvalidEmailSubmitted(true);
        } else {
          setGenericError(true);
        }
      };
      submitMailchimpForm("49016633c3", data, onSuccess, onFailure);
    },
    [email, name, uc]
  );

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/sales" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="hero-bg-wrapper">
          <div className="w-full max-w-7xl mx-auto px-6">
            <div className="py-12 sm:py-16 text-center">
              <h1 className="text-4xl">Tell us a little about you</h1>
            </div>
            <div className="w-full max-w-md pb-16 flex flex-col mx-auto">
              <form
                onSubmit={handleSubmit}
                className="flex-1 flex flex-col gap-6"
              >
                <div
                  className={`flex-1 flex flex-col gap-6 ${
                    submitted ? "hidden" : ""
                  }`}
                >
                  <div className="input-wrapper">
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      id="name"
                      name="NAME"
                      placeholder="Name*"
                      className="text-input focus:outline-none"
                      required
                    />
                  </div>
                  <div className="input-wrapper">
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      id="email"
                      name="EMAIL"
                      placeholder="Work Email*"
                      pattern="^.+@.+\..+$"
                      className="text-input focus:outline-none"
                      required
                    />
                  </div>
                  <div className="mt-6 input-wrapper">
                    <div className="mb-6">
                      <label className="text-xl">
                        What is your use case?
                        <span className="text-sym-purple">*</span>
                      </label>
                    </div>
                    <div className="flex flex-col gap-4">
                      {(Object.keys(useCases) as (keyof typeof useCases)[]).map(
                        (u) => {
                          const elementId = `${rand}_${u}`;

                          return (
                            <label
                              className="inline-flex items-center cursor-pointer radio-button"
                              key={elementId}
                              htmlFor={elementId}
                            >
                              <input
                                value={u}
                                onChange={(e) => {
                                  setUC(
                                    e.target.value as keyof typeof useCases
                                  );
                                  setUseCase(u);
                                }}
                                type="radio"
                                name="USECASE"
                                id={elementId}
                                checked={useCase === u}
                                className="hidden"
                              />
                              <div className="radio-check"></div>
                              {useCases[u]}
                            </label>
                          );
                        }
                      )}
                      <label
                        className="inline-flex flex-row items-start cursor-pointer w-full radio-button"
                        htmlFor={`${rand}_opt4`}
                      >
                        <div>
                          <input
                            value={useCase}
                            onChange={(e) => {
                              setUseCase("opt4");
                            }}
                            type="radio"
                            id={`${rand}_opt4`}
                            checked={useCase === "opt4"}
                            className="hidden"
                          />
                          <div className="radio-check"></div>
                        </div>
                        <div className="flex-1 inline-flex flex-col gap-3">
                          <span>Other:</span>
                          <input
                            onChange={(e) => {
                              setUC(e.target.value as keyof typeof useCases);
                              setUseCase("opt4");
                            }}
                            type="text"
                            className="text-input focus:outline-none w-full"
                            name="USECASE"
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  {submitted && (
                    <div className="flex flex-col gap-8 text-center">
                      <p className="text-2xl text-sym-brightBlue">
                        You're on your way to quicker engineering and better
                        security!
                      </p>
                      <p className="text-2xl text-sym-brightBlue">
                        Our team will be in touch soon.
                      </p>
                      <div className="mt-4">
                        <a href="/" className="cta-button secondary">
                          Learn more about the product
                        </a>
                      </div>
                    </div>
                  )}
                  {fakeEmailSubmitted && (
                    <p className="text-red-500 font-bold">
                      Are you sure that email is right?
                    </p>
                  )}
                  {invalidEmailSubmitted && (
                    <p className="text-red-500 font-bold">
                      That email appears invalid. Please try a different email
                      or reach out to sales@symops.com.
                    </p>
                  )}
                  {genericError && (
                    <p className="text-red-500 font-bold">
                      There was a problem submitting, please try again or reach
                      out to sales@symops.com.
                    </p>
                  )}
                </div>
                <div>
                  {!submitted && (
                    <input
                      type="submit"
                      className="cta-button"
                      value="Submit"
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="hero-bg sales-hero-bg"></div>
        </div>
      </Layout>
    </>
  );
}
