/*
 * This is a list (and associated data) of all the integration pages. By adding,
 * editing or removing an integration from this array, it will update the main
 * /integrations page, and add (or remove) a /integrations/:id route.
 *
 * Sample input:
 *
 * {
 *   id: 'hello-world', // This will generate /integrations/hello-world
 *   title: 'Hello World',
 *   navTitle: 'Hello', // Title to show in nav (nothing long)
 *   previewImage: '', // Icon to represent integration
 *   shortDescription: '', // Short description shown on platform page boxes
 *   content: [ // This is an array of components, all example components are included below
 *     {
 *       type: 'image',
 *       src: '', // Link to image
 *       caption: 'Image Caption: Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
 *     },
 *     {
 *       type: 'text',
 *       content: 'Enter text here',
 *     },
 *     {
 *       type: 'subtitle',
 *       content: 'Enter subtitle here',
 *     },
 *   ],
 * }
 */

/*
 * List of featured integrations
 *
 * This is a list corresponding to integration.id
 * You should have at least 4 featured integrations here for best display
 */
const FeaturedIntegrationIdsList = ["aws-sso", "datadog", "jira", "pagerduty"];

/*
 * List of integrations included in nav bar
 *
 * This is a list corresponding to integration.id
 */
const NavigationIntegrationIdsList = ["aws-sso", "datadog", "jira"];

const Integrations = [
  {
    id: "aptible",
    title: "Aptible",
    subTitle:
      "Add safe, temporary access to your secure Aptible infrastructure",
    navTitle: "Aptible",
    docsLink: "https://docs.symops.com/docs/aptible",
    previewImage: "/assets/integrations/logo-aptible.png",
    integrationOverview:
      "If you’re using Aptible to manage and deploy your infrastructure, compliance and security are already top of mind. By integrating Sym and Aptible, you can reduce risk even further without slowing your teams down." +
      "<br/><br/>" +
      "Sym provides just-in-time, temporary access to Aptible, as well as a full audit record of every access decision and escalation. Stay safe and compliant by using Sym with Aptible.",
    useCases: [
      "Temporary access to Aptible roles",
      "Audit-ready access logs",
      "Automatic identity discovery",
    ],
  },

  {
    id: "aws-sso",
    title: "AWS IAM",
    subTitle:
      "Customized temporary access to AWS IAM Identity Center, without the maintenance",
    navTitle: "AWS IAM",
    docsLink: "https://docs.symops.com/docs/aws-sso",
    previewImage: "/assets/integrations/logo-aws-identity-center.png",
    integrationOverview:
      "Build custom guardrails for temporary AWS access with Sym's AWS IAM integrations. Sym's SDK allows users to select AWS Accounts, Permission Sets, and Groups according to the business rules you need to enforce." +
      "<br/></br/>" +
      "Deep AWS Organization integration allows automatic discovery of AWS accounts within a given Organizational Unit, so you can simpflify configuration for dynamic multi-account environments.",
    useCases: [
      "Temporary AWS IAM Identity Center assignments",
      "Temporary AWS IAM Group membership",
      "AWS Organizations integration",
    ],
    features: [
      {
        type: "feature-block",
        image: "/assets/whitepapers/whitepaper-jit-graphic.svg",
        imageAlt: "Sym AWS JIT access guide",
        imageMaxWidth: 360,
        containerMaxWidth: 1100,
        content: [
          {
            type: "title",
            content: "Read the guide on implementing JIT access in AWS",
          },
          {
            type: "text",
            content:
              "Practical advice on applying least privilege policies to your AWS infrastructure.",
          },
          {
            type: "button",
            text: "Get The Guide",
            link: "/whitepapers/jit-access-guide",
            target: "_self",
          },
        ],
      },
    ],
  },

  {
    id: "aws-lamdba",
    title: "AWS Lambda",
    subTitle: 'Integrate "last mile" Lambda functions for full extensibility',
    navTitle: "AWS Lambda",
    docsLink: "https://docs.symops.com/docs/aws-lambda",
    previewImage: "/assets/integrations/logo-lambda.png",
    integrationOverview:
      "Use Sym's Lambda integration to protect services in your VPC. No need to punch a hole in the firewall, just provide Sym permissions to invoke the Lambda function using Sym's lambda-connector Terraform module." +
      "<br/><br/>" +
      "You can also use Lambda to integrate with SaaS services that require libraries that are not supported by the Sym runtime.",
    useCases: [
      "Temporary access to internal tools and databases",
      "Integrate custom libraries in your escalation code",
      "Execute sensitive jobs",
    ],
    features: [
      {
        type: "feature-block",
        image: "/assets/whitepapers/whitepaper-jit-graphic.svg",
        imageAlt: "Sym AWS JIT access guide",
        imageMaxWidth: 360,
        containerMaxWidth: 1100,
        content: [
          {
            type: "title",
            content: "Read the guide on implementing JIT access in AWS",
          },
          {
            type: "text",
            content:
              "Practical advice on applying least privilege policies to your AWS infrastructure.",
          },
          {
            type: "button",
            text: "Get The Guide",
            link: "/whitepapers/jit-access-guide",
            target: "_self",
          },
        ],
      },
    ],
  },

  {
    id: "boundary",
    title: "HCP Boundary",
    subTitle: "Temporary access to HCP Boundary targets",
    navTitle: "Boundary",
    docsLink: "https://docs.symops.com/docs/boundary",
    previewImage: "/assets/integrations/logo-boundary.png",
    integrationOverview:
      "Help users discover the HCP Boundary targets that they need access to with Sym's context-based controls." +
      "<br><br>" +
      "Use the Sym SDK to list groups and Sym's Boundary Access Strategy to manage temporary escalations.",
    useCases: [
      "Temporary access to Boundary Targets",
      "Boundary Group and Membership discovery",
      "Audit-ready access logs",
    ],
    features: [
      {
        type: "feature-block",
        image: "/assets/whitepapers/whitepaper-zero-trust-graphic.svg",
        imageAlt: "Sym Zero Trust Security access guide",
        imageMaxWidth: 360,
        containerMaxWidth: 1100,
        content: [
          {
            type: "title",
            content: "Read the guide on implementing Zero Trust Security",
          },
          {
            type: "text",
            content:
              "Practical advice for improving your security posture with zero trust.",
          },
          {
            type: "button",
            text: "Get The Guide",
            link: "/whitepapers/zero-trust-security",
            target: "_self",
          },
        ],
      },
    ],
  },

  {
    id: "circleci",
    title: "CircleCI",
    subTitle: "Dynamic approvals anywhere in your deployment pipeline",
    navTitle: "CircleCI",
    docsLink: "https://docs.symops.com/docs/circleci-and-sym",
    previewImage: "/assets/integrations/logo-circleci.png",
    integrationOverview:
      "Connect Sym and CircleCI to trigger Slack-based approvals right from your deployment pipeline. Trigger a Sym workflow from inside of a CircleCI job, and then wait for an approval to continue your CircleCI workflow. In the middle, you gain all the advantages of Sym’s programmable approvals, SDK, and audit logging.",
    useCases: [
      "Dynamic approval of pipeline steps",
      "Audit-ready access logs",
      "Custom HTTP integration",
    ],
  },

  {
    id: "datadog",
    title: "Datadog",
    subTitle:
      "Surface authorization activity in Datadog for governance and audit",
    navTitle: "Datadog",
    docsLink: "https://docs.symops.com/docs/datadog",
    previewImage: "/assets/integrations/logo-datadog.png",
    integrationOverview:
      "You can stream your Sym audit logs directly to Datadog with our native integration." +
      "<br/><br/>" +
      "Simply authorize Sym in your Datadog organization, Terraform a sym_log_destination, and your logs will land in a prebuilt dashboard that you can customize to fit your reporting needs.",
    useCases: ["Prebuilt dashboard", "Built-in facets", "Attribute mappings"],
  },

  {
    id: "github",
    title: "GitHub",
    subTitle: "Temporary escalated access to GitHub repositories",
    navTitle: "GitHub",
    docsLink: "https://docs.symops.com/docs/github",
    previewImage: "/assets/integrations/logo-github.png",
    integrationOverview:
      "Sym adds just-in-time access to sensitive GitHub repos." +
      "<br/><br/>" +
      "Keep your team and your code safe with least-privileged access, without compromising on speed.",
    useCases: [
      "Temporary access to GitHub repos",
      "Discover GitHub role memberships in the SDK",
      "Audit-ready access logs",
    ],
  },

  {
    id: "jira",
    title: "Jira",
    subTitle: "Automate your access management ticket queue",
    navTitle: "Jira",
    docsLink: "https://docs.symops.com/docs/jira",
    previewImage: "/assets/integrations/logo-jira.png",
    integrationOverview:
      "Increase your team's velocity by directly integrating the right context from Jira in your access flows." +
      "<br/><br/>" +
      "You can create Jira tickets automatically as part of your Sym flow, comment on tickets, and discover existing tickets.",
    useCases: [
      "Link a Sym request to a Jira ticket",
      "Create Jira tickets automatically",
      "Comment on Jira tickets from any workflow step",
    ],
  },

  {
    id: "knowbe4",
    title: "KnowBe4",
    subTitle:
      "Integrate security training context into your authorization decisions",
    navTitle: "KnowBe4",
    docsLink: "https://docs.symops.com/docs/knowbe4-sdk-integration",
    previewImage: "/assets/integrations/logo-knowbe4.png",
    integrationOverview:
      "Enrich your authorization flows by integrating security training context into your Sym flows." +
      "<br><br>" +
      "Sym's SDK integration can check training enrollments, pass/fail status, and group membership for users. Use this information to dynamically route requests and grant or deny access.",
    useCases: [
      "Enforce training requirements",
      "Check training enrollments",
      "Check group membership",
    ],
  },

  {
    id: "okta",
    title: "Okta",
    subTitle:
      "Bring context-based intelligence into your Okta group membership decisions",
    navTitle: "Okta",
    docsLink: "https://docs.symops.com/docs/okta",
    previewImage: "/assets/integrations/logo-okta.png",
    integrationOverview:
      "Scale back your default privileges in Okta and protect your organization by creating temporary access to Okta groups with Sym." +
      "<br><br>" +
      "Sym also provides an SDK integration with Okta, so you can use information about Okta users and groups to automate your decisions.",
    useCases: [
      "Temporary Okta Group assignments",
      "Access Okta User profile data in the SDK",
      "Access Okta Group membership data in the SDK",
    ],
  },

  {
    id: "onelogin",
    title: "OneLogin",
    subTitle:
      "Bring context-based intelligence into your OneLogin role assignment decisions",
    navTitle: "OneLogin",
    docsLink: "https://docs.symops.com/docs/onelogin",
    previewImage: "/assets/integrations/logo-onelogin.png",
    integrationOverview:
      "Scale back your default privileges in OneLogin and protect your organization by creating temporary access to OneLogin roles with Sym." +
      "<br><br>" +
      "Sym also provides an SDK integration with OneLogin, so you can use information about OneLogin users and roles to automate your decisions.",
    useCases: [
      "Temporary OneLogin Roleassignments",
      "Access OneLogin User profile data in the SDK",
      "Access OneLogin Role membership data in the SDK",
    ],
  },

  {
    id: "pagerduty",
    title: "PagerDuty",
    subTitle: "A safe and reliable path to break glass access",
    navTitle: "PagerDuty",
    docsLink: "https://docs.symops.com/docs/pagerduty",
    previewImage: "/assets/integrations/logo-pagerduty.png",
    integrationOverview:
      "Fast-track On-Call access using the Sym SDK and PagerDuty. Don't wake up more people than you need to in order to respond to a 2AM incident, but keep all the access guardrails you need for compliance." +
      "<br><br>" +
      "The SDK lets teams integrate PagerDuty On-Call Status, Incidents, and Schedules into authorization logic.",
    useCases: [
      "Check On-Call and Schedule status in the SDK",
      "Check Incident assignments in the SDK",
      "Audit-ready access logs",
    ],
  },

  {
    id: "slack",
    title: "Slack",
    subTitle: "Make security a team sport",
    navTitle: "Slack",
    docsLink: "https://docs.symops.com/docs/slack-sdk",
    previewImage: "/assets/integrations/logo-slack.png",
    integrationOverview:
      "Sym's Slack App brings visibility and collaboration to access management decisions." +
      "<br/><br/>" +
      "Use Sym's Slack SDK to interact with users at any step in a workflow, as well as to get information about slack users and groups to inform routing and access decisions.",
    useCases: [
      "Request and approve access requests in Slack",
      "Message users and channels in the SDK",
      "Get user and group profile information in the SDK",
    ],
  },

  {
    id: "tailscale",
    title: "Tailscale",
    subTitle:
      "Sym adds safe, temporary access to resources managed by Tailscale",
    navTitle: "Tailscale",
    docsLink: "https://docs.symops.com/docs/tailscale",
    previewImage: "/assets/integrations/logo-tailscale.png",
    integrationOverview:
      "If you’re using Tailscale to manage secure access to network devices, then just-in-time access through Sym is the perfect complement for maintaining a secure, least-privileged access model." +
      "<br/><br/>" +
      "Sym’s Slack-based access requests and seamless, centralized audit logging add an extra layer of safety while helping your teams move even faster.",
    useCases: [
      "Temporary access to Tailscale Groups",
      "Audit-ready access logs",
      "Automatic identity discovery",
    ],
  },

  {
    id: "vanta",
    title: "Vanta",
    subTitle: "Automate entitlements review",
    navTitle: "Vanta",
    docsLink: "https://docs.symops.com/docs/vanta-integration",
    previewImage: "/assets/integrations/logo-vanta.png",
    integrationOverview:
      "Sync Sym users to Vanta to provide evidence that your team has made the switch to dynamic authorization." +
      "<br/><br/>" +
      "Connect Sym directly to your Vanta account from the Vanta integrations portal to get started!",
    useCases: [
      "Sync Sym users to Vanta",
      "Easy integration from your Vanta Portal",
    ],
  },
];

// Compute and return featured integrations list
const FeaturedIntegrations = Integrations.filter((integration) =>
  FeaturedIntegrationIdsList.includes(integration.id)
);

// Compute and return navigation integrations list
const NavgationIntegrations = Integrations.filter((integration) =>
  NavigationIntegrationIdsList.includes(integration.id)
);

export { Integrations, FeaturedIntegrations, NavgationIntegrations };
