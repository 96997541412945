import React from "react";

import "./FeatureColumns.css";

export default function FeatureColumns(props) {
  const { item } = props;

  return (
    <div className={`w-full flex-row gap-24 items-start ${item.colClass}`}>
      {item.content.map((feature, i) => {
        return (
          <article
            key={i}
            className={`flex flex-col-reverse ${
              item.colClass === "one-col-right"
                ? "lg:flex-row-reverse"
                : "lg:flex-row"
            } items-center justify-between gap-16`}
          >
            {feature.image && (
              <div className="w-full" style={{ maxWidth: "500px" }}>
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="w-full"
                />
              </div>
            )}
            <div className="flex flex-col gap-4 text-center lg:text-left">
              <h3 className="text-2xl">{feature.title}</h3>
              <p
                dangerouslySetInnerHTML={{ __html: feature.text }}
                className="text-lg"
              ></p>
            </div>
          </article>
        );
      })}
    </div>
  );
}
