import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../Common/Logo";
import styles from "./TopNav.module.css";

import DownArrowIconWhite from "../../../static/img/down-arrow-white.svg";

import { ProductPages } from "../../../pages/Product/ProductList";
import { NavgationIntegrations } from "../../../pages/Integrations/IntegrationsList";

const MobileNavDropdownIcon = (props) => {
  return (
    <div
      className={`${styles.mobileNavDropdownIcon} ${
        props.isOpen ? styles.mobileNavDropdownIconOpen : null
      }`}
    >
      <svg
        width="18"
        height="10"
        viewBox="0 0 18 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L9 9L17 1"
          stroke="#FFF8F8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

const NavMobile = (props) => {
  const [companyOpen, setCompanyOpen] = useState(false);
  const handleCompanyOpen = () => {
    setCompanyOpen(!companyOpen);
  };

  const [featuresOpen, setFeaturesOpen] = useState(false);
  const handleFeaturesOpen = () => {
    setFeaturesOpen(!featuresOpen);
  };

  return (
    <div
      className={`xl:hidden flex flex-col w-full fixed h-screen bg-sym-darkBlue bg-opacity-95 border border-white border-opacity-10 z-40 px-4 justify-start items-start ${
        props.show === true ? "translate-x-0" : "-translate-x-full"
      } transform duration-300`}
    >
      <ul
        className={`flex flex-col justify-between w-full pt-28 ${styles.mobileNavList}`}
      >
        <li
          className={`py-4 border-b border-white border-opacity-20 w-full flex ${
            featuresOpen ? "flex-col items-start pb-3" : "items-center"
          }`}
        >
          <div
            className="w-full flex items-center justify-between"
            onClick={() => handleFeaturesOpen()}
          >
            <div className={`${featuresOpen ? "text-sym-purple" : ""}`}>
              Product
            </div>
            <div>
              <MobileNavDropdownIcon isOpen={featuresOpen} />
            </div>
          </div>
          {featuresOpen ? (
            <>
              <div className="mt-5 flex flex-col">
                <h5 className="font-bold">Use cases</h5>
                <div className={styles.mobileNavDropdownList}>
                  {ProductPages.map((page, i) => {
                    return (
                      <a
                        key={i}
                        className="text-lg pt-3"
                        title={page.navTitle || page.title}
                        href={"/product/" + page.id}
                      >
                        {page.navTitle || page.title}
                      </a>
                    );
                  })}
                </div>
              </div>
              <div className="mt-6 flex flex-col">
                <h5 className="font-bold">Integrations</h5>
                <div className={styles.mobileNavDropdownList}>
                  {NavgationIntegrations.map((page, i) => {
                    return (
                      <a
                        key={i}
                        className="text-lg pt-3"
                        title={page.navTitle || page.title}
                        href={"/integrations/" + page.id}
                      >
                        {page.navTitle || page.title}
                      </a>
                    );
                  })}
                </div>
                <a href="/integrations" className="text-lg pt-3 mt-4 mb-4">
                  Explore all integrations
                </a>
              </div>
            </>
          ) : null}
        </li>

        <li className="py-4 border-b border-white border-opacity-20 w-full flex">
          <a title="Blog" href="https://blog.symops.com/" rel="noreferrer">
            Blog
          </a>
        </li>
        <li className="py-4 border-b border-white border-opacity-20 w-full flex">
          <a title="Docs" href="https://docs.symops.com/" rel="noreferrer">
            Docs
          </a>
        </li>
        <li
          className={`py-4 border-b border-white border-opacity-20 w-full flex ${
            companyOpen ? "flex-col items-start pb-3" : "items-center"
          }`}
        >
          <div
            className="w-full flex items-center justify-between"
            onClick={() => handleCompanyOpen()}
          >
            <div className={`${companyOpen ? "text-sym-purple" : ""}`}>
              Company
            </div>
            <MobileNavDropdownIcon isOpen={companyOpen} />
          </div>
          {companyOpen ? (
            <>
              <a className="pt-4" title={"About"} href={"/about"}>
                About
              </a>
              <a className="pt-4" title={"Security"} href={"/security"}>
                Security
              </a>
            </>
          ) : null}
        </li>
      </ul>
    </div>
  );
};

export default function TopNav(props) {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const node = useRef();

  // Company dropdown
  const companyDropdownRef = useRef();
  const [companyDropDown, setCompanyDropdown] = useState(false);
  const handleCompanyDropDown = () => {
    setCompanyDropdown(!companyDropDown);
  };

  // Features dropdown
  const featuresDropdownRef = useRef();
  const [featuresDropDown, setFeaturesDropdown] = useState(false);
  const handleFeaturesDropDown = () => {
    setFeaturesDropdown(!featuresDropDown);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }

    if (!companyDropdownRef.current.contains(e.target)) {
      setCompanyDropdown(false);
    }

    if (!featuresDropdownRef.current.contains(e.target)) {
      setFeaturesDropdown(false);
    }
  };

  // Set this to "const topBanner = null" if no banner needed
  const topBanner = props.topBanner;
  const topBannerLink = props.topBannerLink;

  return (
    <React.Fragment>
      {!props.hideBanner && topBanner && topBanner.length > 0 && (
        <a href={topBannerLink} target="_blank" rel="noreferrer">
          <div className={`${styles.announcementBanner} text-center fixed`}>
            <div
              className={styles.announcementBannerText}
              dangerouslySetInnerHTML={{
                __html: topBanner,
              }}
            ></div>
          </div>
        </a>
      )}

      <NavMobile show={show} setShow={setShow} />
      <div
        className={`${styles.topNav} ${
          !props.hideBanner && topBanner && topBanner.length > 0
            ? styles.topNavHasTopBanner
            : ""
        } flex w-full justify-center items-center relative`}
      >
        <div
          className={`${styles.topNavWrapper} justify-between w-full items-center`}
        >
          <div className="flex flex-row flex-1 px-6">
            <div className="relative lg:w-1/2 w-full justify-between xl:justify-start flex-1 flex">
              <a href="/" className="w-20 xl:w-32">
                <Logo />
                <span className="sr-only">SYM</span>
              </a>
              <div className="inline-flex xl:hidden items-center">
                <div
                  className="cursor-pointer"
                  onClick={() => setShow(!show)}
                  style={{ width: "40px", padding: "8px", margin: "-8px" }}
                >
                  <svg
                    viewBox="0 0 26 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 10.9705H25"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1.26465H25"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 19.7056H25"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <ul
              className={`${styles.navList} list-none items-center xl:flex xl:flex-row xl:items-center hidden`}
            >
              <li className={`${styles.navitemli}`}>
                <div
                  className={`${styles.navDropdownWrapper}`}
                  onClick={() => handleFeaturesDropDown()}
                  ref={featuresDropdownRef}
                >
                  <div
                    className={`${styles.navCompanyWrapper} ${
                      featuresDropDown ? styles.dropdownOpen : ""
                    }`}
                  >
                    <div className={`${styles.navDropdownParentLink}`}>
                      Product
                    </div>
                    <img src={DownArrowIconWhite} alt="" />
                  </div>
                  <div
                    className={`${
                      styles.navDropDown
                    } bg-sym-darkBlue bg-opacity-95 border border-white border-opacity-10 ${
                      styles.navDropDownProduct
                    } flex flex-row gap-5 ${
                      featuresDropDown ? "block" : "hidden"
                    }`}
                    ref={node}
                  >
                    <div>
                      <h5 className="mb-3 font-bold">Use cases</h5>
                      <div className={styles.desktopNavDropdownList}>
                        {ProductPages.map((page, i) => {
                          return (
                            <div
                              key={i}
                              className={`${styles.navDropDownItem} ${
                                location.pathname.includes(
                                  "/product/" + page.id
                                )
                                  ? styles.navDropDownActive
                                  : ""
                              }`}
                            >
                              <a href={"/product/" + page.id}>
                                {page.navTitle || page.title}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                      <div className="mt-4">
                        <h5 className="mb-3 font-bold">Integrations</h5>
                      </div>
                      <div className={styles.desktopNavDropdownList}>
                        {NavgationIntegrations.map((page, i) => {
                          return (
                            <div
                              key={i}
                              className={`${styles.navDropDownItem} ${
                                location.pathname.includes(
                                  "/integrations/" + page.id
                                )
                                  ? styles.navDropDownActive
                                  : ""
                              }`}
                            >
                              <a href={"/integrations/" + page.id}>
                                {page.navTitle || page.title}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                      <div className="mt-4">
                        <a href="/integrations">Explore all integrations</a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className={`${styles.navitemli}`}>
                <a
                  title="Blog"
                  href="https://blog.symops.com/"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </li>

              <li className={`${styles.navitemli}`}>
                <a
                  title="Docs"
                  href="https://docs.symops.com/"
                  rel="noreferrer"
                >
                  Docs
                </a>
              </li>

              <li className={`${styles.navitemli}`}>
                <a title="Customers" href={"/customers"}>
                  Customers
                </a>
              </li>

              <li className={`${styles.navitemli}`}>
                <div
                  className={`${styles.navDropdownWrapper}`}
                  onClick={() => handleCompanyDropDown()}
                  ref={companyDropdownRef}
                >
                  <div
                    className={`${styles.navCompanyWrapper} ${
                      companyDropDown ? styles.dropdownOpen : ""
                    }`}
                  >
                    <div className={`${styles.navDropdownParentLink}`}>
                      Company
                    </div>
                    <img src={DownArrowIconWhite} alt="" />
                  </div>
                  <div
                    className={`${
                      styles.navDropDown
                    } bg-sym-darkBlue bg-opacity-95 border border-white border-opacity-10 ${
                      companyDropDown ? "block" : "hidden"
                    }`}
                    ref={node}
                  >
                    <div
                      className={`${styles.navDropDownItem} ${
                        location.pathname.includes("about")
                          ? styles.navDropDownActive
                          : ""
                      }`}
                    >
                      <a href={"/about"}>About</a>
                    </div>
                    <div
                      className={`${styles.navDropDownItem} ${
                        location.pathname.includes("security")
                          ? styles.navDropDownActive
                          : ""
                      }`}
                    >
                      <a href={"/security"}>Security</a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
