import React from "react";

import "./UseCasePostText.css";

export default function UseCasePostText(props) {
  const { item } = props;

  return (
    <p
      className="use-case-post-text"
      dangerouslySetInnerHTML={{ __html: item.content }}
    ></p>
  );
}
