import React from "react";
import "./HeroSection.css";
import AnimationContainer from "../../../AnimationContainer/AnimationContainer";
import HeroVideo from "../HeroVideo/HeroVideo.js";
import HeroSectionFeatures from "../HeroSectionFeatures/HeroSectionFeatures";

export default function HeroSection() {
  return (
    <div className="hero-bg-wrapper">
      <AnimationContainer animationClass="home-hero-section-animation">
        <section className="hero-section-wrapper w-full justify-center flex flex-col px-4 md:px-8 lg:px-8 xl:px-16 2xl:px-40">
          <div className="hero-top-wrapper w-full flex flex-row items-center justify-center py-16 md:py-20 lg:py-24 xl:py-28">
            <div className="hero-text-section flex flex-col items-center text-center">
              <h1 className="hero-section-title flex flex-col mb-8">
                Embrace your best security asset: Your Team
              </h1>

              <h4 className="hero-text mb-8">
                Your team is your best defense against attacks. Empower them to
                build safe, scalable access workflows.
              </h4>

              <div className="try-sym-wrapper">
                <HeroVideo
                  videoUrl="https://vimeo.com/815222490/c717c18c42"
                  useButtonToggle={true}
                  buttonToggleId="btn-home-cta-watch-demo"
                />
              </div>
            </div>
          </div>

          <div className="home-hero-logos">
            <img
              src="assets/logos/customers-home-cta/logos-desktop.svg"
              alt="Customer Logos"
              className="hidden sm:block"
            />
            <img
              src="assets/logos/customers-home-cta/logos-mobile.svg"
              alt="Customer Logos"
              className="block sm:hidden"
            />
          </div>
        </section>

        <div className="home-cta-video-wrapper mb-4">
          <div className="px-6">
            <HeroVideo
              videoUrl="https://vimeo.com/870801909/b41114c2e6"
              videoPreview="assets/demo-video.mp4"
              videoPoster="assets/demo-video-preview.jpg"
            />
          </div>
        </div>

        <div className="mt-20">
          <HeroSectionFeatures />
        </div>
      </AnimationContainer>
      <div className="hero-bg home-hero-bg"></div>
    </div>
  );
}
