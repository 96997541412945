import React from "react";
import Left from "../../Icons/Left";

export default function PrevArrow({ onClick }) {
  return (
    <div
      onClick={onClick}
      className="hidden lg:flex border border-black bg-white rounded-full w-16 h-16 justify-center items-center absolute bottom-1/3 left-0 ml-24 cursor-pointer z-50"
    >
      <div className="w-6">
        <Left />
      </div>
    </div>
  );
}
