import React from "react";

import "../../sections.css";
import "./securityCover.css";

import ShieldIllustration from "../images/security-shields.svg";

const containerStyle = {
  animation: "fadeIn ease 1s",
};

export default function SecurityCover() {
  return (
    <div
      className="mb-12 py-12 sm:py-16 text-center"
      style={{
        ...containerStyle,
      }}
    >
      <div className="flex flex-col gap-6">
        <h1>Security at Sym</h1>
        <p className="w-full max-w-lg mx-auto text-xl">
          Security, operational excellence, and meeting our regulatory
          requirements are our highest priorities.
        </p>
        <div className="flex flex-row justify-center mt-4">
          <img
            src={ShieldIllustration}
            alt="Security at Sym"
            className="w-full max-w-xs"
          />
        </div>
      </div>
    </div>
  );
}
