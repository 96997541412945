import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../../components/Layout";
import styles from "../Legal.module.css";

const PAGE_TITLE = "Subprocessors | Sym";

export default function Subprocessors() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/subprocessors" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div
          className={`${styles.legalPage} " flex flex-col w-full justify-center px-4 md:px-8 lg:px-8 xl:px-16 2xl:px-40 relative`}
        >
          <h1>Authorized Subprocessors</h1>
          <br />

          <p>Last updated: March 12, 2021</p>
          <br />
          <br />

          <table className={`${styles.subprocessorTable}`}>
            <tr>
              <th>Company Name</th>
              <th>Details of Processing</th>
              <th>Service Location</th>
            </tr>
            <tr>
              <td>Amazon.com, Inc.</td>
              <td>Execution Environment</td>
              <td>AWS US-East-1 and/or US-West-2</td>
            </tr>
            <tr>
              <td>Auth0, Inc.</td>
              <td>Authentication Services</td>
              <td>AWS US-East-1 and/or US-West-2</td>
            </tr>
            <tr>
              <td>Sumo Logic, Inc.</td>
              <td>Security Monitoring and Logging</td>
              <td>AWS US-East-1 and/or US-West-2</td>
            </tr>
            <tr>
              <td>Sentry - Functional Software, Inc.</td>
              <td>Application Logging and Exception Monitoring</td>
              <td>Google Data Centers, US Only</td>
            </tr>
          </table>
        </div>
      </Layout>
    </>
  );
}
