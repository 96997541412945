import React from "react";
import "./HeroSectionFeatures.css";

export default function HeroSectionFeatures() {
  return (
    <>
      <section className="w-full max-w-7xl mx-auto px-6">
        <div className="flex flex-col lg:flex-row gap-12">
          <div className="flex flex-row gap-4">
            <div className="w-8 h-auto block flex-shrink-0">
              <img
                className="w-full h-auto"
                alt="Access that’s really just-in-time"
                src="assets/home-hero-graphic-1.svg"
              />
            </div>
            <div>
              <h3 className="mb-2 lg:mb-3 font-normal">
                Remove the risk of over-provisioned access
              </h3>
              <p className="opacity-80">
                Automatically grant or revoke access to your production
                environment to help maintain Zero Trust.
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div className="w-8 h-auto block flex-shrink-0">
              <img
                className="w-full h-auto"
                alt="Access that’s really just-in-time"
                src="assets/home-hero-graphic-2.svg"
              />
            </div>
            <div>
              <h3 className="mb-2 lg:mb-3 font-normal">
                Replace manual ticket queues
              </h3>
              <p className="opacity-80">
                Send access requests to the right approver automatically.
                Automate predictable access request patterns to avoid
                bottlenecks.
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div className="w-8 h-auto block flex-shrink-0">
              <img
                className="w-full h-auto"
                alt="Access that’s really just-in-time"
                src="assets/home-hero-graphic-3.svg"
              />
            </div>
            <div>
              <h3 className="mb-2 lg:mb-3 font-normal">
                Boost visibility and collaboration
              </h3>
              <p className="opacity-80">
                Access a complete record of who was granted access to what. Help
                mitigate risk & fulfill compliance like SOC 2.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
