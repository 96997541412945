import React from "react";

import "./IntegrationOtherPosts.css";
import { Integrations } from "../../../../pages/Integrations/IntegrationsList.js";
import IntegrationsList from "../IntegrationsList/IntegrationsList.js";

export default function IntegrationOtherPosts(props) {
  // const currentId = props.currentId;

  // Get all integrations that aren't the currentId
  // const otherIntegrations = Integrations.filter(
  //   (integration) => integration.id !== currentId
  // );

  return <IntegrationsList integrations={Integrations} />;
}
