import React from "react";

export default function Logo() {
  return (
    <svg
      stroke="none"
      viewBox="0 0 125 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M105.619 0.529477L100.157 19.0864L94.8468 0.529477C94.7583 0.214312 94.4801 0 94.1641 0H78.4743C78.095 0 77.7916 0.302558 77.7663 0.70597L75.8319 33.2058C75.8066 33.6344 76.1354 34 76.5399 34H85.6428C86.0221 34 86.3255 33.6848 86.3508 33.294L87.6151 11.0308L93.7722 33.4579C93.8607 33.7731 94.1388 34 94.4549 34H104.936C105.252 34 105.53 33.7857 105.619 33.4705L112.37 10.0096L113.849 33.3066C113.874 33.6974 114.178 34 114.557 34H124.305C124.722 34 125.038 33.6344 125.013 33.1932L122.661 0.693363C122.636 0.302558 122.332 0 121.953 0H106.301C105.985 0 105.72 0.214312 105.619 0.529477Z"
          fill="white"
        />
        <path
          d="M15.7277 16.9937V22.6667H0.745929C0.341357 22.6667 0 23.0197 0 23.4483V33.2184C0 33.647 0.328714 34 0.745929 34H30.7222C31.1267 34 31.4681 33.647 31.4681 33.2184V17.7753C31.4681 17.3467 31.1394 16.9937 30.7222 16.9937H15.7277ZM0 0.781609V16.2247C0 16.6533 0.328714 17.0063 0.745929 17.0063H15.7404V11.2955H30.7222C31.1267 11.2955 31.4681 10.9425 31.4681 10.5139V0.781609C31.4681 0.352985 31.1394 0 30.7222 0H0.745929C0.328714 0 0 0.352985 0 0.781609Z"
          fill="white"
        />
        <path
          d="M61.3179 0.352985L54.2126 12.4805L47.2464 0.365591C47.12 0.138673 46.8924 0 46.6395 0H40.356H35.4632C34.907 0 34.5656 0.655543 34.869 1.15981L48.0555 22.6919V33.2562C48.0555 33.6722 48.3716 34 48.7635 34H59.7628C60.1548 34 60.4708 33.6596 60.4708 33.2562V22.6919L73.7458 1.17241C74.0493 0.66815 73.7206 0.0126066 73.1516 0.0126066H67.6773H61.9248C61.6846 0 61.457 0.138673 61.3179 0.352985Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="125" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
