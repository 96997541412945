import React from "react";

import UseCaseListItem from "./UseCaseListItem/UseCaseListItem";

import "./UseCaseList.css";

export default function UseCaseList(props) {
  return (
    <div className="w-full max-w-md lg:max-w-none mx-auto flex flex-row items-center justify-center">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
        {props.useCases.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <UseCaseListItem useCase={item} />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
