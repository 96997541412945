import React from "react";

import "./FeaturePost.css";
import FeatureColumns from "./FeatureColumns/FeatureColumns";
import IntegrationsList from "../../integrations/IntegrationsList/IntegrationsList.js";

export default function FeaturePost(props) {
  const useCase = props.feature;

  return (
    <div className="w-full flex-col items-start justify-start">
      <div className="flex flex-col items-center gap-24 py-12 sm:py-24 text-center">
        <div className="flex flex-col">
          <div className="w-full max-w-4xl mx-auto flex flex-col gap-6">
            <h1>{useCase.title}</h1>
            <h3 className="font-normal text-2xl">{useCase.subTitle}</h3>
          </div>
        </div>

        <div
          className="w-full flex justify-center items-center flex-1"
          style={{ maxWidth: "900px" }}
        >
          <img className="w-full" alt="Hero" src={useCase.heroImage} />
        </div>
      </div>
      <div className="flex flex-col gap-16 lg:gap-24 pb-24 lg:pb-32">
        {useCase.content.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item.type === "integrations" && (
                <IntegrationsList integrations={item.integrations} />
              )}
              {item.type === "features" && <FeatureColumns item={item} />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
