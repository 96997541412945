/*
 * This is a list (and associated data) of all the feature pages. By adding,
 * editing or removing a use case from this array, it will update the main
 * /features page, and add (or remove) a /features/:id route.
 *
 * Sample input:
 *
 * {
 *   id: 'hello-world', // This will generate /features/hello-world
 *   title: 'Hello World',
 *   navTitle: 'Hello', // Title to show in nav (nothing long)
 *   previewImage: '', // Icon to represent use case
 *   shortDescription: '', // Short description shown on platform page boxes
 *   content: [ // This is an array of components, all example components are included below
 *     {
 *       type: 'image',
 *       src: '', // Link to image
 *       caption: 'Image Caption: Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
 *     },
 *     {
 *       type: 'text',
 *       content: 'Enter text here',
 *     },
 *     {
 *       type: 'subtitle',
 *       content: 'Enter subtitle here',
 *     },
 *   ],
 * }
 */

import { Integrations } from "../Integrations/IntegrationsList.js";

const useCases = [
  {
    id: "extensible-platform",
    title: "Extensible platform",
    subTitle: "Support the full breadth of your internal stack",
    navTitle: "Extensible platform",
    heroImage: "/assets/use-cases/privileged-access-hero.png",
    shortDescription:
      "Use Sym's SDK to extend your Flows to integrate with all the services in your internal platform stack",
    content: [
      {
        type: "features",
        content: [
          {
            title: "Customize routing and access rules",
            text:
              "Use Sym's SDK primitives (Hooks, Reducers, Prefetch and more) to control who can run which steps in your Flows, and where to route the next step to.",
            image: "/assets/features/slack-route-based.png",
          },
        ],
        colClass: "one-col",
      },
      {
        type: "features",
        content: [
          {
            title: "HTTP for quick integrations",
            text:
              "Hit services on the internet directly from the Sym SDK when you don't need the overhead of managing your own Lambda deployment",
            image: "/assets/sdk/sdk-slide-http.png",
          },
        ],
        colClass: "one-col-right",
      },
      {
        type: "features",
        content: [
          {
            title: "AWS Lambdas for the last mile",
            text:
              'Build "last mile" Lambda functions that manage services in your VPC or 3rd party services that Sym doesn\'t support out of the box. Deploy your Lambda functions right alongside your Sym resources in Terraform.',
            image: "/assets/features/approvals-as-code-sdk.png",
          },
        ],
        colClass: "one-col",
      },
    ],
  },
  {
    id: "slack",
    title: "Customizable Slack app",
    subTitle: "Sym lives where you work – no additional applications required.",
    navTitle: "Customizable Slack app",
    heroImage: "/assets/features/slack-hero.png",
    shortDescription:
      "Sym lives where you work – no additional applications required.",
    content: [
      {
        type: "features",
        content: [
          {
            title: "Don't reinvent the wheel",
            text:
              "We build on strong flow abstractions so that you don’t need to reinvent the wheel each time you’re thinking about a new target access problem.",

            image: "/assets/product-features/product-features-3.png",
          },
        ],
        colClass: "one-col",
      },
      {
        type: "features",
        content: [
          {
            title: "Collect the right context from requesters",
            text:
              "Make workflow routing smart and adaptive by configuring what information users provide in requests.",
            image: "/assets/features/slack-information.png",
          },
        ],
        colClass: "one-col-right",
      },
      {
        type: "features",
        content: [
          {
            title: "No more application switching",
            text:
              "Don’t fumble for more software when you’re in a hurry – make requests from anywhere in Slack.",
            image: "/assets/features/slack-application-switching.png",
          },
        ],
        colClass: "one-col",
      },
    ],
  },
  {
    id: "reports",
    title: "Flexible reporting that fits into your existing stack",
    subTitle:
      "Every action taken with Sym is tracked, logged, and available for export into nearly any reporting destination.",
    navTitle: "Flexible reporting",
    heroImage: "/assets/features/reports-hero.png",
    shortDescription:
      "Every action taken with Sym is tracked, logged, and available for export into nearly any reporting destination.",
    content: [
      {
        type: "features",
        content: [
          {
            title: "Detailed audit logs for compliance",
            text:
              "Sym ensures your access policies are fully customizable & that all of your team's approvals and access escalations are logged.",
            image: "/assets/features/reports-compliance.png",
          },
        ],
        colClass: "one-col",
      },
      {
        type: "talk-to-us",
      },
      {
        type: "features",
        content: [
          {
            title: "Dashboards on your terms",
            text:
              "Instead of creating another dashboard to log into, we stream structured log data to the reporting destination of your choice. Build out the dashboards you need in the tools you already use, or store the audit data in S3 for safekeeping.",
            image: "/assets/features/reports-dashboards.png",
          },
        ],
        colClass: "one-col-right",
      },
      {
        type: "features",
        content: [
          {
            title: "Manage reporting destinations in Terraform",
            text:
              "Declare where you want to send your audit data in Terraform, right along with the rest of your Sym resources.",
            image: "/assets/product-features/product-features-5.png",
          },
        ],
        colClass: "one-col",
      },
    ],
  },
  {
    id: "integrations",
    title: "Integrations for identity, access, routing, and extensibility",
    subTitle:
      "Out of the box integrations (such as Slack, AWS, Okta, and PagerDuty) make it easy to provision just-in-time access flows.",
    navTitle: "Integrations",
    heroImage: "/assets/product-features/product-features-2.png",
    shortDescription:
      "Our out of the box integrations (such as Slack, AWS, Okta, and PagerDuty) make it easy to provision just-in-time access flows for common production scenarios.",
    content: [
      {
        type: "features",
        content: [
          {
            title: "SDK for flexible integration",
            text:
              "Once you have that first flow configured, dive deeper into our SDK to support the parts of your business that don’t fit into cookie-cutter strategies.",
            // image: "/assets/product-features/product-features-1.png",
          },
        ],
        colClass: "one-col",
      },
      {
        type: "integrations",
        integrations: Integrations,
      },
    ],
  },
];

export default useCases;
