import React from "react";
import Helmet from "react-helmet";
import "./Customers.css";

import Layout from "../../components/Layout";
import AnimationContainer from "../../components/AnimationContainer/AnimationContainer";
import BottomCta from "../../components/HomePage/Sections/BottomCta/BottomCta";

export default function Customers() {
  const PAGE_TITLE = "Customers | Sym";

  const customersArray = [
    {
      company: "Courier",
      name: "Seth Carney",
      title: "CTO at Courier",
      text:
        "The fact that it’s a developer tool is a pretty big enabler for us. If you’re thinking about building you probably shouldn’t.",
      logo: "/img/courier-logo.svg",
      caseStudyLink: "https://blog.symops.com/2023/04/25/sym-and-courier/",
    },
    {
      company: "Bryter",
      name: "Joao Martins",
      title: "Bryter",
      text:
        "Symops has been a game-changer for BRYTER, helping us mitigate risks from uncontrolled and open-ended access into AWS. We’ve eliminated bottlenecks and streamlined our workflows while maintaining a clear audit trail.",
      logo: "/img/bryter-logo.svg",
      caseStudyLink: "https://blog.symops.com/2023/05/11/sym-and-bryter/",
    },
    {
      company: "Jellyfish",
      name: "Phil Kelly",
      title: "IT Engineer at Jellyfish",
      text:
        "Sym really helps our engineering teams and our success teams move fast while staying secure.",
      logo: "/img/jellyfish-logo.svg",
      caseStudyLink: "https://blog.symops.com/2023/05/02/sym-and-jellyfish/",
    },
    {
      company: "Orum",
      name: "Justin Hadley",
      title: "Director of Information Security at Orum",
      text:
        "Using Sym to implement just-in-time access for us is great. Not having engineers that have stagnant or over privileged AWS policies assigned within their accounts or different environments 24x7 makes me sleep a little bit better at night.",
      logo: "/img/orum-logo.svg",
      caseStudyLink: "https://blog.symops.com/2022/12/07/orum-and-sym/",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <AnimationContainer animationClass="platform-white-sections-animation">
          <div className="hero-bg-wrapper">
            <div className="w-full max-w-7xl mx-auto px-6 pb-24">
              <div className="py-12 sm:py-24 text-center">
                <div className="flex flex-col gap-6">
                  <h1>Customer use cases</h1>
                  <p className="w-full max-w-3xl mx-auto text-xl">
                    Companies use Sym’s platform to customize the most secure,
                    flexible, and auditable workflows to move fast and stay
                    secure.
                  </p>
                </div>
              </div>

              <div
                className="w-full flex flex-row mb-20 mx-auto"
                style={{ maxWidth: "1100px" }}
              >
                <img
                  src="assets/logos/customers-home-cta/logos-desktop.svg"
                  alt="Customer Logos"
                  className="hidden sm:block"
                />
                <img
                  src="assets/logos/customers-home-cta/logos-mobile.svg"
                  alt="Customer Logos"
                  className="block sm:hidden"
                />
              </div>

              <div className="flex grid grid-cols-1 lg:grid-cols-2 gap-8">
                {customersArray.map((customer, i) => {
                  return (
                    <a
                      href={customer.caseStudyLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={i}
                    >
                      <div className="flex flex-col h-full gap-8 p-8 md:p-12 relative bg-gradient-fade">
                        <div className="w-full flex-shrink-0">
                          <img
                            src={customer.logo}
                            alt={`${customer.company} Logo`}
                            className="max-h-10 filter invert"
                          />
                        </div>
                        <p className="text-lg">"{customer.text}"</p>
                        <div className="mb-8">
                          <p className="text-lg font-bold">{customer.name}</p>
                          <p className="text-md text-sym-brightBlue">
                            {customer.title}
                          </p>
                        </div>
                        <div className="absolute bottom-6 right-6">
                          <div className="cta-button tertiary">
                            Read Case Study
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="hero-bg customers-hero-bg"></div>
          </div>
          <BottomCta id="customers" />
        </AnimationContainer>
      </Layout>
    </>
  );
}
