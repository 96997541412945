import React from "react";
import Helmet from "react-helmet";
import Layout from "../../components/Layout";
import AnimationContainer from "../../components/AnimationContainer/AnimationContainer";
import UseCaseList from "../../components/sections/useCases/UseCaseList/UseCaseList";

import UseCases from "./UseCasesList";

import BottomCta from "../../components/HomePage/Sections/BottomCta/BottomCta";

const PAGE_TITLE = "Use Cases | Sym";

export default function UseCaseHome() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/use-cases" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="hero-bg-wrapper">
          <AnimationContainer animationClass="platform-white-sections-animation">
            <section className="w-full max-w-7xl mx-auto px-6">
              <div className="w-full max-w-5xl mx-auto flex flex-col items-center gap-8 py-12 sm:py-24 text-center">
                <h1>Use Cases</h1>
                <h3 className="text-2xl font-normal">
                  Sym helps you solve security gaps by starting with
                  collaborative review rather than overly-tightened policies.
                  Take your existing AWS permissions and wrap them in a Sym
                  workflow. Then use our SDK to route access requests to peers
                  or to the right approvers based on the context of the request.
                </h3>
              </div>
              <UseCaseList useCases={UseCases} />
            </section>
          </AnimationContainer>
          <div className="hero-bg product-hero-bg"></div>
        </div>
        <BottomCta id="use-case" />
      </Layout>
    </>
  );
}
