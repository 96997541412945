import React, { useState } from "react";
import { PopupModal } from "react-calendly";

export default function CalendlyPopupTrigger(props) {
  const { children, calendlyLink } = props;

  const [calendlyOpen, setCalendlyOpen] = useState(false);

  return (
    <>
      <div onClick={() => setCalendlyOpen(true)} className="inline-block">
        {children}
      </div>
      <PopupModal
        url={calendlyLink}
        // pageSettings={this.props.pageSettings}
        // utm={this.props.utm}
        // prefill={this.props.prefill}
        onModalClose={() => setCalendlyOpen(false)}
        open={calendlyOpen}
        rootElement={document.getElementById("root")}
      />
    </>
  );
}
