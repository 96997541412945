import React from "react";

import "../../sections.css";
import "./Workflow.css";

export default function Workflow() {
  return (
    <div className="section-container compliance-workflow-container px-4 w-full flex flex-wrap items-center justify-center">
      <div className="compliance-workflow-inner">
        <h1>Workflow Diagrams</h1>
        <p>
          Because Sym is fully delivered as code, all of your logic can be
          represented in simple diagrams that describe your exact business
          rules. For every change you make via the Sym SDK, we’ll generate a
          diagram — with diffs — that you can share for approval or for audit.
        </p>
        <img
          src="/assets/compliance/workflow-diagram.png"
          alt="Workflow Diagram"
          className="workflow-diagram"
        />
      </div>
    </div>
  );
}
