import React from "react";

import "./IntegrationPost.css";
import IntegrationOtherPosts from "../IntegrationOtherPosts/IntegrationOtherPosts";
import FeatureBlock from "../../product/ProductPage/FeatureBlock/FeatureBlock.js";

export default function IntegrationPost(props) {
  const integration = props.integration;

  return (
    <div className="">
      <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between gap-16 lg:gap-24 py-16 lg:py-20">
        <div className="w-full max-w-2xl flex flex-col gap-12 lg:gap-16">
          <div className="text-center lg:text-left">
            <h1>{integration.title}</h1>
            <p className="mt-6 text-2xl">{integration.subTitle}</p>
          </div>
          <div className="text-center lg:text-left">
            <h3 className="mb-3">Integration Overview</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: integration.integrationOverview,
              }}
            ></p>
          </div>
        </div>
        <div className="w-full max-w-lg">
          <div className="w-full bg-gradient-fade border border-sym-blue">
            <div className="flex flex-row items-center justify-center gap-16 p-12 border-b border-sym-blue">
              <div className="w-full" style={{ maxWidth: "60px" }}>
                <img
                  src="/assets/logo-icon-white.svg"
                  alt="Sym"
                  className="w-full block"
                />
              </div>
              <div>
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.5977 28.0527L12.5977 0.370117L16.5977 0.370117L16.5977 28.0527L12.5977 28.0527Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.663084 12.2114L28.5293 12.2114V16.2114L0.663084 16.2114V12.2114Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="w-full" style={{ maxWidth: "70px" }}>
                <img
                  src={integration.previewImage}
                  alt={integration.title}
                  className="w-full block"
                />
              </div>
            </div>
            <div className="p-12 pb-28 relative">
              <div className="text-center">
                <h3 className="mb-8 text-2xl lg:text-3xl">Use Cases</h3>
              </div>
              <ul className="flex flex-col gap-4 text-lg lg:text-xl integration-feature-list">
                {integration.useCases.map((useCase, i) => {
                  return (
                    <li key={i} className="flex flex-row items-center gap-5">
                      {useCase}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {integration.features &&
        integration.features.map((feature, i) => {
          return (
            <div className="flex flex-col gap-12 lg:gap-16 py-0 lg:py-16 mb-24 lg:mb-12">
              <FeatureBlock item={feature} />
            </div>
          );
        })}
      <div className="flex flex-col gap-12 lg:gap-16 py-0 lg:py-16 mb-24 lg:mb-12">
        <div className="flex flex-col items-center gap-6">
          <h2>More Integrations</h2>
        </div>
        <IntegrationOtherPosts currentId={props.integration.id} />
      </div>
    </div>
  );
}
