/*
 * This is a list (and associated data) of all the feature pages. By adding,
 * editing or removing a use case from this array, it will update the main
 * /product page, and add (or remove) a /product/:id route.
 *
 * Sample input:
 *
 * {
 *   id: 'hello-world', // This will generate /product/hello-world
 *   title: 'Hello World',
 *   previewImage: '', // Icon to represent use case
 *   shortDescription: '', // Short description shown on platform page boxes
 *   content: [ // This is an array of components, all example components are included below
 *     {
 *       type: 'image',
 *       src: '', // Link to image
 *       caption: 'Image Caption: Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
 *     },
 *     {
 *       type: 'text',
 *       content: 'Enter text here',
 *     },
 *     {
 *       type: 'subtitle',
 *       content: 'Enter subtitle here',
 *     },
 *   ],
 * }
 */

const ProductPages = [
  {
    id: "reduce-access",
    title: "Reduce the default scope of access across your stack",
    subTitle:
      "Manage risk and increase visibility with temporary, context-based access flows.",
    navTitle: "Reduce default access",
    heroScheduleCallButton: true,
    heroImage: "/assets/product/alerts-hero.png",
    content: [
      {
        type: "features-grid",
        content: [
          {
            title: "Automate the ticket queue",
            text:
              "Use Sym's low-code SDK to wire in the business rules you need to automate manual access requests.",
          },
          {
            title: "Make access visible",
            text:
              "Surface access requests and approvals in Slack, Sym's web app, or in your own systems using Sym's Events API.",
          },
          {
            title: "Log to your dashboards",
            text:
              "Stream structured log data to the reporting destination of your choice, or store the audit data in S3 for safekeeping.",
          },
          {
            title: "Connect to internal tools and services",
            text:
              "Write “last mile” AWS Lambdas to manage access to databases, internal tools, and third party services.",
          },
        ],
      },
      {
        type: "feature-block",
        image: "/assets/whitepapers/whitepaper-jit-graphic.svg",
        imageAlt: "Sym AWS JIT access guide",
        imageMaxWidth: 360,
        containerMaxWidth: 1100,
        content: [
          {
            type: "title",
            content: "Read the guide on implementing JIT access in AWS",
          },
          {
            type: "text",
            content:
              "Practical advice on applying least privilege policies to your AWS infrastructure.",
          },
          {
            type: "button",
            text: "Get The Guide",
            link: "/whitepapers/jit-access-guide",
            target: "_self",
          },
        ],
      },
      {
        type: "cta-video",
        title: "Watch the demo: protecting your AWS infrastructure",
        text:
          "Learn how to create a just-in-time workflow for AWS SSO using Sym.",
        videoUrl: "https://vimeo.com/815222490/c717c18c42",
        videoPoster: "assets/demo-video-preview-1.jpg",
      },
      {
        type: "testimonials",
      },
    ],
  },
  {
    id: "compliance",
    title: "Solve your access management compliance headache",
    subTitle:
      "Sym helps you implement least privilege controls for dynamic, cross-functional teams. All with the accountability you need to manage risk.",
    navTitle: "Achieve compliance",
    heroScheduleCallButton: true,
    heroImage: "/assets/product-features-home/feature-reports.svg",
    content: [
      {
        type: "features-grid",
        content: [
          {
            title: "Centralize your access guardrails",
            text:
              "Define the business rules for dynamic access in one place, using infrastructure-as-code for governance and collaboration.",
          },
          {
            title: "Log to your dashboards",
            text:
              "Stream structured log data to the reporting destination of your choice, or store the audit data in S3 for safekeeping.",
          },
        ],
      },
      {
        type: "feature-block",
        image: "/assets/product/audit-pressure-code.png",
        imageAlt: "Audit pressure",
        imageMaxWidth: 548,
        content: [
          {
            type: "title",
            content: "Security your engineers won’t hate to implement",
          },
          {
            type: "text",
            content:
              "Sym helps engineers solve access management problems with the tools and practices they love:",
          },
          {
            type: "list",
            content: [
              "Terraform resources and modules to manage everything as infrastructure-as-code",
              "An SDK to customize workflow logic in Python",
              "Tight integration with AWS services for access, secrets, logging, and more",
            ],
          },
        ],
      },
      {
        type: "feature-columns",
        title: "Detailed logging to help with your next audit",
        content: [
          {
            title: "SOC 2 and ISO 27001",
            text:
              "Access management, a clear audit trail, and Zero Trust help you improve your security and compliance posture." +
              "<br /><br />" +
              "<a href='/security'>Read more about our own approach to security</a>",
            image: "/assets/product/audit-pressure-soc.png",
          },
          {
            title: "Manage reporting destinations in Terraform",
            text:
              "Declare where you want to send your audit data in Terraform, right along with the rest of your Sym resources.",
            image: "/assets/product/audit-pressure-terraform.png",
          },
        ],
      },
    ],
  },
  {
    id: "customer-data",
    title: "Protect customer data",
    subTitle:
      "Create visible, time-bound access to customer data and customer-impacting systems",
    navTitle: "Protect customer data",
    heroScheduleCallButton: true,
    heroImage: "/assets/product/alerts-hero.png",
    content: [
      {
        type: "features-grid",
        content: [
          {
            title: "Connect to your internal customer management tools",
            text:
              "Write “last mile” AWS Lambdas to connect to the internal services that your teams use to manage customer data and resources.",
          },
          {
            title: "Satisfy vendor review requirements",
            text:
              "Give your customers auditable evidence that you protect access to their sensistive data",
          },
          {
            title: "Automate the ticket queue",
            text:
              "Use Sym's low-code SDK to wire in the business rules you need to automate manual access requests.",
          },
          {
            title: "Make access visible",
            text:
              "Surface access requests and approvals in Slack, Sym's web app, or in your own systems using Sym's Events API.",
          },
        ],
      },
      {
        type: "feature-block",
        image: "/assets/product/customer-data-courier-hero.png",
        imageAlt: "Sym x Courier banner",
        imageMaxWidth: 460,
        content: [
          {
            type: "title",
            content:
              "Use case: How Courier enables secure access to customer data",
          },
          {
            type: "text",
            content:
              "Establish granular, least-privilege controls for protecting customer data that are easy for engineering to set up, and even easier to use.",
          },
          {
            type: "button",
            text: "Read the case study",
            link: "https://blog.symops.com/2023/04/25/sym-and-courier/",
          },
        ],
      },
      {
        type: "feature-columns",
        title: "Detailed logging to help with your next SOC 2 Audit",
        content: [
          {
            title: "SOC 2 and ISO 27001",
            text:
              "Access management, a clear audit trail, and Zero Trust help teams improve their security and compliance posture." +
              "<br /><br />" +
              "<a href='/security'>Read more about our own approach to security</a>",
            image: "/assets/product/audit-pressure-soc.png",
          },
          {
            title: "Logging to your dashboards",
            text:
              "Instead of creating another dashboard to log into, we stream structured log data to the reporting destination of your choice. Build out the dashboards you need in the tools you already use, or store the audit data in S3 for safekeeping.",
            image: "/assets/product/audit-pressure-dashboards.png",
          },
        ],
      },
    ],
  },
];

export { ProductPages };
