import React from "react";
import Helmet from "react-helmet";
import { useParams, Redirect } from "react-router-dom";

import Layout from "../../components/Layout";
import AnimationContainer from "../../components/AnimationContainer/AnimationContainer";
import ProductPage from "../../components/sections/product/ProductPage/ProductPage";
import BottomCta from "../../components/HomePage/Sections/BottomCta/BottomCta";

import { ProductPages } from "./ProductList";

export default function Product() {
  // @ts-ignore
  const { id } = useParams();
  const productPage = ProductPages.find((u) => u.id === id);

  // Redirect to homepage if not found
  if (!productPage) {
    return <Redirect to="/" />;
  }

  const PAGE_TITLE = `${productPage.title} | Sym`;

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link
          rel="canonical"
          href={`https://symops.com/product/${productPage.id}`}
        />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="hero-bg-wrapper">
          <AnimationContainer animationClass="platform-white-sections-animation">
            <ProductPage productPage={productPage} />
          </AnimationContainer>
          <div className="hero-bg product-hero-bg"></div>
        </div>
        <BottomCta id="product" demoButton={true} />
      </Layout>
    </>
  );
}
