import React, { useCallback, useState } from "react";

import "./FeatureBlock.css";
import { submitMailchimpForm } from "../../../../../util.js";
import { Transition } from "@headlessui/react";

export default function FeatureBlock(props) {
  const { item } = props;

  const [submitted, setSubmitted] = useState(false);
  const [displayForm, setDisplayForm] = useState(true);
  const [email, setEmail] = useState("");

  const transitionDuration = 500; // Fade transition duration (must be a valid Tailwind duration value)

  const handleNewsletterSubmit = useCallback(
    (mailchimpFormId) => (event) => {
      event.preventDefault();

      if (!mailchimpFormId) {
        return;
      }

      const data = {
        EMAIL: email,
      };

      const onSuccess = () => {
        setDisplayForm(false);
        setSubmitted(true);
      };

      const onFailure = (message) => {
        console.error(message);
        alert("There was a problem submitting the form, please try again.");
      };

      submitMailchimpForm(mailchimpFormId, data, onSuccess, onFailure);
    },
    [email]
  );

  return (
    <div className="bg-gradient-fade p-8 lg:p-16">
      <div
        className="w-full flex flex-col lg:flex-row items-center justify-between gap-8 lg:gap-16"
        style={{
          maxWidth: item.containerMaxWidth
            ? `${item.containerMaxWidth}px`
            : "1300px",
        }}
      >
        <div className="flex flex-col gap-6 text-center lg:text-left">
          {item.content.map((blockItem, i) => {
            return (
              <React.Fragment key={i}>
                {blockItem.type === "title" && (
                  <h2 className="w-full" style={{ maxWidth: "520px" }}>
                    {blockItem.content}
                  </h2>
                )}
                {blockItem.type === "text" && (
                  <p
                    dangerouslySetInnerHTML={{ __html: blockItem.content }}
                    className="w-full text-xl"
                    style={{ maxWidth: "520px" }}
                  ></p>
                )}
                {blockItem.type === "button" && (
                  <div>
                    <a
                      href={blockItem.link}
                      target={blockItem.target ?? "_blank"}
                      rel="noreferrer"
                      className="cta-button"
                    >
                      {blockItem.text}
                    </a>
                  </div>
                )}
                {blockItem.type === "list" && (
                  <ul className="list-disc pl-6 flex flex-col gap-2 text-xl">
                    {blockItem.content.map((listItem, j) => {
                      return (
                        <li
                          key={j}
                          dangerouslySetInnerHTML={{ __html: listItem }}
                        ></li>
                      );
                    })}
                  </ul>
                )}
                {blockItem.type === "newsletter-signup" && (
                  <div className="mt-2">
                    {submitted && (
                      <Transition
                        appear={true}
                        show={true}
                        enter={"transition-all duration-" + transitionDuration}
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave={"transition-all duration-" + transitionDuration}
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <p className="flex w-full transition-all font-semibold text-green-500">
                          You're on your way to quicker engineering and better
                          security! Look out for our guide in your inbox.
                        </p>
                      </Transition>
                    )}
                    {!submitted && (
                      <Transition
                        appear={true}
                        show={displayForm}
                        enter={"transition-all duration-" + transitionDuration}
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave={"transition-all duration-" + transitionDuration}
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="flex w-full"
                      >
                        <div
                          className="flex w-full transition-all"
                          style={{ width: "100%", maxWidth: "500px" }}
                        >
                          <form
                            onSubmit={handleNewsletterSubmit(
                              blockItem.mailchimpFormId
                            )}
                            className="flex w-full"
                          >
                            <input
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              type="email"
                              id="email"
                              name="EMAIL"
                              className="border-b border-white bg-transparent md:text-base text-sm py-3 w-full focus:outline-none"
                              placeholder="Enter your email"
                              pattern="^.+@.+\..+$"
                              required
                            />
                            <button type="submit" className="cta-button">
                              Get The Guide
                            </button>
                          </form>
                        </div>
                      </Transition>
                    )}
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
        {item.image && (
          <div
            className="side-image w-full"
            style={{
              maxWidth: item.imageMaxWidth ? `${item.imageMaxWidth}px` : "100%",
            }}
          >
            <img
              src={item.image}
              alt={item.imageAlt}
              className="block w-full"
            />
          </div>
        )}
      </div>
    </div>
  );
}
