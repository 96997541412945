import React from "react";

import "./FeatureGrid.css";

export default function FeatureGrid(props) {
  const { item } = props;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      {item.content.map((feature, i) => {
        return (
          <article
            key={i}
            className="flex flex-col justify-start gap-6 p-8 lg:p-12 bg-gradient-fade"
          >
            {feature.image && <img src={feature.image} alt={feature.title} />}
            <div>
              <h3 className="mb-4 font-normal text-2xl">{feature.title}</h3>
              <p
                dangerouslySetInnerHTML={{ __html: feature.text }}
                className="opacity-90"
              ></p>
            </div>
          </article>
        );
      })}
    </div>
  );
}
