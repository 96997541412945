import React from "react";
import Helmet from "react-helmet";
import { useParams, Redirect } from "react-router-dom";

import Layout from "../../components/Layout";
import AnimationContainer from "../../components/AnimationContainer/AnimationContainer";
import IntegrationPost from "../../components/sections/integrations/IntegrationPost/IntegrationPost";

import { Integrations } from "./IntegrationsList";

export default function IntegrationPage() {
  const { id } = useParams();
  const integration = Integrations.find((u) => u.id === id);

  // Redirect to use cases page if not found
  if (!integration) {
    return <Redirect to="/integrations" />;
  }

  const PAGE_TITLE = `${integration.title} Integration | Sym`;

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link
          rel="canonical"
          href={`https://symops.com/integrations/${integration.id}`}
        />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <React.Fragment>
        <Layout>
          <div className="hero-bg-wrapper">
            <AnimationContainer animationClass="platform-white-sections-animation">
              <div className="w-full max-w-7xl mx-auto px-6">
                <IntegrationPost integration={integration} />
              </div>
            </AnimationContainer>
            <div className="hero-bg integration-hero-bg"></div>
          </div>
        </Layout>
      </React.Fragment>
    </>
  );
}
