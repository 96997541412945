import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import SecurityCover from "../components/sections/security/SecurityCover/SecurityCover";
import SecurityParagraphs from "../components/sections/security/SecurityParagraphs/SecurityParagraphs";
import BottomCta from "../components/HomePage/Sections/BottomCta/BottomCta";
import SecurityDisclosure from "../components/sections/security/SecurityDisclosure/SecurityDisclosure";

const PAGE_TITLE = "Security | Sym";

export default function Security() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/security" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="hero-bg-wrapper">
          <div className="w-full max-w-7xl mx-auto px-6">
            <SecurityCover />
            <SecurityParagraphs />
            <div className="mt-16">
              <SecurityDisclosure />
            </div>
          </div>
          <div className="hero-bg security-hero-bg"></div>
        </div>
        <BottomCta id="security" />
      </Layout>
    </>
  );
}
