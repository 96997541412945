import React, { useState } from "react";

import "./TestimonialsBlock.css";
import AnimationContainer from "../../AnimationContainer/AnimationContainer";

export default function TestimonialSection(params) {
  const [testimonialIndex, setTestimonialIndex] = useState(0);

  const testimonialsArray = [
    {
      name: "Joao Martins",
      title: "Product Security Manager",
      text:
        "Symops has been a game-changer for BRYTER, helping us mitigate risks from uncontrolled and open-ended access into AWS. We’ve eliminated bottlenecks and streamlined our workflows while maintaining a clear audit trail.",
      img: "img/bryter-logo.svg",
      pic: "assets/people/joao.jpeg",
      caseStudyLink: "https://blog.symops.com/2023/05/11/sym-and-bryter/",
      company: "Bryter",
    },
    {
      name: "Troy Goode",
      title: "CEO",
      text:
        "Sym has made our security policies more provable, which makes attesting to our compliance goals easy. Most security tools slow you down, but Sym speeds you up.",
      img: "img/courier-logo.svg",
      pic: "assets/people/edwards.jpg",
      caseStudyLink: "https://blog.symops.com/2023/04/25/sym-and-courier/",
      company: "Courier",
    },
    {
      name: "Justin Hadley",
      title: "Director of Information Security",
      text:
        "Using Sym to implement just-in-time access for us is great. Not having engineers that have stagnant or over privileged AWS policies assigned within their accounts or different environments 24x7 makes me sleep a little bit better at night.",
      img: "img/orum-logo.svg",
      pic: "assets/people/justin.jpeg",
      caseStudyLink: "https://blog.symops.com/2022/12/07/orum-and-sym/",
      company: "Orum",
    },
    {
      name: "Phil Kelly",
      title: "Head of IT",
      text:
        "Sym really helps our engineering teams and our success teams move fast while staying secure.",
      img: "img/jellyfish-logo.svg",
      pic: "assets/people/phil.jpeg",
      caseStudyLink: "https://blog.symops.com/2023/05/02/sym-and-jellyfish/",
      company: "Jellyfish",
    },
  ];

  const adjustTestimonialIndex = (input) => {
    const maxIndex = testimonialsArray.length - 1;
    const output = testimonialIndex + input;

    if (output > maxIndex) {
      setTestimonialIndex(0);
      return;
    }
    if (output < 0) {
      setTestimonialIndex(maxIndex);
      return;
    }
    setTestimonialIndex(output);
  };

  return (
    <AnimationContainer animationClass="testimonials-block-animation">
      <div className="testimonials-block w-full bg-sym-dark">
        <div className="py-12 sm:py-20">
          <div className="w-full max-w-6xl mx-auto flex flex-row items-center justify-between gap-8 lg:gap-24 px-8">
            <button
              onClick={() => adjustTestimonialIndex(-1)}
              className="w-8 h-8 inline-flex items-center justify-center flex-shrink-0 bg-none outline-none"
            >
              <svg
                width="12"
                height="25"
                viewBox="0 0 12 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7329 0.294193C11.9041 0.486526 12 0.744718 12 1.01358C12 1.28243 11.9041 1.54062 11.7329 1.73296L2.16548 12.5L11.7329 23.2649C11.9041 23.4572 12 23.7154 12 23.9843C12 24.2531 11.9041 24.5113 11.7329 24.7037C11.6496 24.7974 11.5501 24.872 11.4402 24.9229C11.3303 24.9738 11.2121 25 11.0928 25C10.9735 25 10.8554 24.9738 10.7455 24.9229C10.6355 24.872 10.536 24.7974 10.4528 24.7037L0.2787 13.2517C0.100008 13.0506 0 12.7808 0 12.5C0 12.2192 0.100008 11.9494 0.2787 11.7483L10.4528 0.296347C10.536 0.202562 10.6355 0.12802 10.7455 0.0771213C10.8554 0.026223 10.9735 0 11.0928 0C11.2121 0 11.3303 0.026223 11.4402 0.0771213C11.5501 0.12802 11.6496 0.202562 11.7329 0.296347V0.294193Z"
                  fill="white"
                />
              </svg>
            </button>
            <div className="w-full max-w-5xl">
              {testimonialsArray.map((testimonial, i) => {
                return (
                  <div
                    key={i}
                    className={`testimonial-fade flex flex-col items-center gap-8 ${
                      testimonialIndex === i ? "flex" : "hidden"
                    }`}
                  >
                    <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-8">
                      <div
                        className="inline-block flex-shrink-0 w-24 lg:w-32 h-24 lg:h-32 bg-cover bg-center rounded-full"
                        style={{ backgroundImage: `url(${testimonial.pic})` }}
                      ></div>
                      <div className="text-center sm:text-left">
                        <p className="text-md text-sym-purple">
                          {testimonial.company}
                        </p>
                        <p className="text-2xl font-bold">{testimonial.name}</p>
                        <p className="text-md opacity-70">
                          {testimonial.title}
                        </p>
                      </div>
                    </div>
                    <div className="w-full flex flex-col items-cente text-center">
                      <p className="text-base md:text-xl lg:text-2xl">
                        {testimonial.text}
                      </p>
                      {testimonial.caseStudyLink ? (
                        <div className="mt-4">
                          <a
                            href={testimonial.caseStudyLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-block mt-4 cta-button tertiary"
                          >
                            Read case study
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
            <button
              onClick={() => adjustTestimonialIndex(1)}
              className="w-8 h-8 inline-flex items-center justify-center flex-shrink-0 bg-none outline-none"
            >
              <svg
                width="12"
                height="25"
                viewBox="0 0 12 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.26712 24.7058C0.0958717 24.5135 -6.51053e-08 24.2553 -8.86096e-08 23.9864C-1.12114e-07 23.7176 0.0958716 23.4594 0.26712 23.267L9.83452 12.5L0.267118 1.73511C0.0958697 1.54278 -2.07327e-06 1.28459 -2.09677e-06 1.01573C-2.12028e-06 0.746872 0.0958696 0.488678 0.267118 0.296345C0.350356 0.20256 0.449877 0.128017 0.559808 0.0771198C0.669738 0.0262203 0.787849 -6.88761e-08 0.907169 -7.93074e-08C1.02649 -8.97387e-08 1.1446 0.0262202 1.25453 0.0771197C1.36446 0.128017 1.46398 0.20256 1.54722 0.296345L11.7213 11.7483C11.9 11.9494 12 12.2192 12 12.5C12 12.7808 11.9 13.0506 11.7213 13.2517L1.54722 24.7037C1.46399 24.7974 1.36446 24.872 1.25453 24.9229C1.1446 24.9738 1.02649 25 0.907171 25C0.787851 25 0.66974 24.9738 0.55981 24.9229C0.44988 24.872 0.350358 24.7974 0.26712 24.7037L0.26712 24.7058Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </AnimationContainer>
  );
}
