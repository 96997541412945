import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../../components/Layout";
import styles from "../Legal.module.css";

const PAGE_TITLE = "Responsible Disclosure Policy | Sym";

export default function ResponsibleDisclosure() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/security/disclosure" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>

      <Layout>
        <div
          className={`${styles.legalPage} " flex flex-col w-full justify-center px-4 md:px-8 lg:px-8 xl:px-16 2xl:px-40 relative`}
        >
          <br />
          <br />
          <h1>SYM RESPONSIBLE DISCLOSURE POLICY</h1>
          <br />

          <p>Last updated: January 25, 2023</p>
          <br />
          <br />

          <h3>Introduction</h3>
          <br />
          <p>
            Symops Inc. welcomes feedback from security researchers and the
            general public to help improve our security. If you believe you have
            discovered a vulnerability, privacy issue, exposed data, or other
            security issues in any of our assets, we want to hear from you. This
            policy outlines steps for reporting vulnerabilities to us, what we
            expect, what you can expect from us.
          </p>

          <br />
          <br />

          <h3>Systems in Scope</h3>
          <br />
          <p>
            This policy applies to any digital assets owned, operated, or
            maintained by Symops Inc. including:
            <br />
            <br />
            <ul>
              <li>
                <a href="https://symops.com/">https://symops.com/</a>
              </li>
              <li>
                <a href="https://api.symops.com">https://api.symops.com/</a>
              </li>
              <li>
                <a href="https://docs.symops.com/docs/install-sym-flow-cli">
                  symflow cli
                </a>
              </li>
              <li>
                <a href="https://slack.com/apps/A01LXNQCKN1-sym?tab=more_info">
                  Sym Slack app
                </a>
              </li>
              <li>
                <a href="https://github.com/symopsio/terraform-provider-sym">
                  Sym Terraform provider
                </a>
              </li>
            </ul>
          </p>

          <br />
          <br />

          <h3>Out of Scope</h3>
          <br />
          <p>
            <ul>
              <li>Use of automated scanning tools.</li>
              <li>Intentional denial of service attacks.</li>
              <li>
                Social engineering, phishing, or other attacks involving
                employees of Sym or our vendors.
              </li>
              <li>
                Assets, systems, or other equipment not created/owned by Sym.
              </li>
            </ul>
            Vulnerabilities discovered or suspected in out-of-scope systems
            should be reported to the appropriate vendor or applicable
            authority.
          </p>

          <br />
          <br />

          <h3>Our Commitments</h3>
          <br />
          <p>
            When working with us, according to this policy, you can expect us
            to:
            <br />
            <br />
            <ul>
              <li>
                Respond to your report promptly, and work with you to understand
                and validate your report.
              </li>
              <li>
                Strive to keep you informed about the progress of a
                vulnerability as it is processed.
              </li>
              <li>
                Work to remediate discovered vulnerabilities in a timely manner,
                within our operational constraints.
              </li>
              <li>
                Extend Safe Harbor for your vulnerability research that is
                related to this policy.
              </li>
            </ul>
          </p>

          <br />
          <br />

          <h3>Our Expectations</h3>
          <br />
          <p>
            In participating in our vulnerability disclosure program in good
            faith, we ask that you:
            <br />
            <br />
            <ul>
              <li>
                Play by the rules, including following this policy and any other
                relevant agreements. If there is any inconsistency between this
                policy and any other applicable terms, the terms of this policy
                will prevail.
              </li>
              <li>Report any vulnerability you’ve discovered promptly.</li>
              <li>
                Avoid violating the privacy of others, disrupting our systems,
                destroying data, and/or harming user experience.
              </li>
              <li>
                Use only the Official Channels to discuss vulnerability
                information with us.
              </li>
              <li>
                Provide us a reasonable amount of time (at least 90 days from
                the initial report) to resolve the issue before you disclose it
                publicly.
              </li>
              <li>
                Perform testing only on in-scope systems, and respect systems
                and activities which are out-of-scope.
              </li>
              <li>
                If a vulnerability provides unintended access to data: Limit the
                amount of data you access to the minimum required for
                effectively demonstrating a Proof of Concept; and cease testing
                and submit a report immediately if you encounter any user data
                during testing, such as Personally Identifiable Information
                (PII) or proprietary data.
              </li>
              <li>
                Only interact with test accounts you own or with explicit
                permission from the account holder.
              </li>
              <li>Do not engage in extortion.</li>
            </ul>
          </p>

          <br />
          <br />

          <h3>Official Channels</h3>
          <br />
          <p>
            Please report security issues via{" "}
            <a href="mailto:security@symops.io" style={{ color: "#3377F3" }}>
              {" "}
              security@symops.io
            </a>
            , providing all relevant information. The more details you are able
            to provide, the easier it will be for us to triage and fix the
            issue.
          </p>

          <br />
          <br />

          <h3>Safe Harbor</h3>
          <br />
          <p>
            We consider any research activities conducted in a manner consistent
            with this policy to be:
            <br />
            <br />
            <ul>
              <li>
                Authorized concerning any applicable anti-hacking laws, and we
                will not initiate or support legal action against you for
                accidental, good-faith violations of this policy.
              </li>
              <li>
                Authorized concerning any relevant anti-circumvention laws, and
                we will not bring a claim against you for circumvention of
                technology controls.
              </li>
              <li>
                Exempt from restrictions in our Terms of Service (TOS) and/or
                Acceptable Usage Policy (AUP) that would interfere with
                conducting security research, and we waive those restrictions on
                a limited basis for purposes of this research.
              </li>
              <li>
                Lawful, helpful to the overall security of the Internet and of
                Sym specifically, and conducted in good faith.
              </li>
            </ul>
          </p>

          <br />

          <p>
            You are expected, as always, to comply with all applicable laws. If
            legal action is initiated by a third party against you and you have
            complied with this policy, we will take steps to make it known that
            your actions were conducted in compliance with this policy.
          </p>

          <br />

          <p>
            If at any time you have concerns or are uncertain whether your
            security research is consistent with this policy, please submit a
            report through one of our Official Channels before going any
            further.
          </p>

          <br />

          <p>
            Note that the Safe Harbor applies only to legal claims under the
            control of Symops, Inc., and that the policy does not bind
            independent third parties.
          </p>

          <br />
          <br />
          <br />
        </div>
      </Layout>
    </>
  );
}
