import React from "react";

import "./UseCasePost.css";
import UseCaseOtherPosts from "./UseCaseOtherPosts/UseCaseOtherPosts";
import UseCasePostImage from "./UseCasePostImage/UseCasePostImage";
import UseCasePostText from "./UseCasePostText/UseCasePostText";
import UseCasePostSubtitle from "./UseCasePostSubtitle/UseCasePostSubtitle";
import UseCasePostSlideshow from "./UseCasePostSlideshow/UseCasePostSlideshow";
import UseCasePostIntegrations from "./UseCasePostIntegrations/UseCasePostIntegrations";
import SalesForm from "../../../Common/SalesForm/SalesForm";
import UseCases from "../../../../pages/UseCases/UseCasesList";

export default function UseCaseList(props) {
  const useCase = props.useCase;
  const useCaseIndex = UseCases.findIndex((u) => u.id === props.useCase.id);

  return (
    <div className="pb-24 lg:pb-32">
      <div className="flex flex-col mb-24 lg:mb-32">
        <div className="w-full max-w-5xl mx-auto flex flex-col items-center gap-8 py-12 sm:py-24 text-center">
          <h1>{useCase.title}</h1>
          <h3 className="text-2xl font-normal">{useCase.subTitle}</h3>
        </div>

        <div
          className="w-full flex justify-center items-center flex-1 mx-auto"
          style={{ maxWidth: "900px" }}
        >
          <img className="h-auto" alt="Hero" src={useCase.heroImage} />
        </div>
      </div>
      <div className="flex flex-col gap-24">
        {useCase.content.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item.type === "image" && (
                <div>
                  <UseCasePostImage item={item} />
                </div>
              )}
              {item.type === "text" && (
                <div>
                  <UseCasePostText item={item} />
                </div>
              )}
              {item.type === "subtitle" && (
                <div>
                  <UseCasePostSubtitle item={item} />
                </div>
              )}
              {item.type === "slideshow" && (
                <div>
                  <UseCasePostSlideshow item={item} />
                </div>
              )}
              {item.type === "salesForm" && (
                <div>
                  <SalesForm mailchimpUseCaseTag={item.mailchimpUseCaseTag} />
                </div>
              )}
              {item.type === "integrations" && (
                <div>
                  <UseCasePostIntegrations item={item} />
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div className="mt-24">
        <UseCaseOtherPosts index={useCaseIndex} />
      </div>
    </div>
  );
}
