import React from "react";

import "./CtaVideoBlock.css";
import HeroVideo from "../../../../HomePage/Sections/HeroVideo/HeroVideo.js";

export default function CtaVideoBlock(props) {
  const { item } = props;

  return (
    <div
      className={`flex ${
        item.titleOnly ? "flex-col" : "flex-col-reverse lg:flex-row"
      } items-center justify-between gap-12 text-center lg:text-center`}
    >
      {item.titleOnly && <h2>{item.title}</h2>}
      <div className="w-full" style={{ maxWidth: "640px" }}>
        <HeroVideo
          videoUrl={item.videoUrl}
          videoPreview={item.videoPreview}
          videoPoster={item.videoPoster}
        />
      </div>
      {!item.titleOnly && (
        <div className="w-full max-w-lg flex flex-col gap-6">
          <div>
            <h2>{item.title}</h2>
          </div>
          <p className="text-xl">{item.text}</p>
        </div>
      )}
    </div>
  );
}
