import jsonp from "jsonp";

const JSONP_TIMEOUT = 10000;

export const preloadImages = (images) => {
  images.forEach((img) => {
    new Image().src = img;
  });
};

export const objectToQs = (obj) =>
  Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join("&");

export const submitMailchimpForm = (formId, params, onSuccess, onFailure) => {
  const utmParams = {};
  const utmParamsWhitelist = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];
  utmParamsWhitelist.forEach((param) => {
    const item = localStorage.getItem(param);
    if (item) {
      utmParams[`U${param.split("_")[1].toUpperCase()}`] = item;
    }
  });

  const qs = objectToQs({
    u: "bf4a75808d9732c76ea6ef0be",
    id: formId,
    ...utmParams,
    ...params,
  });
  const url = `https://symops.us4.list-manage.com/subscribe/post-json?${qs}`;

  jsonp(url, { param: "c", timeout: JSONP_TIMEOUT }, (err, data) => {
    if (err) {
      onFailure(err.toString());
    } else if (data.result !== "success") {
      if (data.msg.includes("already subscribed")) {
        onSuccess();
      } else {
        onFailure(data.msg);
      }
    } else {
      onSuccess();
    }
  });
};
