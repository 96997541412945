import React from "react";

import "./ProductFeaturesSection.css";
import AnimationContainer from "../../../AnimationContainer/AnimationContainer";

export default function ProductFeaturesSection() {
  const stepsArray = [
    {
      title: "Centralized reports for any audit",
      desc:
        "Every action taken with Sym is tracked, logged, and available for export. Whether you’re gathering data for SOC2 or peace of mind, we expose every step of every workflow for easy consumption.",
      img: "assets/product-features-home/feature-reports.svg",
    },
    {
      title: "Intelligent, context-based access",
      desc:
        "Automatically grant or revoke access to your production environment to help maintain Zero Trust.",
      img: "assets/product/alerts-hero.png",
    },
    {
      title: "Deep partnerships and integrations",
      desc:
        "Use Sym’s SDK to customize your Flows and integrate with the services in your internal platform stack.",
      img: "assets/product-features-home/feature-integrations.svg",
    },
  ];
  return (
    <AnimationContainer animationClass="product-features-section-animation">
      <section className="product-features-section-wrapper w-full justify-center flex">
        <div className="w-full md:max-w-7xl flex flex-col">
          <div className="flex flex-col w-full px-4">
            <div className="mb-16 text-center">
              <h2>Simplify access management</h2>
            </div>

            <div className="product-features-items">
              {stepsArray.map((step, i) => {
                return (
                  <div key={i} className="relative product-features-item">
                    <div className="product-features-item-inner">
                      <div className="w-full flex relative product-features-image-container">
                        <img
                          className="product-features-image"
                          src={step.img}
                          alt=""
                        />
                      </div>
                      <div className="flex flex-col mt-4 product-features-text-content">
                        <div
                          className="product-features-title font-medium md:text-lg text-sm tracking-normal"
                          style={{ fontWeight: 600 }}
                        >
                          {step.title}
                        </div>
                        <div className="product-features-desc opacity-80">
                          {step.desc}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </AnimationContainer>
  );
}
