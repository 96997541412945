import React from "react";

import "../../sections.css";
import "./Logging.css";

export default function Logging() {
  return (
    <div className="section-container compliance-logging-container px-4 w-full flex flex-wrap items-center justify-center">
      <div className="compliance-logging-inner">
        <h1>Logging and Reporting</h1>
        <p>
          Whether you're piping logs into your monitoring tool of choice as part
          of your ongoing diligence, or preparing for your next annual audit,
          Sym's reporting tools are a good friend to have in your corner.
          <br />
          <br />
          Easily pipe logs to destinations like DataDog, NewRelic, S3, or any
          destination that supports ingestion from Kinesis Firehose.
        </p>
        <img
          src="/assets/compliance/logging-diagram.png"
          alt="Logging Diagram"
          className="logging-diagram"
        />
      </div>
    </div>
  );
}
