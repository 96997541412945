import Helmet from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import CodeBlock from "../../components/Common/CodeBlock/CodeBlock";
import React from "react";

const PAGE_TITLE = "Authorization Success | Sym";

export default function AuthorizationSuccess() {
  const { integration } = useParams();
  // Get the query params as one long string
  const queryString = useLocation().search;
  // Parse the query params into an object
  const queryParams = new URLSearchParams(queryString);

  const isVanta = integration === "vanta";
  const isDatadog = integration === "datadog";
  const datadogSite = queryParams.get("datadog_site") || "";

  const capitalizedIntegration =
    integration.toString()[0].toUpperCase() +
    integration.toString().substring(1);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link
          rel="canonical"
          href={"https://symops.com/authorization/success/" + integration}
        />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>

      <div className="hero-bg-wrapper h-screen">
        <div className="mx-16 md:mx-48 max-w-full">
          <div className="flex flex-col my-20 px-8 py-10 bg-gradient-fade text-center">
            <h3 className="my-10 text-sm sm:text-3xl">
              Congratulations! &#127881;
            </h3>
            <h3>Sym and {capitalizedIntegration} are now connected.</h3>
            <div className="my-7">
              To finish setting up the integration, add the following code to
              your Terraform configuration and run{" "}
              <code className="text-sm">terraform apply</code>:
            </div>
            {isVanta && (
              <div className="mx-auto max-w-full text-left">
                <CodeBlock>
                  {`resource "sym_integration" "vanta" {
  type = "vanta"
  name = "\${local.environment}-vanta"

  # Fill in your company's name in Vanta here. It is located as the last item of the sidebar in app.vanta.com.
  external_id = "CHANGEME"
}`}
                </CodeBlock>
              </div>
            )}

            {isDatadog && (
              <div className="mx-auto max-w-full text-left">
                <CodeBlock>
                  {`resource "sym_log_destination" "datadog" {
  type = "datadog"
  
  settings = {
    datadog_site = "${datadogSite}"
    
    stream_name = "\${local.environment}-datadog"
   }
}`}
                </CodeBlock>
              </div>
            )}
          </div>
        </div>
        <div className="hero-bg login-bg"></div>
      </div>
    </>
  );
}
