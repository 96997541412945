import React from "react";
import Right from "../../Icons/Right";

export default function NextArrow({ onClick }) {
  return (
    <div
      onClick={onClick}
      className="hidden lg:flex border border-black bg-white rounded-full w-16 h-16 justify-center items-center absolute bottom-1/3 right-0 mr-24 cursor-pointer z-50"
    >
      <div className="w-6">
        <Right />
      </div>
    </div>
  );
}
