import Helmet from "react-helmet";
import "./SelfServiceSignUpSuccess.css";
import Layout from "../../components/Layout";
import { useHistory, useLocation } from "react-router-dom";
import SetupInstructions from "./SetupInstructions";
import React, { useEffect } from "react";

const PAGE_TITLE = "Successful Sign Up! | Sym";
const ALLOWED_ROUTE_PLANS = ["free", "standard"];

export default function SelfServiceSignUpSuccess() {
  const location = useLocation();
  const history = useHistory();
  const routePlan = location.pathname.split("/")[2];

  const queryString = useLocation().search; // Get the query params as one long string
  const queryParams = new URLSearchParams(queryString); // Parse the query params into an object

  const emailIsVerified = queryParams.get("email_verified") === "True";
  const orgId = queryParams.get("org_id");
  // The user_email query param is base64-encoded. Decode it to get the plain string.
  const email = atob(queryParams.get("user_email") || "");

  useEffect(() => {
    if (routePlan && !ALLOWED_ROUTE_PLANS.includes(routePlan)) {
      // Redirect if invalid plan name back to main signup URL
      return history.push("/signup/success");
    }
  }, [history, routePlan]);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel="canonical" href="https://symops.com/signup/success" />
        <meta
          content={PAGE_TITLE}
          property="og:title"
          data-react-helmet="true"
        />
        <meta
          content={PAGE_TITLE}
          property="twitter:title"
          data-react-helmet="true"
        />
      </Helmet>
      <Layout>
        <div className="hero-bg-wrapper">
          <div className="w-full max-w-6xl flex flex-col mx-auto px-6 py-0 lg:py-12 gap-24">
            <div className="flex flex-col mx-auto self-center w-full max-w-6xl px-5 md:px-12 lg:px-20 py-16 my-14 rounded-lg shadow-md text-center bg-gradient-fade">
              <h2>Congratulations!</h2>
              <h4 className="mt-5">Your Sym organization has been created</h4>

              <div
                className={`bg-green-700 bg-opacity-20 lg:mx-20 p-6 mt-10 rounded ${
                  orgId ? "lg:px-40" : "lg:px-20"
                }`}
              >
                {orgId && (
                  <div>
                    <h4 className="md:px-14 text-2xl">
                      Your Organization ID is
                    </h4>
                    <h4 className="font-courier font-bold text-2xl">{orgId}</h4>

                    <p className="mt-4">
                      We’ve also sent your Organization ID and a link to these
                      instructions to <b>{email}</b>.
                    </p>
                  </div>
                )}

                {!orgId && (
                  <div>
                    <p>
                      If you’ve signed up for Sym, your Organization ID and a
                      link to this page will be in the inbox of the email
                      address you used to sign up.
                    </p>
                    <p className="mt-4">
                      If you haven’t signed up for Sym,{" "}
                      <a href="/signup">please start by signing up here.</a>
                    </p>
                  </div>
                )}
              </div>

              {!emailIsVerified && (
                <div className="bg-yellow-400 bg-opacity-10 px-3 lg:mx-20 p-6 mt-6 rounded">
                  We have sent an email with a confirmation link to your email
                  address.
                  <div className="mt-4 md:mt-0">
                    Please verify your email before continuing.
                  </div>
                </div>
              )}

              <div className="mt-10 lg:px-20">
                <SetupInstructions />
              </div>
            </div>
          </div>
          <div className="hero-bg login-bg"></div>
        </div>
      </Layout>
    </>
  );
}
