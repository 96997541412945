import React, { useCallback, useState } from "react";

import "./SalesForm.css";
import { submitMailchimpForm } from "../../../util";

export default function SalesForm(props) {
  const { boxed, mailchimpUseCaseTag } = props;

  const [invalidEmailSubmitted, setInvalidEmailSubmitted] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const [fakeEmailSubmitted, setFakeEmailSubmitted] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const clearAll = () => {
    setName("");
    setEmail("");
  };

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setGenericError(false);
      setFakeEmailSubmitted(false);
      setInvalidEmailSubmitted(false);

      const data = {
        NAME: name,
        EMAIL: email,
      };

      if (mailchimpUseCaseTag && mailchimpUseCaseTag.length > 0) {
        data.USECASE = mailchimpUseCaseTag;
      }

      const onSuccess = () => {
        clearAll();
        setSubmitted(true);
      };
      const onFailure = (message) => {
        console.error(message);

        if (
          message ===
          "This email cannot be added to this list. Please enter a different email address."
        ) {
          setFakeEmailSubmitted(true);
        } else if (
          message.includes("is an invalid email address and cannot be imported")
        ) {
          setInvalidEmailSubmitted(true);
        } else {
          setGenericError(true);
        }
      };
      submitMailchimpForm("49016633c3", data, onSuccess, onFailure);
    },
    [email, name, mailchimpUseCaseTag]
  );

  return (
    <div
      className={`sales-form justify-center flex px-4 md:px-8 lg:px-8 xl:px-16 2xl:px-40 ${
        boxed ? "is-boxed" : "w-full"
      }`}
    >
      <div className="sales-form-wrapper">
        <h2>Try Sym</h2>
        {!submitted && (
          <div>
            <p>Sign up to get started</p>
            <form onSubmit={handleSubmit}>
              <div className="email-form">
                <div className="input-split">
                  <div className="input-wrapper">
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      id="name"
                      name="NAME"
                      placeholder="Name"
                      className="text-input"
                      required
                    />
                  </div>
                  <div className="input-wrapper">
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      id="email"
                      name="EMAIL"
                      placeholder="Work Email"
                      pattern="^.+@.+\..+$"
                      className="text-input"
                      required
                    />
                  </div>
                </div>

                {fakeEmailSubmitted && (
                  <p className={"error-message"}>
                    Are you sure that email is right?
                  </p>
                )}
                {invalidEmailSubmitted && (
                  <p className={"error-message"}>
                    That email appears invalid. Please try a different email or
                    reach out to sales@symops.com.
                  </p>
                )}
                {genericError && (
                  <p className={"error-message"}>
                    There was a problem submitting, please try again or reach
                    out to sales@symops.com.
                  </p>
                )}

                {!submitted && (
                  <div className="mt-12">
                    <input
                      type="submit"
                      className={"cta-button"}
                      value="Try Sym"
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
        )}
        {submitted && (
          <div className="submitted-message">
            <p>Thanks! We’ll be in touch soon. ✨</p>
            <button
              type="button"
              className={
                "sales-submit-button is-submitted-button px-5 py-3 rounded-3xl focus:outline-none font-medium md:text-lg text-sm"
              }
              disabled="{true}"
            >
              Thanks!
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
