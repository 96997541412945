/*
 * This is a list (and associated data) of all the use case pages. By adding,
 * editing or removing a use case from this array, it will update the main
 * /use-cases page, and add (or remove) a /use-cases/:id route.
 *
 * Sample input:
 *
 * {
 *   id: 'hello-world', // This will generate /use-cases/hello-world
 *   title: 'Hello World',
 *   navTitle: 'Hello', // Title to show in nav (nothing long)
 *   previewImage: '', // Icon to represent use case
 *   shortDescription: '', // Short description shown on platform page boxes
 *   content: [ // This is an array of components, all example components are included below
 *     {
 *       type: 'image',
 *       src: '', // Link to image
 *       caption: 'Image Caption: Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
 *     },
 *     {
 *       type: 'text',
 *       content: 'Enter text here',
 *     },
 *     {
 *       type: 'subtitle',
 *       content: 'Enter subtitle here',
 *     },
 *   ],
 * }
 */

const useCases = [
  {
    id: "privileged-access",
    title: "Privileged Access Management",
    subTitle:
      "Sym makes it easy to define, manage, and execute approvals for both daily and exceptional access.",

    navTitle: "Privileged Access Management",
    previewImage: "/assets/use-cases/privileged-access.png",
    heroImage: "/assets/use-cases/privileged-access-hero.png",
    shortDescription:
      "Grant access to the right people, at the right time, with the right context.",

    content: [
      {
        type: "text",
        content:
          "It’s hard to strike just the right balance between privilege and speed. " +
          "Bad defaults can put your whole organization at risk, but if you lock things down too much, you’ll introduce undue toil to daily engineering. " +
          "In an ideal world, you could centralize temporary access rules around least-privileged defaults, quickly iterate on the right policies to fit your needs, handle access requests without switching tools, and not worry about cleaning up after an approval." +
          "<br><br>" +
          "Sym’s SDK-first approach, Slack routing, and automatic deescalations ensure you get the best of all worlds, all defined in code.",
      },

      {
        type: "talk-to-us",
      },

      {
        type: "integrations",
        integrationIds: ["aws-sso", "okta", "github"], // These ids come from IntegrationsList.js
      },
    ],
  },

  {
    id: "customer-approved-access",
    title: "Customer-Approved Access",
    subTitle: "Let your customers approve access to their data in Slack.",
    navTitle: "Customer-Approved Access",
    previewImage: "/assets/use-cases/customer-access.png",
    heroImage: "/assets/use-cases/customer-access-hero.png",
    shortDescription:
      "Protect access to customer data with clear, auditable, least-privileged policies.",
    content: [
      {
        type: "text",
        content:
          "Build trust with a dynamic, hands-on way for customers to participate in approvals. Skip the ticket queue – make sure your customers have the right context to keep things moving fast." +
          "<br><br>" +
          "Keep your customers confident, safe, and engaged with Sym.",
      },
      {
        type: "talk-to-us",
      },
      {
        type: "integrations",
        integrationIds: ["pagerduty", "lamdba", "aws-sso"], // These ids come from IntegrationsList.js
      },
    ],
  },

  {
    id: "day-to-day-compliance",
    title: "Day to Day Compliance",
    subTitle:
      "Use Sym’s SDK to create approval workflows for anything, all managed with your SDLC.",
    navTitle: "Day to Day Compliance",
    previewImage: "/assets/use-cases/compliance.png",
    heroImage: "/assets/use-cases/compliance-hero.png",
    shortDescription:
      "Ensure every privileged action is centrally tracked for easy, hassle-free audit.",
    content: [
      {
        type: "text",
        content:
          "Required controls for things like SOC-2, PCI, and HIPAA can slow your team down, but they don’t have to. Sym makes it easy to add lightweight controls in the places that matter." +
          "<br><br>" +
          "Manage risk and accelerate your platform roadmap with the Sym SDK.",
      },

      {
        type: "talk-to-us",
      },

      {
        type: "integrations",
        integrationIds: ["circleci", "aptible", "tailscale"], // These ids come from IntegrationsList.js
      },
    ],
  },
];

export default useCases;
