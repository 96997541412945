import React, { useState, useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import { RemoveScroll } from "react-remove-scroll";
import ReactPlayer from "react-player/vimeo";
import "./HeroVideo.css";
// import AnimationContainer from "../../../AnimationContainer/AnimationContainer";

export default function HeroVideo(props) {
  const { useButtonToggle, videoPreview, videoPoster, videoUrl } = props;

  const [videoModalShowing, setVideoModalShowing] = useState(false);

  function toggleModal() {
    if (!videoModalShowing) {
      window.analytics.track("Clicked Hero Video");
    }
    setVideoModalShowing(!videoModalShowing);
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setVideoModalShowing(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return (
    <>
      {useButtonToggle ? (
        <button
          onClick={toggleModal}
          className="cta-button tertiary"
          id={props.buttonToggleId ?? undefined}
        >
          Watch a Demo
        </button>
      ) : (
        <div onClick={toggleModal} className="home-hero-video-toggle">
          {videoPreview ? (
            <video autoPlay muted loop poster={videoPoster}>
              <source src={videoPreview} type="video/mp4" />
            </video>
          ) : null}
          <div className="demo-video-preview">
            <img src={videoPoster} alt="Sym demo video preview" />
          </div>
          <div className="video-preview-overlay"></div>
          <div className="play-button">
            <svg
              width="35"
              height="44"
              viewBox="0 0 35 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.1828 19.7147L4.25904 1.02553C2.4259 -0.158957 0.00891113 1.15695 0.00891113 3.33948V41.1628C0.00891113 43.3587 2.45244 44.6721 4.2839 43.4605L33.2076 24.3263C34.8649 23.23 34.8517 20.7931 33.1828 19.7147Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      )}
      {videoModalShowing
        ? createPortal(
            <RemoveScroll>
              <div className="home-hero-video-wrapper">
                <div className="home-hero-video-inner">
                  <div onClick={toggleModal} className="home-video-close">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.57593 7.91078L1.98939 12.524L0.575928 11.1372L5.16247 6.52399L0.575928 1.91078L1.98939 0.523987L6.57593 5.13719L11.1625 0.523987L12.5759 1.91078L7.98939 6.52399L12.5759 11.1372L11.1625 12.524L6.57593 7.91078Z"
                        fill="white"
                      ></path>
                    </svg>
                  </div>
                  <ReactPlayer
                    url={videoUrl}
                    controls={true}
                    playing={true}
                    width="100%"
                    height="100%"
                  />
                </div>
                <div
                  onClick={toggleModal}
                  className="home-hero-video-overlay"
                ></div>
              </div>
            </RemoveScroll>,
            document.body
          )
        : null}
    </>
  );
}
