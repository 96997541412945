import AnimationContainer from "../../../AnimationContainer/AnimationContainer";
import FeatureBlock from "../../../sections/product/ProductPage/FeatureBlock/FeatureBlock.js";

const Whitepaper = {
  type: "feature-block",
  image: "/assets/whitepapers/whitepaper-zero-trust-graphic.svg",
  imageAlt: "Sym Zero Trust Security access guide",
  imageMaxWidth: 360,
  containerMaxWidth: 1100,
  content: [
    {
      type: "title",
      content: "Read the guide on implementing Zero Trust Security",
    },
    {
      type: "text",
      content:
        "Practical advice for improving your security posture with zero trust.",
    },
    {
      type: "button",
      text: "Get The Guide",
      link: "/whitepapers/zero-trust-security",
      target: "_self",
    },
  ],
};

export default function HeroSection() {
  return (
    <AnimationContainer animationClass="home-hero-section-animation">
      <section className="product-features-section-wrapper w-full justify-center flex">
        <div className="w-full md:max-w-7xl flex flex-col">
          <FeatureBlock item={Whitepaper} />
        </div>
      </section>
    </AnimationContainer>
  );
}
