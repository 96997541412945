import React from "react";
import "../../sections.css";
import "./investors.css";

import AnimationContainer from "../../../AnimationContainer/AnimationContainer";

//investor images
import AmplifyImageSrc from "../Recognition/images/amplifyLogo.svg";
import UncorkImageSrc from "../images/uncork.svg";
import MangoImageSrc from "../images/mango.svg";
import amaranthineImageSrc from "../images/amaranthine.svg";

//angel/advisor images
import amitAgarwalSrc from "../images/amitAgarwal.jpg";
import jamieBarnettSrc from "../images/jamieBarnett.jpeg";
import gerhardEschelbeckSrc from "../images/gerhardEschelbeck.jpg";
import adamGrossSrc from "../images/adamGross.png";
import jamesSmithSrc from "../images/jamesSmith.jpeg";
import jasonWarnerSrc from "../images/jasonWarner.png";
import robWitoffSrc from "../images/robWitoff.jpg";
import heidiWaterhouseSrc from "../images/heidiWaterhouse.jpg";

const investors = [
  {
    imgSrc: AmplifyImageSrc,
    link: "https://amplifypartners.com/",
    bg: "linear-gradient(41.01deg, #2A0082 -80.32%, #47A7FF 123.83%)",
  },
  {
    imgSrc: UncorkImageSrc,
    link: "https://uncorkcapital.com/",
    bg: "linear-gradient(129.71deg, #0F44FF -89.93%, #8D47FF 146.18%)",
  },
  {
    imgSrc: MangoImageSrc,
    link: "https://www.linkedin.com/in/robinvasan/",
    bg: "linear-gradient(56.46deg, #44A2F9 -15.96%, #00E0E0 127.3%)",
  },
  {
    imgSrc: amaranthineImageSrc,
    link: "https://amaranthine.com/",
    bg: "linear-gradient(61.5deg, #0FA9FF -26.87%, #4786FF 102.37%)",
  },
];

const fullAdvisors = [
  {
    name: "Amit Agarwal",
    job: "President @ Datadog",
    imgSrc: amitAgarwalSrc,
    linkedinHref: "https://www.linkedin.com/in/amitto/",
  },
  {
    name: "Jamie Barnett",
    job: "Former CMO @ Netskope",
    imgSrc: jamieBarnettSrc,
    linkedinHref: "https://www.linkedin.com/in/jamiecbarnett/",
  },
  {
    name: "Gerhard Eschelbeck",
    job: "Former VP Security and Privacy Engineering @ Google",
    imgSrc: gerhardEschelbeckSrc,
    linkedinHref: "https://www.linkedin.com/in/gerhardeschelbeck/",
  },
  {
    name: "Adam Gross",
    job: "Former CEO @ Heroku",
    imgSrc: adamGrossSrc,
    linkedinHref: "https://www.linkedin.com/in/adamcgross/",
  },
  {
    name: "James Smith",
    job: "Former CEO @ Bugsnag",
    imgSrc: jamesSmithSrc,
    linkedinHref: "https://www.linkedin.com/in/loopj/",
  },
  {
    name: "Jason Warner",
    job: "Former CTO @ GitHub",
    imgSrc: jasonWarnerSrc,
    linkedinHref: "https://www.linkedin.com/in/jcwarner/",
  },
  {
    name: "Heidi Waterhouse",
    job: "Former Developer Advocate @ LaunchDarkly",
    imgSrc: heidiWaterhouseSrc,
    linkedinHref: "https://www.linkedin.com/in/heidiwaterhouse/",
  },
  {
    name: "Rob Witoff",
    job: "Former Chief Architect @ Coinbase",
    imgSrc: robWitoffSrc,
    linkedinHref: "https://www.linkedin.com/in/rwitoff",
  },
];

const InvestorProfile = ({ investor }) => {
  const { imgSrc, link, bg } = investor;
  return (
    <div className="w-full inline-flex justify-center items-center">
      <a href={link} target="_blank" rel="noreferrer">
        <div
          className="rounded-full p-4"
          style={{ width: "130px", height: "130px", background: bg }}
        >
          <img alt="" src={imgSrc} className="filter invert w-full h-full" />
        </div>
      </a>
    </div>
  );
};

const advisors = fullAdvisors.slice(0, 8);

const AdvisorProfile = ({ advisor }) => {
  const { name, job, imgSrc, linkedinHref } = advisor;
  return (
    <div className="w-full inline-flex flex-col justify-center items-center gap-4 text-center">
      <img
        alt={name}
        src={imgSrc}
        className="w-full rounded-full flex-shrink-0"
        style={{ maxWidth: "130px" }}
      />
      <a
        href={linkedinHref}
        className=""
        target="_blank"
        rel="noopener noreferrer"
      >
        <h3 className="text-base">{name}</h3>
        <p className="text-sym-blue">{job}</p>
      </a>
    </div>
  );
};

export default function Investors() {
  return (
    <AnimationContainer animationClass="investors-animation">
      <div className="flex flex-col py-16">
        <div className="w-full mb-24 text-center">
          <h2 className="inline-block mx-auto">Investors &amp; Advisors</h2>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-8 sm:gap-12">
          {investors.map((investor, index) => (
            <InvestorProfile key={index} investor={investor} />
          ))}
        </div>
        <div className="mt-16 lg:mt-20 grid grid-cols-2 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-8 sm:gap-12">
          {advisors.map((advisor) => (
            <AdvisorProfile key={advisor.name} advisor={advisor} />
          ))}
        </div>
      </div>
    </AnimationContainer>
  );
}
